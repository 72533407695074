import { createElement } from "react";
import { createObj } from "../fable_modules/fable-library.4.10.0/Util.js";
import { join } from "../fable_modules/fable-library.4.10.0/String.js";
import { empty, append, map, singleton, delay, toList } from "../fable_modules/fable-library.4.10.0/Seq.js";
import { singleton as singleton_1, ofArray } from "../fable_modules/fable-library.4.10.0/List.js";
import { Interop_reactApi } from "../fable_modules/Feliz.2.6.0/./Interop.fs.js";
import { Currency_formatCurrency, DateTime_formatNullable } from "../Extensions.fs.js";
import { fromParts, compare } from "../fable_modules/fable-library.4.10.0/Decimal.js";

export function render(invoices) {
    let elems_4;
    return createElement("div", createObj(ofArray([["className", join(" ", ["table-container", "table-has-fixed-header"])], ["style", {
        maxHeight: 450 + "px",
        overflowY: "auto",
    }], (elems_4 = toList(delay(() => {
        let elems_3, children_2, children, children_6;
        return (invoices.length === 0) ? singleton(createElement("div", {
            className: "has-text-centered",
            children: "No invoices were found for this shipment",
        })) : singleton(createElement("table", createObj(ofArray([["className", join(" ", ["table", "is-narrow", "is-fullwidth"])], (elems_3 = [(children_2 = singleton_1((children = ofArray([createElement("th", {
            className: "p-2",
            scope: "col",
            children: "Invoice Number",
        }), createElement("th", {
            className: "p-2",
            scope: "col",
            children: "Invoice Date",
        }), createElement("th", {
            className: "p-2",
            scope: "col",
            children: "Customer Name",
        }), createElement("th", {
            className: "p-2",
            scope: "col",
            children: "Customer Address",
        }), createElement("th", {
            className: join(" ", ["p-2", "has-text-right"]),
            scope: "col",
            children: "Invoice Amount (USD)",
        }), createElement("th", {
            className: join(" ", ["p-2", "has-text-right"]),
            scope: "col",
            children: "Invoice Amount",
        }), createElement("th", {
            className: "p-2",
            scope: "col",
        })]), createElement("tr", {
            children: Interop_reactApi.Children.toArray(Array.from(children)),
        }))), createElement("thead", {
            children: Interop_reactApi.Children.toArray(Array.from(children_2)),
        })), (children_6 = toList(delay(() => map((invoice) => {
            let elems, elems_2, elems_1;
            const children_4 = ofArray([createElement("td", {
                className: "p-2",
                children: invoice.Number,
            }), createElement("td", {
                className: "p-2",
                children: DateTime_formatNullable(invoice.InvoiceDate),
            }), createElement("td", {
                className: "p-2",
                children: invoice.CustomerName,
            }), createElement("td", createObj(ofArray([["className", join(" ", ["p-2", "ariaMultiLine"])], (elems = toList(delay(() => map((addressInfo) => addressInfo, invoice.CustomerAddress))), ["children", Interop_reactApi.Children.toArray(Array.from(elems))])]))), createElement("td", {
                className: join(" ", toList(delay(() => append(singleton("p-2"), delay(() => append(singleton("has-text-right"), delay(() => ((compare(invoice.AmountUsd, fromParts(0, 0, 0, false, 0)) < 0) ? singleton("has-text-danger") : empty())))))))),
                children: Currency_formatCurrency("en-US", "USD", invoice.AmountUsd),
            }), createElement("td", {
                className: join(" ", toList(delay(() => append(singleton("p-2"), delay(() => append(singleton("has-text-right"), delay(() => ((compare(invoice.Amount, fromParts(0, 0, 0, false, 0)) < 0) ? singleton("has-text-danger") : empty())))))))),
                children: Currency_formatCurrency("en-US", invoice.Currency, invoice.Amount),
            }), createElement("td", createObj(ofArray([["className", "p-2"], (elems_2 = [createElement("div", createObj(ofArray([["className", join(" ", ["field", "has-addons"])], (elems_1 = [createElement("p", {
                className: "control",
            })], ["children", Interop_reactApi.Children.toArray(Array.from(elems_1))])])))], ["children", Interop_reactApi.Children.toArray(Array.from(elems_2))])])))]);
            return createElement("tr", {
                children: Interop_reactApi.Children.toArray(Array.from(children_4)),
            });
        }, invoices))), createElement("tbody", {
            children: Interop_reactApi.Children.toArray(Array.from(children_6)),
        }))], ["children", Interop_reactApi.Children.toArray(Array.from(elems_3))])]))));
    })), ["children", Interop_reactApi.Children.toArray(Array.from(elems_4))])])));
}

