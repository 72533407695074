import { Union, Record } from "../fable_modules/fable-library.4.10.0/Types.js";
import { NewFavoritedPurchaseOrder, User, FavoritedPurchaseOrder, User_$reflection } from "../../CView.DTOs/DTOs.fs.js";
import { record_type, unit_type, union_type, string_type, class_type } from "../fable_modules/fable-library.4.10.0/Reflection.js";
import { PurchaseOrder_$reflection } from "../../CView.DTOs/Inventory.fs.js";
import { FSharpResult$2 } from "../fable_modules/fable-library.4.10.0/Choice.js";
import { AsyncMsg$1, Deferred$1, AsyncMsg$1_$reflection, Deferred$1_$reflection } from "../Extensions.fs.js";
import { RelatedEntities, RelatedEntities_$reflection } from "../Domain/PurchaseOrder.fs.js";
import { defaultPurchaseOrder } from "../Domain/Inventory.fs.js";
import { ofArray, singleton } from "../fable_modules/fable-library.4.10.0/List.js";
import { tryHead, append } from "../fable_modules/fable-library.4.10.0/Array.js";
import { Toast_text, Toast_title, Toast_success, Alert_text, Alert_title, Alert_error } from "../Components/SweetAlert.fs.js";
import { createObj, equals } from "../fable_modules/fable-library.4.10.0/Util.js";
import { Cmd_none } from "../fable_modules/Fable.Elmish.4.0.2/cmd.fs.js";
import { Cmd_OfAsyncWith_perform } from "../fable_modules/Fable.Elmish.4.0.2/./cmd.fs.js";
import { startImmediate } from "../fable_modules/fable-library.4.10.0/Async.js";
import { deleteFavoritePurchaseOrder, createFavoritePurchaseOrder } from "../Api/UserApi.fs.js";
import { findPurchaseOrder } from "../Api/InventoryApi.fs.js";
import { createElement } from "react";
import { printf, join } from "../fable_modules/fable-library.4.10.0/String.js";
import { empty, append as append_1, singleton as singleton_1, delay, toList } from "../fable_modules/fable-library.4.10.0/Seq.js";
import { render as render_1 } from "../Components/Loader.fs.js";
import { RouterModule_encodeParts } from "../fable_modules/Feliz.Router.4.0.0/./Router.fs.js";
import { Interop_reactApi } from "../fable_modules/Feliz.2.6.0/./Interop.fs.js";
import { defaultArg } from "../fable_modules/fable-library.4.10.0/Option.js";
import { formatDecimal } from "../Domain/Formatter.fs.js";
import { render as render_2 } from "../Components/PurchaseOrderMaterialReceipt.fs.js";
import { render as render_3 } from "../Components/PurchaseOrderPackingList.fs.js";
import { render as render_4 } from "../Components/PurchaseOrderLineItem.fs.js";
import { defaultOf } from "../fable_modules/Feliz.2.6.0/../fable-library.4.10.0/Util.js";

export class State extends Record {
    constructor(User, PurchaseOrderId, PurchaseOrderDeferred, FollowPurchaseOrder, UnFollowPurchaseOrder, ActiveTab) {
        super();
        this.User = User;
        this.PurchaseOrderId = PurchaseOrderId;
        this.PurchaseOrderDeferred = PurchaseOrderDeferred;
        this.FollowPurchaseOrder = FollowPurchaseOrder;
        this.UnFollowPurchaseOrder = UnFollowPurchaseOrder;
        this.ActiveTab = ActiveTab;
    }
}

export function State_$reflection() {
    return record_type("CView.UI.Pages.PurchaseOrderDetails.State", [], State, () => [["User", User_$reflection()], ["PurchaseOrderId", class_type("System.Guid")], ["PurchaseOrderDeferred", Deferred$1_$reflection(union_type("Microsoft.FSharp.Core.FSharpResult`2", [PurchaseOrder_$reflection(), string_type], FSharpResult$2, () => [[["ResultValue", PurchaseOrder_$reflection()]], [["ErrorValue", string_type]]]))], ["FollowPurchaseOrder", Deferred$1_$reflection(union_type("Microsoft.FSharp.Core.FSharpResult`2", [unit_type, string_type], FSharpResult$2, () => [[["ResultValue", unit_type]], [["ErrorValue", string_type]]]))], ["UnFollowPurchaseOrder", Deferred$1_$reflection(union_type("Microsoft.FSharp.Core.FSharpResult`2", [unit_type, string_type], FSharpResult$2, () => [[["ResultValue", unit_type]], [["ErrorValue", string_type]]]))], ["ActiveTab", RelatedEntities_$reflection()]]);
}

export class Intent extends Union {
    constructor(tag, fields) {
        super();
        this.tag = tag;
        this.fields = fields;
    }
    cases() {
        return ["NoOp", "PurchaseOrderFollowed", "PurchaseOrderUnfollowed", "Search"];
    }
}

export function Intent_$reflection() {
    return union_type("CView.UI.Pages.PurchaseOrderDetails.Intent", [], Intent, () => [[], [["Item1", class_type("System.Guid")], ["Item2", string_type]], [["Item1", class_type("System.Guid")], ["Item2", string_type]], []]);
}

export class Msg extends Union {
    constructor(tag, fields) {
        super();
        this.tag = tag;
        this.fields = fields;
    }
    cases() {
        return ["GetPurchaseOrder", "FollowPurchaseOrder", "UnFollowPurchaseOrder", "SetActiveTab", "BreadcrumbClicked"];
    }
}

export function Msg_$reflection() {
    return union_type("CView.UI.Pages.PurchaseOrderDetails.Msg", [], Msg, () => [[["Item", AsyncMsg$1_$reflection(union_type("Microsoft.FSharp.Core.FSharpResult`2", [PurchaseOrder_$reflection(), string_type], FSharpResult$2, () => [[["ResultValue", PurchaseOrder_$reflection()]], [["ErrorValue", string_type]]]))]], [["Item", AsyncMsg$1_$reflection(union_type("Microsoft.FSharp.Core.FSharpResult`2", [unit_type, string_type], FSharpResult$2, () => [[["ResultValue", unit_type]], [["ErrorValue", string_type]]]))]], [["Item", AsyncMsg$1_$reflection(union_type("Microsoft.FSharp.Core.FSharpResult`2", [unit_type, string_type], FSharpResult$2, () => [[["ResultValue", unit_type]], [["ErrorValue", string_type]]]))]], [["Item", RelatedEntities_$reflection()]], []]);
}

function isFavoritePurchaseOrder(state) {
    return state.User.PurchaseOrders.some((s) => (s.PurchaseOrderId === state.PurchaseOrderId));
}

function toPurchaseOrder(state) {
    const matchValue = state.PurchaseOrderDeferred;
    switch (matchValue.tag) {
        case 1:
            return defaultPurchaseOrder;
        case 2:
            if (matchValue.fields[0].tag === 0) {
                return matchValue.fields[0].fields[0];
            }
            else {
                return defaultPurchaseOrder;
            }
        default:
            return defaultPurchaseOrder;
    }
}

export function init(purchaseOrderId, user) {
    return [new State(user, purchaseOrderId, new Deferred$1(0, []), new Deferred$1(0, []), new Deferred$1(0, []), new RelatedEntities(0, [])), singleton((dispatch) => {
        dispatch(new Msg(0, [new AsyncMsg$1(0, [])]));
    })];
}

export function update(msg, state) {
    let bind$0040, bind$0040_1;
    switch (msg.tag) {
        case 1:
            if (msg.fields[0].tag === 1) {
                const purchaseOrder = toPurchaseOrder(state);
                return [new State((msg.fields[0].fields[0].tag === 1) ? state.User : ((bind$0040 = state.User, new User(bind$0040.Id, bind$0040.UserTypeId, bind$0040.Email, bind$0040.DisplayName, bind$0040.IsActive, bind$0040.CanViewAirShipments, bind$0040.CanViewOceanShipments, bind$0040.CanViewGroundShipments, bind$0040.CanCreateBookings, bind$0040.CanViewBookings, bind$0040.CanExportSearchResults, bind$0040.CanViewInventory, bind$0040.CanViewAnalytics, bind$0040.CanViewRailShipments, bind$0040.CanViewCO2Emissions, bind$0040.CanViewBilling, bind$0040.CanViewShipments, bind$0040.CanExportBookings, bind$0040.CanExportInventory, bind$0040.CanExportBilling, bind$0040.CanExportDocuments, bind$0040.Accounts, bind$0040.Shipments, append([new FavoritedPurchaseOrder(purchaseOrder.Id, purchaseOrder.PurchaseOrderNumber)], state.User.PurchaseOrders), bind$0040.MaterialReceipts, bind$0040.PackingLists, bind$0040.UserPreferences))), state.PurchaseOrderId, state.PurchaseOrderDeferred, new Deferred$1(2, [msg.fields[0].fields[0]]), state.UnFollowPurchaseOrder, state.ActiveTab), (msg.fields[0].fields[0].tag === 1) ? Alert_error(Alert_title("Pin Purchase Order", Alert_text(msg.fields[0].fields[0].fields[0]))) : Toast_success(Toast_title("Pin Purchase Order", Toast_text("You have successfully pinned the purchase order to the dashboard"))), (msg.fields[0].fields[0].tag === 1) ? (new Intent(0, [])) : (new Intent(1, [purchaseOrder.Id, purchaseOrder.PurchaseOrderNumber]))];
            }
            else if (equals(state.FollowPurchaseOrder, new Deferred$1(1, []))) {
                return [state, Cmd_none(), new Intent(0, [])];
            }
            else {
                return [new State(state.User, state.PurchaseOrderId, state.PurchaseOrderDeferred, new Deferred$1(1, []), state.UnFollowPurchaseOrder, state.ActiveTab), Cmd_OfAsyncWith_perform((computation_1) => {
                    startImmediate(computation_1);
                }, createFavoritePurchaseOrder, new NewFavoritedPurchaseOrder(state.PurchaseOrderId), (arg_2) => (new Msg(1, [new AsyncMsg$1(1, [arg_2])]))), new Intent(0, [])];
            }
        case 2:
            if (msg.fields[0].tag === 1) {
                const purchaseOrder_1 = toPurchaseOrder(state);
                return [new State((msg.fields[0].fields[0].tag === 1) ? state.User : ((bind$0040_1 = state.User, new User(bind$0040_1.Id, bind$0040_1.UserTypeId, bind$0040_1.Email, bind$0040_1.DisplayName, bind$0040_1.IsActive, bind$0040_1.CanViewAirShipments, bind$0040_1.CanViewOceanShipments, bind$0040_1.CanViewGroundShipments, bind$0040_1.CanCreateBookings, bind$0040_1.CanViewBookings, bind$0040_1.CanExportSearchResults, bind$0040_1.CanViewInventory, bind$0040_1.CanViewAnalytics, bind$0040_1.CanViewRailShipments, bind$0040_1.CanViewCO2Emissions, bind$0040_1.CanViewBilling, bind$0040_1.CanViewShipments, bind$0040_1.CanExportBookings, bind$0040_1.CanExportInventory, bind$0040_1.CanExportBilling, bind$0040_1.CanExportDocuments, bind$0040_1.Accounts, bind$0040_1.Shipments, state.User.PurchaseOrders.filter((userPurchaseOrder) => (userPurchaseOrder.PurchaseOrderId !== purchaseOrder_1.Id)), bind$0040_1.MaterialReceipts, bind$0040_1.PackingLists, bind$0040_1.UserPreferences))), state.PurchaseOrderId, state.PurchaseOrderDeferred, state.FollowPurchaseOrder, new Deferred$1(2, [msg.fields[0].fields[0]]), state.ActiveTab), (msg.fields[0].fields[0].tag === 1) ? Alert_error(Alert_title("Unpin Purchase Order", Alert_text(msg.fields[0].fields[0].fields[0]))) : Toast_success(Toast_title("Unpin Purchase Order", Toast_text("You have successfully unpinned the purchase order from the dashboard"))), (msg.fields[0].fields[0].tag === 1) ? (new Intent(0, [])) : (new Intent(2, [purchaseOrder_1.Id, purchaseOrder_1.PurchaseOrderNumber]))];
            }
            else if (equals(state.UnFollowPurchaseOrder, new Deferred$1(1, []))) {
                return [state, Cmd_none(), new Intent(0, [])];
            }
            else {
                return [new State(state.User, state.PurchaseOrderId, state.PurchaseOrderDeferred, state.FollowPurchaseOrder, new Deferred$1(1, []), state.ActiveTab), Cmd_OfAsyncWith_perform((computation_2) => {
                    startImmediate(computation_2);
                }, deleteFavoritePurchaseOrder, state.PurchaseOrderId, (arg_4) => (new Msg(2, [new AsyncMsg$1(1, [arg_4])]))), new Intent(0, [])];
            }
        case 3:
            return [new State(state.User, state.PurchaseOrderId, state.PurchaseOrderDeferred, state.FollowPurchaseOrder, state.UnFollowPurchaseOrder, msg.fields[0]), Cmd_none(), new Intent(0, [])];
        case 4:
            return [state, Cmd_none(), new Intent(3, [])];
        default:
            if (msg.fields[0].tag === 1) {
                return [new State(state.User, state.PurchaseOrderId, new Deferred$1(2, [msg.fields[0].fields[0]]), state.FollowPurchaseOrder, state.UnFollowPurchaseOrder, state.ActiveTab), (msg.fields[0].fields[0].tag === 1) ? Alert_error(Alert_title("Purchase Order", Alert_text(msg.fields[0].fields[0].fields[0]))) : Cmd_none(), new Intent(0, [])];
            }
            else if (equals(state.PurchaseOrderDeferred, new Deferred$1(1, []))) {
                return [state, Cmd_none(), new Intent(0, [])];
            }
            else {
                return [new State(state.User, state.PurchaseOrderId, new Deferred$1(1, []), state.FollowPurchaseOrder, state.UnFollowPurchaseOrder, state.ActiveTab), Cmd_OfAsyncWith_perform((computation) => {
                    startImmediate(computation);
                }, findPurchaseOrder, state.PurchaseOrderId, (arg) => (new Msg(0, [new AsyncMsg$1(1, [arg])]))), new Intent(0, [])];
            }
    }
}

export function render(state, dispatch) {
    let elems_24;
    return createElement("div", createObj(ofArray([["className", join(" ", [])], (elems_24 = toList(delay(() => {
        let purchaseOrder, elems_3, elems_1, children, elems, elems_2;
        const matchValue = state.PurchaseOrderDeferred;
        return (matchValue.tag === 1) ? singleton_1(render_1()) : ((matchValue.tag === 2) ? ((matchValue.fields[0].tag === 0) ? append_1(singleton_1((purchaseOrder = matchValue.fields[0].fields[0], createElement("nav", createObj(ofArray([["aria-label", "breadcrumbs"], ["className", join(" ", ["breadcrumb", "is-small", "box", "is-flex", "is-flex-wrap-wrap", "is-justify-content-space-between", "is-align-items-center"])], (elems_3 = [createElement("ul", createObj(ofArray([["className", "has-text-weight-semibold"], (elems_1 = [(children = singleton(createElement("a", {
            className: "is-size-6",
            href: RouterModule_encodeParts(singleton("/inventory"), 1),
            onClick: (_arg) => {
                dispatch(new Msg(4, []));
            },
            children: "Purchase Order",
        })), createElement("li", {
            children: Interop_reactApi.Children.toArray(Array.from(children)),
        })), createElement("li", createObj(ofArray([["className", join(" ", ["is-active", "is-size-6"])], (elems = [createElement("a", {
            children: purchaseOrder.PurchaseOrderNumber,
        })], ["children", Interop_reactApi.Children.toArray(Array.from(elems))])])))], ["children", Interop_reactApi.Children.toArray(Array.from(elems_1))])]))), createElement("div", createObj(ofArray([["className", join(" ", ["is-flex", "is-align-content-center"])], (elems_2 = [createElement("span", {
            className: join(" ", ["tag", "ml-1"]),
            children: purchaseOrder.StatusName,
        }), createElement("i", {
            className: join(" ", toList(delay(() => append_1(singleton_1("material-icons"), delay(() => append_1(singleton_1("mot-icon"), delay(() => append_1(singleton_1("m1-3"), delay(() => append_1(singleton_1("is-clickable"), delay(() => (isFavoritePurchaseOrder(state) ? singleton_1("has-text-link-dark") : singleton_1("has-text-grey-light"))))))))))))),
            title: "Favorite",
            children: "push_pin",
            onClick: (_arg_1) => {
                if (isFavoritePurchaseOrder(state)) {
                    dispatch(new Msg(2, [new AsyncMsg$1(0, [])]));
                }
                else {
                    dispatch(new Msg(1, [new AsyncMsg$1(0, [])]));
                }
            },
            disabled: equals(state.FollowPurchaseOrder, new Deferred$1(1, [])) ? true : equals(state.UnFollowPurchaseOrder, new Deferred$1(1, [])),
        })], ["children", Interop_reactApi.Children.toArray(Array.from(elems_2))])])))], ["children", Interop_reactApi.Children.toArray(Array.from(elems_3))])]))))), delay(() => {
            let purchaseOrder_1, elems_15, elems_14, elems_5, elems_4, fmt, x_2, elems_13, elems_12, elems_11, elems_6, elems_7, elems_8, elems_9, elems_10, fmt_1;
            return append_1(singleton_1((purchaseOrder_1 = matchValue.fields[0].fields[0], createElement("div", createObj(ofArray([["className", "box"], (elems_15 = [createElement("div", createObj(ofArray([["className", "columns"], (elems_14 = [createElement("div", createObj(ofArray([["className", join(" ", ["column", "is-narrow"])], (elems_5 = [createElement("div", createObj(ofArray([["className", join(" ", ["is-flex", "is-align-content-top", "is-flex-direction-column"])], (elems_4 = [createElement("h1", {
                className: join(" ", ["is-size-4", "has-text-weight-semibold"]),
                children: purchaseOrder_1.PurchaseOrderNumber,
            }), createElement("span", createObj(singleton(((fmt = printf("Vendor | %s"), fmt.cont((value_40) => ["children", value_40])))(defaultArg((x_2 = tryHead(purchaseOrder_1.LineItems), (x_2 == null) ? void 0 : x_2.VendorName), "")))))], ["children", Interop_reactApi.Children.toArray(Array.from(elems_4))])])))], ["children", Interop_reactApi.Children.toArray(Array.from(elems_5))])]))), createElement("div", createObj(ofArray([["className", "column"], (elems_13 = [createElement("div", createObj(ofArray([["className", join(" ", ["block", "ml-6", "py-0"])], (elems_12 = [createElement("div", createObj(ofArray([["className", join(" ", ["columns", "is-multiline", "is-centered"])], (elems_11 = [createElement("div", createObj(ofArray([["className", "column"], (elems_6 = [createElement("h6", {
                className: "is-size-6",
                children: "Line Items",
            }), createElement("span", {
                className: "is-size-4",
                children: purchaseOrder_1.LineItemCount,
            })], ["children", Interop_reactApi.Children.toArray(Array.from(elems_6))])]))), createElement("div", createObj(ofArray([["className", "column"], (elems_7 = [createElement("h6", {
                className: "is-size-6",
                children: "Quantity Ordered",
            }), createElement("span", {
                className: "is-size-4",
                children: formatDecimal(purchaseOrder_1.QuantityOrderedCount),
            })], ["children", Interop_reactApi.Children.toArray(Array.from(elems_7))])]))), createElement("div", createObj(ofArray([["className", "column"], (elems_8 = [createElement("h6", {
                className: "is-size-6",
                children: "Quantity Received",
            }), createElement("span", {
                className: "is-size-4",
                children: formatDecimal(purchaseOrder_1.QuantityReceivedCount),
            })], ["children", Interop_reactApi.Children.toArray(Array.from(elems_8))])]))), createElement("div", createObj(ofArray([["className", "column"], (elems_9 = [createElement("h6", {
                className: "is-size-6",
                children: "Material Receipts",
            }), createElement("span", {
                className: "is-size-4",
                children: purchaseOrder_1.MaterialReceiptCount,
            })], ["children", Interop_reactApi.Children.toArray(Array.from(elems_9))])]))), createElement("div", createObj(ofArray([["className", "column"], (elems_10 = [createElement("h6", {
                className: "is-size-6",
                children: "Lines Shipped",
            }), createElement("span", createObj(ofArray([["className", "is-size-4"], ((fmt_1 = printf("%i of %i"), fmt_1.cont((value_106) => ["children", value_106])))(purchaseOrder_1.LinesShippedCount)(purchaseOrder_1.LineItems.length)])))], ["children", Interop_reactApi.Children.toArray(Array.from(elems_10))])])))], ["children", Interop_reactApi.Children.toArray(Array.from(elems_11))])])))], ["children", Interop_reactApi.Children.toArray(Array.from(elems_12))])])))], ["children", Interop_reactApi.Children.toArray(Array.from(elems_13))])])))], ["children", Interop_reactApi.Children.toArray(Array.from(elems_14))])])))], ["children", Interop_reactApi.Children.toArray(Array.from(elems_15))])]))))), delay(() => {
                let selectedTab, purchaseOrder_2, elems_23, elems_22, elems_21, elems_19, children_2, elems_16, elems_17, fmt_2, elems_18, fmt_3, elems_20;
                return singleton_1((selectedTab = state.ActiveTab, (purchaseOrder_2 = matchValue.fields[0].fields[0], createElement("div", createObj(ofArray([["className", "columns"], (elems_23 = [createElement("div", createObj(ofArray([["className", "column"], (elems_22 = [createElement("div", createObj(ofArray([["className", "box"], (elems_21 = [createElement("div", createObj(ofArray([["className", join(" ", ["tabs", "is-small", "is-boxed"])], ["id", "tabParent"], (elems_19 = [(children_2 = ofArray([createElement("li", createObj(ofArray([["className", join(" ", toList(delay(() => append_1(singleton_1("has-text-weight-semibold"), delay(() => append_1(singleton_1("tablink"), delay(() => (equals(state.ActiveTab, new RelatedEntities(0, [])) ? singleton_1("is-active") : empty()))))))))], (elems_16 = [createElement("a", {
                    onClick: (_arg_2) => {
                        dispatch(new Msg(3, [new RelatedEntities(0, [])]));
                    },
                    children: "PO Line Items",
                })], ["children", Interop_reactApi.Children.toArray(Array.from(elems_16))])]))), createElement("li", createObj(ofArray([["className", join(" ", toList(delay(() => append_1(singleton_1("has-text-weight-semibold"), delay(() => append_1(singleton_1("tablink"), delay(() => (equals(state.ActiveTab, new RelatedEntities(1, [])) ? singleton_1("is-active") : empty()))))))))], (elems_17 = [createElement("a", createObj(ofArray([["onClick", (_arg_3) => {
                    dispatch(new Msg(3, [new RelatedEntities(1, [])]));
                }], ((fmt_2 = printf("Material Reciepts (%i)"), fmt_2.cont((value_131) => ["children", value_131])))(purchaseOrder_2.MaterialReceipts.length)])))], ["children", Interop_reactApi.Children.toArray(Array.from(elems_17))])]))), createElement("li", createObj(ofArray([["className", join(" ", toList(delay(() => append_1(singleton_1("has-text-weight-semibold"), delay(() => append_1(singleton_1("tablink"), delay(() => (equals(state.ActiveTab, new RelatedEntities(2, [])) ? singleton_1("is-active") : empty()))))))))], (elems_18 = [createElement("a", createObj(ofArray([["onClick", (_arg_4) => {
                    dispatch(new Msg(3, [new RelatedEntities(2, [])]));
                }], ((fmt_3 = printf("Packing Lists (%i)"), fmt_3.cont((value_137) => ["children", value_137])))(purchaseOrder_2.PackingLists.length)])))], ["children", Interop_reactApi.Children.toArray(Array.from(elems_18))])])))]), createElement("ul", {
                    children: Interop_reactApi.Children.toArray(Array.from(children_2)),
                }))], ["children", Interop_reactApi.Children.toArray(Array.from(elems_19))])]))), createElement("div", createObj(ofArray([["className", "tabcontent"], (elems_20 = toList(delay(() => ((selectedTab.tag === 1) ? singleton_1(render_2(purchaseOrder_2.MaterialReceipts)) : ((selectedTab.tag === 2) ? singleton_1(render_3(purchaseOrder_2)) : singleton_1(render_4(purchaseOrder_2.LineItems)))))), ["children", Interop_reactApi.Children.toArray(Array.from(elems_20))])])))], ["children", Interop_reactApi.Children.toArray(Array.from(elems_21))])])))], ["children", Interop_reactApi.Children.toArray(Array.from(elems_22))])])))], ["children", Interop_reactApi.Children.toArray(Array.from(elems_23))])]))))));
            }));
        })) : singleton_1(defaultOf())) : singleton_1(defaultOf()));
    })), ["children", Interop_reactApi.Children.toArray(Array.from(elems_24))])])));
}

