import { Union, Record } from "../fable_modules/fable-library.4.10.0/Types.js";
import { InvoiceSearchRequest, PagedDataResponse$1_$reflection, InvoiceSearchRequest_$reflection, InvoiceListItem_$reflection, User_$reflection, Account_$reflection } from "../../CView.DTOs/DTOs.fs.js";
import { int32_type, bool_type, lambda_type, record_type, list_type, unit_type, union_type, string_type, option_type, array_type } from "../fable_modules/fable-library.4.10.0/Reflection.js";
import { FSharpResult$2 } from "../fable_modules/fable-library.4.10.0/Choice.js";
import { Currency_formatCurrency, DateTime_formatNullable, AsyncMsg$1, Deferred$1, Deferred_exists, AsyncMsg$1_$reflection, Deferred$1_$reflection } from "../Extensions.fs.js";
import { toInt32, fromInt32, toInt64, compare } from "../fable_modules/fable-library.4.10.0/BigInt.js";
import { contains, ofArray, filter, cons, singleton, empty } from "../fable_modules/fable-library.4.10.0/List.js";
import { Alert_text, Alert_title, Alert_error } from "../Components/SweetAlert.fs.js";
import { Cmd_none } from "../fable_modules/Fable.Elmish.4.0.2/cmd.fs.js";
import { safeHash, defaultOf, createObj, equals } from "../fable_modules/fable-library.4.10.0/Util.js";
import { Cmd_OfAsyncWith_perform } from "../fable_modules/Fable.Elmish.4.0.2/./cmd.fs.js";
import { startImmediate } from "../fable_modules/fable-library.4.10.0/Async.js";
import { exportInvoicesAsZip, downloadInvoice, export$, search } from "../Api/InvoiceApi.fs.js";
import { map as map_1 } from "../fable_modules/fable-library.4.10.0/Array.js";
import { defaultInvoiceSearchRequest } from "../Domain/Invoice.fs.js";
import { createElement } from "react";
import { printf, isNullOrWhiteSpace, join } from "../fable_modules/fable-library.4.10.0/String.js";
import { Interop_reactApi } from "../fable_modules/Feliz.2.6.0/./Interop.fs.js";
import { maxValue, minValue, toString } from "../fable_modules/fable-library.4.10.0/Date.js";
import { formatDecimalWithUnits, formatInt, toDateTimeOffsetInput } from "../Domain/Formatter.fs.js";
import { value as value_279, toNullable } from "../fable_modules/fable-library.4.10.0/Option.js";
import { DateTimeOffset_fromString } from "../../CView.DTOs/PrimitiveTypes.fs.js";
import { map as map_2, collect, empty as empty_1, singleton as singleton_1, append, delay, toList } from "../fable_modules/fable-library.4.10.0/Seq.js";
import { render as render_1 } from "../Components/Loader.fs.js";
import { IconType, ModeOfTransportIcon_render } from "../Components/ModeOfTransportIcon.fs.js";
import { RouterModule_encodeParts } from "../fable_modules/Feliz.Router.4.0.0/./Router.fs.js";
import { fromParts, compare as compare_1 } from "../fable_modules/fable-library.4.10.0/Decimal.js";
import { defaultOf as defaultOf_1 } from "../fable_modules/Feliz.2.6.0/../fable-library.4.10.0/Util.js";

export class State extends Record {
    constructor(Accounts, User, SelectedInvoice, InvoiceSearchQuery, PreviousInvoiceSearchQuery, InvoicesDeferred, ExportInvoiceMetadataListDeferred, DownloadInvoiceDeferred, InvoicesToZip, ExportInvoicesAsZipDeferred) {
        super();
        this.Accounts = Accounts;
        this.User = User;
        this.SelectedInvoice = SelectedInvoice;
        this.InvoiceSearchQuery = InvoiceSearchQuery;
        this.PreviousInvoiceSearchQuery = PreviousInvoiceSearchQuery;
        this.InvoicesDeferred = InvoicesDeferred;
        this.ExportInvoiceMetadataListDeferred = ExportInvoiceMetadataListDeferred;
        this.DownloadInvoiceDeferred = DownloadInvoiceDeferred;
        this.InvoicesToZip = InvoicesToZip;
        this.ExportInvoicesAsZipDeferred = ExportInvoicesAsZipDeferred;
    }
}

export function State_$reflection() {
    return record_type("CView.UI.Pages.Billing.State", [], State, () => [["Accounts", array_type(Account_$reflection())], ["User", User_$reflection()], ["SelectedInvoice", option_type(InvoiceListItem_$reflection())], ["InvoiceSearchQuery", InvoiceSearchRequest_$reflection()], ["PreviousInvoiceSearchQuery", InvoiceSearchRequest_$reflection()], ["InvoicesDeferred", Deferred$1_$reflection(union_type("Microsoft.FSharp.Core.FSharpResult`2", [PagedDataResponse$1_$reflection(InvoiceListItem_$reflection()), string_type], FSharpResult$2, () => [[["ResultValue", PagedDataResponse$1_$reflection(InvoiceListItem_$reflection())]], [["ErrorValue", string_type]]]))], ["ExportInvoiceMetadataListDeferred", Deferred$1_$reflection(union_type("Microsoft.FSharp.Core.FSharpResult`2", [unit_type, string_type], FSharpResult$2, () => [[["ResultValue", unit_type]], [["ErrorValue", string_type]]]))], ["DownloadInvoiceDeferred", Deferred$1_$reflection(union_type("Microsoft.FSharp.Core.FSharpResult`2", [unit_type, string_type], FSharpResult$2, () => [[["ResultValue", unit_type]], [["ErrorValue", string_type]]]))], ["InvoicesToZip", list_type(InvoiceListItem_$reflection())], ["ExportInvoicesAsZipDeferred", Deferred$1_$reflection(union_type("Microsoft.FSharp.Core.FSharpResult`2", [unit_type, string_type], FSharpResult$2, () => [[["ResultValue", unit_type]], [["ErrorValue", string_type]]]))]]);
}

export class Intent extends Union {
    constructor(tag, fields) {
        super();
        this.tag = tag;
        this.fields = fields;
    }
    cases() {
        return ["InvoiceQueryChanged", "NoOp"];
    }
}

export function Intent_$reflection() {
    return union_type("CView.UI.Pages.Billing.Intent", [], Intent, () => [[["Item", InvoiceSearchRequest_$reflection()]], []]);
}

export class Msg extends Union {
    constructor(tag, fields) {
        super();
        this.tag = tag;
        this.fields = fields;
    }
    cases() {
        return ["UpdateInvoiceSearchQuery", "SearchInvoicesAsync", "ExportInvoiceMetadataList", "SetSelectedInvoice", "DownloadInvoice", "AddInvoiceToZipList", "RemoveInvoiceFromZipList", "ExportInvoicesAsZip", "ClearSearch", "SetPageNumber"];
    }
}

export function Msg_$reflection() {
    return union_type("CView.UI.Pages.Billing.Msg", [], Msg, () => [[["Item", lambda_type(InvoiceSearchRequest_$reflection(), InvoiceSearchRequest_$reflection())]], [["Item1", AsyncMsg$1_$reflection(union_type("Microsoft.FSharp.Core.FSharpResult`2", [PagedDataResponse$1_$reflection(InvoiceListItem_$reflection()), string_type], FSharpResult$2, () => [[["ResultValue", PagedDataResponse$1_$reflection(InvoiceListItem_$reflection())]], [["ErrorValue", string_type]]]))], ["Item2", bool_type]], [["Item", AsyncMsg$1_$reflection(union_type("Microsoft.FSharp.Core.FSharpResult`2", [unit_type, string_type], FSharpResult$2, () => [[["ResultValue", unit_type]], [["ErrorValue", string_type]]]))]], [["Item", InvoiceListItem_$reflection()]], [["Item", AsyncMsg$1_$reflection(union_type("Microsoft.FSharp.Core.FSharpResult`2", [unit_type, string_type], FSharpResult$2, () => [[["ResultValue", unit_type]], [["ErrorValue", string_type]]]))]], [["Item", InvoiceListItem_$reflection()]], [["Item", InvoiceListItem_$reflection()]], [["Item", AsyncMsg$1_$reflection(union_type("Microsoft.FSharp.Core.FSharpResult`2", [unit_type, string_type], FSharpResult$2, () => [[["ResultValue", unit_type]], [["ErrorValue", string_type]]]))]], [], [["Item", int32_type]]]);
}

function hasPreviousPage(state) {
    return Deferred_exists((result) => {
        if (result.tag === 0) {
            return result.fields[0].Page > 1;
        }
        else {
            return false;
        }
    }, state.InvoicesDeferred);
}

function hasNextPage(state) {
    return Deferred_exists((result) => {
        if (result.tag === 0) {
            const pagedData = result.fields[0];
            return compare(toInt64(fromInt32(pagedData.Page * pagedData.PageSize)), pagedData.TotalCount) < 0;
        }
        else {
            return false;
        }
    }, state.InvoicesDeferred);
}

export function init(accounts, user, invoiceSearchRequest) {
    return [new State(accounts, user, void 0, invoiceSearchRequest, invoiceSearchRequest, new Deferred$1(0, []), new Deferred$1(0, []), new Deferred$1(0, []), empty(), new Deferred$1(0, [])), singleton((dispatch) => {
        dispatch(new Msg(1, [new AsyncMsg$1(0, []), false]));
    })];
}

export function update(msg, state) {
    let bind$0040, bind$0040_1, bind$0040_2, bind$0040_3;
    switch (msg.tag) {
        case 1:
            if (msg.fields[0].tag === 1) {
                return [new State(state.Accounts, state.User, state.SelectedInvoice, state.InvoiceSearchQuery, msg.fields[1] ? state.PreviousInvoiceSearchQuery : state.InvoiceSearchQuery, new Deferred$1(2, [msg.fields[0].fields[0]]), state.ExportInvoiceMetadataListDeferred, state.DownloadInvoiceDeferred, state.InvoicesToZip, state.ExportInvoicesAsZipDeferred), (msg.fields[0].fields[0].tag === 1) ? Alert_error(Alert_title("Search Invoices", Alert_text(msg.fields[0].fields[0].fields[0]))) : Cmd_none(), new Intent(1, [])];
            }
            else if (equals(state.InvoicesDeferred, new Deferred$1(1, [])) ? true : (state.Accounts.length === 0)) {
                return [state, Cmd_none(), new Intent(1, [])];
            }
            else {
                return [new State(state.Accounts, state.User, state.SelectedInvoice, state.InvoiceSearchQuery, state.PreviousInvoiceSearchQuery, new Deferred$1(1, []), state.ExportInvoiceMetadataListDeferred, state.DownloadInvoiceDeferred, state.InvoicesToZip, state.ExportInvoicesAsZipDeferred), Cmd_OfAsyncWith_perform((computation) => {
                    startImmediate(computation);
                }, search, msg.fields[1] ? ((bind$0040 = state.PreviousInvoiceSearchQuery, new InvoiceSearchRequest(bind$0040.Page, bind$0040.PageSize, bind$0040.OrderBy, bind$0040.OrderDirection, bind$0040.InvoiceNumber, bind$0040.FromDate, bind$0040.ToDate, map_1((x) => x.Id, state.Accounts)))) : ((bind$0040_1 = state.InvoiceSearchQuery, new InvoiceSearchRequest(1, bind$0040_1.PageSize, bind$0040_1.OrderBy, bind$0040_1.OrderDirection, bind$0040_1.InvoiceNumber, bind$0040_1.FromDate, bind$0040_1.ToDate, map_1((x_1) => x_1.Id, state.Accounts)))), (arg) => (new Msg(1, [new AsyncMsg$1(1, [arg]), msg.fields[1]]))), new Intent(1, [])];
            }
        case 2:
            if (msg.fields[0].tag === 1) {
                return [new State(state.Accounts, state.User, state.SelectedInvoice, state.InvoiceSearchQuery, state.PreviousInvoiceSearchQuery, state.InvoicesDeferred, new Deferred$1(2, [msg.fields[0].fields[0]]), state.DownloadInvoiceDeferred, state.InvoicesToZip, state.ExportInvoicesAsZipDeferred), (msg.fields[0].fields[0].tag === 1) ? Alert_error(Alert_title("Export Invoices", Alert_text(msg.fields[0].fields[0].fields[0]))) : Cmd_none(), new Intent(1, [])];
            }
            else if (equals(state.ExportInvoiceMetadataListDeferred, new Deferred$1(1, [])) ? true : (state.Accounts.length === 0)) {
                return [state, Cmd_none(), new Intent(1, [])];
            }
            else {
                return [new State(state.Accounts, state.User, state.SelectedInvoice, state.InvoiceSearchQuery, state.PreviousInvoiceSearchQuery, state.InvoicesDeferred, new Deferred$1(1, []), state.DownloadInvoiceDeferred, state.InvoicesToZip, state.ExportInvoicesAsZipDeferred), Cmd_OfAsyncWith_perform((computation_1) => {
                    startImmediate(computation_1);
                }, export$, (bind$0040_2 = state.PreviousInvoiceSearchQuery, new InvoiceSearchRequest(bind$0040_2.Page, bind$0040_2.PageSize, bind$0040_2.OrderBy, bind$0040_2.OrderDirection, bind$0040_2.InvoiceNumber, bind$0040_2.FromDate, bind$0040_2.ToDate, map_1((x_2) => x_2.Id, state.Accounts))), (arg_2) => (new Msg(2, [new AsyncMsg$1(1, [arg_2])]))), new Intent(1, [])];
            }
        case 3:
            return [new State(state.Accounts, state.User, msg.fields[0], state.InvoiceSearchQuery, state.PreviousInvoiceSearchQuery, state.InvoicesDeferred, state.ExportInvoiceMetadataListDeferred, state.DownloadInvoiceDeferred, state.InvoicesToZip, state.ExportInvoicesAsZipDeferred), singleton((dispatch) => {
                dispatch(new Msg(4, [new AsyncMsg$1(0, [])]));
            }), new Intent(1, [])];
        case 4:
            if (msg.fields[0].tag === 1) {
                return [new State(state.Accounts, state.User, state.SelectedInvoice, state.InvoiceSearchQuery, state.PreviousInvoiceSearchQuery, state.InvoicesDeferred, state.ExportInvoiceMetadataListDeferred, new Deferred$1(2, [msg.fields[0].fields[0]]), state.InvoicesToZip, state.ExportInvoicesAsZipDeferred), (msg.fields[0].fields[0].tag === 1) ? Alert_error(Alert_title("Download Invoices", Alert_text(msg.fields[0].fields[0].fields[0]))) : Cmd_none(), new Intent(1, [])];
            }
            else if (equals(state.DownloadInvoiceDeferred, new Deferred$1(1, []))) {
                return [state, Cmd_none(), new Intent(1, [])];
            }
            else {
                const matchValue = state.SelectedInvoice;
                if (matchValue != null) {
                    const selectedInvoice = matchValue;
                    return [new State(state.Accounts, state.User, state.SelectedInvoice, state.InvoiceSearchQuery, state.PreviousInvoiceSearchQuery, state.InvoicesDeferred, state.ExportInvoiceMetadataListDeferred, new Deferred$1(1, []), state.InvoicesToZip, state.ExportInvoicesAsZipDeferred), Cmd_OfAsyncWith_perform((computation_2) => {
                        startImmediate(computation_2);
                    }, () => downloadInvoice(selectedInvoice.Id, selectedInvoice.InvoiceNumber, selectedInvoice.FileExtension), void 0, (arg_4) => (new Msg(4, [new AsyncMsg$1(1, [arg_4])]))), new Intent(1, [])];
                }
                else {
                    return [state, Cmd_none(), new Intent(1, [])];
                }
            }
        case 5:
            return [new State(state.Accounts, state.User, state.SelectedInvoice, state.InvoiceSearchQuery, state.PreviousInvoiceSearchQuery, state.InvoicesDeferred, state.ExportInvoiceMetadataListDeferred, state.DownloadInvoiceDeferred, cons(msg.fields[0], state.InvoicesToZip), state.ExportInvoicesAsZipDeferred), Cmd_none(), new Intent(1, [])];
        case 6:
            return [new State(state.Accounts, state.User, state.SelectedInvoice, state.InvoiceSearchQuery, state.PreviousInvoiceSearchQuery, state.InvoicesDeferred, state.ExportInvoiceMetadataListDeferred, state.DownloadInvoiceDeferred, filter((i) => (i.Id !== msg.fields[0].Id), state.InvoicesToZip), state.ExportInvoicesAsZipDeferred), Cmd_none(), new Intent(1, [])];
        case 7:
            if (msg.fields[0].tag === 1) {
                return [new State(state.Accounts, state.User, state.SelectedInvoice, state.InvoiceSearchQuery, state.PreviousInvoiceSearchQuery, state.InvoicesDeferred, state.ExportInvoiceMetadataListDeferred, state.DownloadInvoiceDeferred, state.InvoicesToZip, new Deferred$1(2, [msg.fields[0].fields[0]])), (msg.fields[0].fields[0].tag === 1) ? Alert_error(Alert_title("Export Invoices", Alert_text(msg.fields[0].fields[0].fields[0]))) : Cmd_none(), new Intent(1, [])];
            }
            else if (equals(state.ExportInvoicesAsZipDeferred, new Deferred$1(1, []))) {
                return [state, Cmd_none(), new Intent(1, [])];
            }
            else if (equals(state.InvoicesToZip, empty())) {
                return [state, Cmd_none(), new Intent(1, [])];
            }
            else {
                return [new State(state.Accounts, state.User, state.SelectedInvoice, state.InvoiceSearchQuery, state.PreviousInvoiceSearchQuery, state.InvoicesDeferred, state.ExportInvoiceMetadataListDeferred, state.DownloadInvoiceDeferred, empty(), new Deferred$1(1, [])), Cmd_OfAsyncWith_perform((computation_3) => {
                    startImmediate(computation_3);
                }, exportInvoicesAsZip, state.InvoicesToZip, (arg_6) => (new Msg(7, [new AsyncMsg$1(1, [arg_6])]))), new Intent(1, [])];
            }
        case 8: {
            const newState_1 = new State(state.Accounts, state.User, state.SelectedInvoice, defaultInvoiceSearchRequest, state.PreviousInvoiceSearchQuery, state.InvoicesDeferred, state.ExportInvoiceMetadataListDeferred, state.DownloadInvoiceDeferred, state.InvoicesToZip, state.ExportInvoicesAsZipDeferred);
            return [newState_1, singleton((dispatch_1) => {
                dispatch_1(new Msg(1, [new AsyncMsg$1(0, []), false]));
            }), new Intent(0, [newState_1.InvoiceSearchQuery])];
        }
        case 9:
            return [new State(state.Accounts, state.User, state.SelectedInvoice, state.InvoiceSearchQuery, (bind$0040_3 = state.PreviousInvoiceSearchQuery, new InvoiceSearchRequest(msg.fields[0], bind$0040_3.PageSize, bind$0040_3.OrderBy, bind$0040_3.OrderDirection, bind$0040_3.InvoiceNumber, bind$0040_3.FromDate, bind$0040_3.ToDate, bind$0040_3.AccountIds)), state.InvoicesDeferred, state.ExportInvoiceMetadataListDeferred, state.DownloadInvoiceDeferred, state.InvoicesToZip, state.ExportInvoicesAsZipDeferred), singleton((dispatch_2) => {
                dispatch_2(new Msg(1, [new AsyncMsg$1(0, []), true]));
            }), new Intent(1, [])];
        default:
            return [new State(state.Accounts, state.User, state.SelectedInvoice, msg.fields[0](state.InvoiceSearchQuery), state.PreviousInvoiceSearchQuery, state.InvoicesDeferred, state.ExportInvoiceMetadataListDeferred, state.DownloadInvoiceDeferred, state.InvoicesToZip, state.ExportInvoicesAsZipDeferred), Cmd_none(), new Intent(1, [])];
    }
}

export function render(state, dispatch) {
    let elems_5, elems_1, elems, value_28, elems_2, elems_3, value_58, elems_4, value_80, elems_13, elems_12, elems_11, elems_6, elems_7, matchValue_2, elems_10, elems_23, elems_22, elems_21, elems_14, children, elems_20, elems_27, elems_26;
    const divider = createElement("hr", {
        className: "my-4",
    });
    let searchControls;
    const labelCss = ofArray(["is-block", "mb-1", "has-text-weight-semibold"]);
    const inputCss = ofArray(["input", "is-small", "is-fullwidth"]);
    searchControls = createElement("form", createObj(ofArray([["onSubmit", (e) => {
        e.preventDefault();
        if (state.Accounts.length === 0) {
        }
        else {
            dispatch(new Msg(1, [new AsyncMsg$1(0, []), false]));
        }
    }], ["className", "box"], ["style", {
        minWidth: 300,
    }], (elems_5 = [createElement("div", createObj(ofArray([["className", "block"], (elems_1 = [createElement("div", createObj(ofArray([["className", join(" ", ["is-flex", "is-justify-content-space-between", "is-align-items-center", "mb-1"])], (elems = [createElement("label", {
        className: "has-text-weight-semibold",
        for: "invoiceNumber",
        children: "Invoice Number",
    })], ["children", Interop_reactApi.Children.toArray(Array.from(elems))])]))), createElement("input", createObj(ofArray([["className", join(" ", inputCss)], ["id", "invoiceNumber"], ["name", "invoiceNumber"], ["type", "text"], ["maxLength", 50], ["placeholder", "Invoice Number..."], (value_28 = state.InvoiceSearchQuery.InvoiceNumber, ["ref", (e_1) => {
        if (!(e_1 == null) && !equals(e_1.value, value_28)) {
            e_1.value = value_28;
        }
    }]), ["onChange", (ev) => {
        const value_30 = ev.target.value;
        dispatch(new Msg(0, [(query) => (new InvoiceSearchRequest(query.Page, query.PageSize, query.OrderBy, query.OrderDirection, value_30, query.FromDate, query.ToDate, query.AccountIds))]));
    }]])))], ["children", Interop_reactApi.Children.toArray(Array.from(elems_1))])]))), createElement("div", createObj(ofArray([["className", "block"], (elems_2 = [createElement("label", {
        className: join(" ", labelCss),
        for: "invoiceDate",
        children: "Invoice Date",
    })], ["children", Interop_reactApi.Children.toArray(Array.from(elems_2))])]))), createElement("div", createObj(ofArray([["className", "block"], (elems_3 = [createElement("label", {
        className: join(" ", labelCss),
        for: "fromDate",
        children: "From",
    }), createElement("input", createObj(ofArray([["className", join(" ", inputCss)], ["id", "fromDate"], ["name", "fromDate"], ["type", "date"], ["min", toString(minValue(), "yyyy-MM-dd")], ["max", toString(maxValue(), "yyyy-MM-dd")], (value_58 = toDateTimeOffsetInput(state.InvoiceSearchQuery.FromDate), ["ref", (e_2) => {
        if (!(e_2 == null) && !equals(e_2.value, value_58)) {
            e_2.value = value_58;
        }
    }]), ["onChange", (e_3) => {
        const element = e_3.target;
        dispatch(new Msg(0, [(query_1) => (new InvoiceSearchRequest(query_1.Page, query_1.PageSize, query_1.OrderBy, query_1.OrderDirection, query_1.InvoiceNumber, isNullOrWhiteSpace(element.value) ? defaultOf() : toNullable(DateTimeOffset_fromString(element.value)), query_1.ToDate, query_1.AccountIds))]));
    }]])))], ["children", Interop_reactApi.Children.toArray(Array.from(elems_3))])]))), createElement("div", createObj(ofArray([["className", "block"], (elems_4 = [createElement("label", {
        className: join(" ", labelCss),
        for: "toDate",
        children: "To",
    }), createElement("input", createObj(ofArray([["className", join(" ", inputCss)], ["id", "toDate"], ["name", "toDate"], ["type", "date"], ["min", toString(minValue(), "yyyy-MM-dd")], ["max", toString(maxValue(), "yyyy-MM-dd")], (value_80 = toDateTimeOffsetInput(state.InvoiceSearchQuery.ToDate), ["ref", (e_4) => {
        if (!(e_4 == null) && !equals(e_4.value, value_80)) {
            e_4.value = value_80;
        }
    }]), ["onChange", (e_5) => {
        const element_1 = e_5.target;
        dispatch(new Msg(0, [(query_2) => (new InvoiceSearchRequest(query_2.Page, query_2.PageSize, query_2.OrderBy, query_2.OrderDirection, query_2.InvoiceNumber, query_2.FromDate, isNullOrWhiteSpace(element_1.value) ? defaultOf() : toNullable(DateTimeOffset_fromString(element_1.value)), query_2.AccountIds))]));
    }]])))], ["children", Interop_reactApi.Children.toArray(Array.from(elems_4))])]))), divider, createElement("button", {
        className: join(" ", toList(delay(() => append(singleton_1("block"), delay(() => append(singleton_1("button"), delay(() => append(singleton_1("is-small"), delay(() => append(singleton_1("is-primary"), delay(() => append(singleton_1("is-fullwidth"), delay(() => (equals(state.InvoicesDeferred, new Deferred$1(1, [])) ? singleton_1("is-loading") : empty_1())))))))))))))),
        type: "submit",
        children: "Search",
        disabled: state.Accounts.length === 0,
    }), createElement("button", {
        className: join(" ", toList(delay(() => append(singleton_1("block"), delay(() => append(singleton_1("button"), delay(() => append(singleton_1("is-small"), delay(() => append(singleton_1("is-fullwidth"), delay(() => (equals(state.InvoicesDeferred, new Deferred$1(1, [])) ? singleton_1("is-loading") : empty_1())))))))))))),
        type: "button",
        onClick: (_arg) => {
            dispatch(new Msg(8, []));
        },
        children: "Clear",
    })], ["children", Interop_reactApi.Children.toArray(Array.from(elems_5))])])));
    const header = createElement("div", createObj(ofArray([["className", "block"], (elems_13 = [createElement("div", createObj(ofArray([["className", "box"], (elems_12 = [createElement("div", createObj(ofArray([["className", join(" ", ["columns", "is-align-items-center"])], (elems_11 = [createElement("div", createObj(ofArray([["className", "column"], (elems_6 = [createElement("h1", {
        className: join(" ", ["has-text-weight-semibold", "has-text-link", "is-size-5"]),
        children: "Billing",
    })], ["children", Interop_reactApi.Children.toArray(Array.from(elems_6))])]))), createElement("div", createObj(ofArray([["className", "column"], (elems_7 = [createElement("div", {
        className: "has-text-weight-semibold",
        children: (matchValue_2 = state.InvoicesDeferred, (matchValue_2.tag === 1) ? "Searching..." : ((matchValue_2.tag === 2) ? ((matchValue_2.fields[0].tag === 0) ? ((compare(matchValue_2.fields[0].fields[0].TotalCount, toInt64(fromInt32(0))) > 0) ? (`${formatInt(~~toInt32(matchValue_2.fields[0].fields[0].TotalCount))} invoices found | showing page ${formatInt(matchValue_2.fields[0].fields[0].Page)} of ${formatInt(matchValue_2.fields[0].fields[0].NumberOfPages)}`) : "0 invoices found") : "") : "")),
    })], ["children", Interop_reactApi.Children.toArray(Array.from(elems_7))])]))), createElement("div", createObj(ofArray([["className", "column is-narrow"], (elems_10 = toList(delay(() => {
        let elems_8;
        return append(singleton_1(createElement("button", createObj(ofArray([["className", join(" ", ["button", "is-small", "mr-2"])], ["type", "button"], ["disabled", !hasPreviousPage(state)], ["onClick", (_arg_1) => {
            if (hasPreviousPage(state)) {
                dispatch(new Msg(9, [state.PreviousInvoiceSearchQuery.Page - 1]));
            }
        }], (elems_8 = [createElement("span", {
            className: join(" ", ["material-icons", "is-small"]),
            children: "navigate_before",
        }), createElement("span", {
            className: "is-sr-only",
            children: "Previous",
        })], ["children", Interop_reactApi.Children.toArray(Array.from(elems_8))])])))), delay(() => {
            let elems_9;
            return append(singleton_1(createElement("button", createObj(ofArray([["className", join(" ", ["button", "is-small", "mr-2"])], ["type", "button"], ["disabled", !hasNextPage(state)], ["onClick", (_arg_2) => {
                if (hasNextPage(state)) {
                    dispatch(new Msg(9, [state.PreviousInvoiceSearchQuery.Page + 1]));
                }
            }], (elems_9 = [createElement("span", {
                className: join(" ", ["material-icons", "is-small"]),
                children: "navigate_next",
            }), createElement("span", {
                className: "is-sr-only",
                children: "Next",
            })], ["children", Interop_reactApi.Children.toArray(Array.from(elems_9))])])))), delay(() => append(state.User.CanExportBilling ? singleton_1(createElement("button", {
                className: join(" ", ["button", "is-small", "mr-2", equals(state.InvoicesToZip, empty()) ? "is-hidden" : ""]),
                hidden: true,
                type: "button",
                children: (state.ExportInvoicesAsZipDeferred.tag === 1) ? "Creating zip..." : "Download zip archive",
                disabled: equals(state.ExportInvoicesAsZipDeferred, new Deferred$1(1, [])),
                onClick: (_arg_3) => {
                    dispatch(new Msg(7, [new AsyncMsg$1(0, [])]));
                },
            })) : empty_1(), delay(() => (state.User.CanExportSearchResults ? singleton_1(createElement("button", {
                className: join(" ", ["button", "is-small"]),
                type: "button",
                children: (state.ExportInvoiceMetadataListDeferred.tag === 1) ? "Exporting..." : "Export",
                disabled: equals(state.ExportInvoiceMetadataListDeferred, new Deferred$1(1, [])),
                onClick: (_arg_4) => {
                    dispatch(new Msg(2, [new AsyncMsg$1(0, [])]));
                },
            })) : empty_1())))));
        }));
    })), ["children", Interop_reactApi.Children.toArray(Array.from(elems_10))])])))], ["children", Interop_reactApi.Children.toArray(Array.from(elems_11))])])))], ["children", Interop_reactApi.Children.toArray(Array.from(elems_12))])])))], ["children", Interop_reactApi.Children.toArray(Array.from(elems_13))])])));
    let searchResult;
    const matchValue_5 = state.InvoicesDeferred;
    searchResult = ((matchValue_5.tag === 1) ? render_1() : ((matchValue_5.tag === 2) ? ((matchValue_5.fields[0].tag === 0) ? createElement("div", createObj(ofArray([["className", "box"], (elems_23 = [createElement("div", createObj(ofArray([["className", "table-container"], (elems_22 = [createElement("table", createObj(ofArray([["className", join(" ", ["table", "is-narrow", "is-hoverable", "is-fullwidth"])], (elems_21 = [createElement("thead", createObj(singleton((elems_14 = [(children = ofArray([createElement("th", {
        className: "p-2",
        scope: "col",
        children: "",
    }), createElement("th", {
        className: "p-2",
        scope: "col",
        children: "Invoice Number",
    }), createElement("th", {
        className: "p-2",
        scope: "col",
        children: "Shipment",
    }), createElement("th", {
        className: "p-2",
        scope: "col",
        children: "Invoice Date",
    }), createElement("th", {
        className: join(" ", ["p-2", "has-text-right"]),
        scope: "col",
        children: "Invoice Amount (USD)",
    }), createElement("th", {
        className: join(" ", ["p-2", "has-text-right"]),
        scope: "col",
        children: "Invoice Amount",
    })]), createElement("tr", {
        children: Interop_reactApi.Children.toArray(Array.from(children)),
    }))], ["children", Interop_reactApi.Children.toArray(Array.from(elems_14))])))), createElement("tbody", createObj(singleton((elems_20 = toList(delay(() => collect((invoice) => {
        let children_2, elems_15, elems_16, elems_19;
        const fileNotFound = !invoice.FileExists;
        const amountUsd = formatDecimalWithUnits("", invoice.AmountUsd);
        return singleton_1((children_2 = ofArray([createElement("td", createObj(ofArray([["className", "p-2"], (elems_15 = toList(delay(() => (fileNotFound ? singleton_1(createElement("p", {
            children: "",
        })) : singleton_1(createElement("input", {
            id: invoice.Id,
            name: invoice.InvoiceNumber,
            type: "checkbox",
            checked: contains(invoice, state.InvoicesToZip, {
                Equals: equals,
                GetHashCode: safeHash,
            }),
            onChange: (ev_1) => {
                const value_224 = ev_1.target.checked;
                if (value_224) {
                    dispatch(new Msg(5, [invoice]));
                }
                else {
                    dispatch(new Msg(6, [invoice]));
                }
            },
        }))))), ["children", Interop_reactApi.Children.toArray(Array.from(elems_15))])]))), createElement("td", createObj(ofArray([["className", "p-2"], (elems_16 = toList(delay(() => {
            let fmt;
            return fileNotFound ? singleton_1(createElement("p", {
                children: invoice.InvoiceNumber,
            })) : singleton_1(createElement("a", createObj(ofArray([["className", join(" ", ["is-link", "is-underlined"])], ["style", {
                overflowWrap: "anywhere",
            }], ["onClick", (_arg_5) => {
                dispatch(new Msg(3, [invoice]));
            }], ((fmt = printf("%s%s"), fmt.cont((value_235) => ["children", value_235])))(invoice.InvoiceNumber)(invoice.FileExtension)]))));
        })), ["children", Interop_reactApi.Children.toArray(Array.from(elems_16))])]))), createElement("td", createObj(ofArray([["className", "p-2"], (elems_19 = toList(delay(() => map_2((shipment) => {
            let elems_18, elems_17;
            return createElement("div", createObj(ofArray([["className", join(" ", ["is-flex", "is-flex-wrap-wrap", "is-align-content-center", "is-align-items-center"])], (elems_18 = [ModeOfTransportIcon_render(new IconType(0, []), shipment.ModeOfTransportIcon), createElement("div", createObj(ofArray([["className", join(" ", ["is-flex", "is-flex-direction-column", "p-2"])], (elems_17 = [createElement("a", {
                className: "is-link",
                href: RouterModule_encodeParts(ofArray(["/shipment-details", (shipment.ShipmentId != null) ? value_279(shipment.ShipmentId) : ""]), 1),
                children: shipment.ShipmentNumber,
            }), createElement("div", {
                children: [shipment.ServiceType],
            }), createElement("div", {
                children: [shipment.ServiceLevel],
            })], ["children", Interop_reactApi.Children.toArray(Array.from(elems_17))])])))], ["children", Interop_reactApi.Children.toArray(Array.from(elems_18))])])));
        }, invoice.Shipments))), ["children", Interop_reactApi.Children.toArray(Array.from(elems_19))])]))), createElement("td", {
            className: "p-2",
            children: DateTime_formatNullable(invoice.InvoiceDate),
        }), createElement("td", {
            className: join(" ", toList(delay(() => append(singleton_1("p-2"), delay(() => append(singleton_1("has-text-right"), delay(() => ((invoice.AmountUsd != null) ? ((compare_1(value_279(invoice.AmountUsd), fromParts(0, 0, 0, false, 0)) < 0) ? singleton_1("has-text-danger") : empty_1()) : empty_1())))))))),
            children: Currency_formatCurrency("en-US", "USD", invoice.AmountUsd),
        }), createElement("td", {
            className: join(" ", toList(delay(() => append(singleton_1("p-2"), delay(() => append(singleton_1("has-text-right"), delay(() => ((invoice.Amount != null) ? ((compare_1(value_279(invoice.Amount), fromParts(0, 0, 0, false, 0)) < 0) ? singleton_1("has-text-danger") : empty_1()) : empty_1())))))))),
            children: Currency_formatCurrency("en-US", invoice.Currency, invoice.Amount),
        })]), createElement("tr", {
            children: Interop_reactApi.Children.toArray(Array.from(children_2)),
        })));
    }, matchValue_5.fields[0].fields[0].Data))), ["children", Interop_reactApi.Children.toArray(Array.from(elems_20))]))))], ["children", Interop_reactApi.Children.toArray(Array.from(elems_21))])])))], ["children", Interop_reactApi.Children.toArray(Array.from(elems_22))])])))], ["children", Interop_reactApi.Children.toArray(Array.from(elems_23))])]))) : defaultOf_1()) : defaultOf_1()));
    if (state.Accounts.length === 0) {
        const children_4 = singleton(createElement("h4", {
            className: join(" ", ["has-text-weight-semibold", "has-text-centered", "is-size-5"]),
            children: "Please select a client account",
        }));
        return createElement("div", {
            children: Interop_reactApi.Children.toArray(Array.from(children_4)),
        });
    }
    else {
        return createElement("div", createObj(singleton((elems_27 = [header, createElement("div", createObj(ofArray([["className", join(" ", ["columns"])], (elems_26 = [createElement("div", {
            className: join(" ", ["column", "is-narrow"]),
            children: Interop_reactApi.Children.toArray([searchControls]),
        }), createElement("div", {
            className: join(" ", ["column"]),
            children: Interop_reactApi.Children.toArray([searchResult]),
        })], ["children", Interop_reactApi.Children.toArray(Array.from(elems_26))])])))], ["children", Interop_reactApi.Children.toArray(Array.from(elems_27))]))));
    }
}

