import { Union } from "./fable_modules/fable-library.4.10.0/Types.js";
import { array_type, union_type } from "./fable_modules/fable-library.4.10.0/Reflection.js";
import { SimpleJson_tryParse } from "./fable_modules/Fable.SimpleJson.3.24.0/./SimpleJson.fs.js";
import { Convert_fromJson } from "./fable_modules/Fable.SimpleJson.3.24.0/./Json.Converter.fs.js";
import { createTypeInfo } from "./fable_modules/Fable.SimpleJson.3.24.0/./TypeInfo.Converter.fs.js";
import { Account_$reflection, AccountGroup_$reflection } from "../CView.DTOs/DTOs.fs.js";
import { Fable_SimpleJson_Json__Json_stringify_Static_4E60E31B } from "./fable_modules/Fable.SimpleJson.3.24.0/Json.Converter.fs.js";
import { formatDateTimeOffset, formatDateTime } from "./Extensions.js";
import { ofNullable, map, defaultArg } from "./fable_modules/fable-library.4.10.0/Option.js";
import { isNullOrWhiteSpace } from "./fable_modules/fable-library.4.10.0/String.js";

export class AsyncMsg$1 extends Union {
    constructor(tag, fields) {
        super();
        this.tag = tag;
        this.fields = fields;
    }
    cases() {
        return ["Started", "Completed"];
    }
}

export function AsyncMsg$1_$reflection(gen0) {
    return union_type("CView.UI.Extensions.AsyncMsg`1", [gen0], AsyncMsg$1, () => [[], [["Item", gen0]]]);
}

export class Deferred$1 extends Union {
    constructor(tag, fields) {
        super();
        this.tag = tag;
        this.fields = fields;
    }
    cases() {
        return ["HasNotStartedYet", "InProgress", "Resolved"];
    }
}

export function Deferred$1_$reflection(gen0) {
    return union_type("CView.UI.Extensions.Deferred`1", [gen0], Deferred$1, () => [[], [], [["Item", gen0]]]);
}

export function Deferred_map(transform, deferred) {
    switch (deferred.tag) {
        case 1:
            return new Deferred$1(1, []);
        case 2:
            return new Deferred$1(2, [transform(deferred.fields[0])]);
        default:
            return new Deferred$1(0, []);
    }
}

export function Deferred_bind(transform, deferred) {
    switch (deferred.tag) {
        case 1:
            return new Deferred$1(1, []);
        case 2:
            return transform(deferred.fields[0]);
        default:
            return new Deferred$1(0, []);
    }
}

export function Deferred_resolved(deferred) {
    switch (deferred.tag) {
        case 1:
            return false;
        case 2:
            return true;
        default:
            return false;
    }
}

export function Deferred_exists(predicate, deferred) {
    switch (deferred.tag) {
        case 1:
            return false;
        case 2:
            return predicate(deferred.fields[0]);
        default:
            return false;
    }
}

export function Deferred_iter(action, deferred) {
    switch (deferred.tag) {
        case 1: {
            break;
        }
        case 2: {
            action(deferred.fields[0]);
            break;
        }
        default:
            0;
    }
}

export function LocalStorage_getUserName() {
    return window.localStorage.getItem("c-view-user-name");
}

export function LocalStorage_getIdToken() {
    return window.localStorage.getItem("c-view-id-token");
}

export function LocalStorage_getGlobalAccount() {
    let matchValue;
    try {
        return (matchValue = SimpleJson_tryParse(window.localStorage.getItem("c-view-global-account")), (matchValue != null) ? Convert_fromJson(matchValue, createTypeInfo(AccountGroup_$reflection())) : (() => {
            throw new Error("Couldn\'t parse the input JSON string because it seems to be invalid");
        })());
    }
    catch (matchValue_1) {
        return void 0;
    }
}

export function LocalStorage_getAccounts() {
    try {
        const matchValue = SimpleJson_tryParse(window.localStorage.getItem("c-view-accounts"));
        if (matchValue != null) {
            return Convert_fromJson(matchValue, createTypeInfo(array_type(Account_$reflection())));
        }
        else {
            throw new Error("Couldn\'t parse the input JSON string because it seems to be invalid");
        }
    }
    catch (matchValue_1) {
        return new Array(0);
    }
}

export function LocalStorage_getOriginUrl() {
    return window.localStorage.getItem("origin-url");
}

export function LocalStorage_setUserName(userName) {
    window.localStorage.setItem("c-view-user-name", userName);
}

export function LocalStorage_setIdToken(idToken) {
    window.localStorage.setItem("c-view-id-token", idToken);
}

export function LocalStorage_setGlobalAccount(value) {
    window.localStorage.setItem("c-view-global-account", Fable_SimpleJson_Json__Json_stringify_Static_4E60E31B(value));
}

export function LocalStorage_setAccounts(value) {
    window.localStorage.setItem("c-view-accounts", Fable_SimpleJson_Json__Json_stringify_Static_4E60E31B(value));
}

export function LocalStorage_setOriginUrl(value) {
    window.localStorage.setItem("origin-url", value);
}

export function LocalStorage_clearOriginUrl() {
    window.localStorage.removeItem("origin-url");
}

export function LocalStorage_clearApplication() {
    window.localStorage.setItem("c-view-user-name", "");
    window.localStorage.setItem("c-view-id-token", "");
    window.localStorage.setItem("c-view-global-account", "");
    window.localStorage.setItem("c-view-accounts", "");
    window.localStorage.setItem("origin-url", "");
}

/**
 * using Fable generate a JavaScript statement
 * that instantiates a TextEncoder and uses its
 * instance method to encode input as Uint8Array
 */
export function Interop_encode(input) {
                    const encoder = new TextEncoder();
                const encodedBytes = encoder.encode(input);
                return encodedBytes;
            ;
}

export const Interop_formatDateTime = formatDateTime;

export const Interop_formatDateTimeOffset = formatDateTimeOffset;

/**
 * Format a date time as month day, year
 */
export function DateTime_format(dateTime) {
    return Interop_formatDateTime(dateTime);
}

/**
 * Format a date time as month day, year
 */
export function DateTime_formatNullable(dateTime) {
    return defaultArg(map(Interop_formatDateTime, ofNullable(dateTime)), "");
}

/**
 * Format a date time offset as month day, year hh:mm:ss AM|PM
 */
export function DateTimeOffset_format(dateTimeOffset) {
    return Interop_formatDateTimeOffset(dateTimeOffset);
}

/**
 * Format a date time offset as month day, year hh:mm:ss AM|PM
 */
export function DateTimeOffset_formatNullable(dateTimeOffset) {
    return defaultArg(map(Interop_formatDateTimeOffset, ofNullable(dateTimeOffset)), "");
}

/**
 * Encode input to byte array
 */
export function String_encode(input) {
    return Interop_encode(input);
}

/**
 * Reason why this is a type extension instead of a module:
 * It makes it super simple to conditionally render elements in
 * the UI. The AutoOpen attribute also makes it discoverable
 * when this namespace is referenced.
 */
export function System_String__String_get_NonEmpty(this$) {
    return !isNullOrWhiteSpace(this$);
}

export function Currency_formatCurrency(locale, currency, value) {
    const options = {
        style: "currency",
        currency: currency,
        minimumFractionDigits: 2,
        maximumFractionDigits: 4,
    };
    return new Intl.NumberFormat(locale, options).format(value);
}

