import { Record } from "../CView.UI/fable_modules/fable-library.4.10.0/Types.js";
import { float64_type, bool_type, decimal_type, record_type, array_type, class_type, string_type, int32_type } from "../CView.UI/fable_modules/fable-library.4.10.0/Reflection.js";

export class PurchaseOrderSearchRequest extends Record {
    constructor(Page, PageSize, OrderBy, OrderDirection, PurchaseOrderNumber, PurchaseOrderPartNumber, WarehouseCode, PurchaseOrderStatus, FromDate, ToDate, AccountIds) {
        super();
        this.Page = (Page | 0);
        this.PageSize = (PageSize | 0);
        this.OrderBy = OrderBy;
        this.OrderDirection = OrderDirection;
        this.PurchaseOrderNumber = PurchaseOrderNumber;
        this.PurchaseOrderPartNumber = PurchaseOrderPartNumber;
        this.WarehouseCode = WarehouseCode;
        this.PurchaseOrderStatus = PurchaseOrderStatus;
        this.FromDate = FromDate;
        this.ToDate = ToDate;
        this.AccountIds = AccountIds;
    }
}

export function PurchaseOrderSearchRequest_$reflection() {
    return record_type("CView.DTOs.Inventory.PurchaseOrderSearchRequest", [], PurchaseOrderSearchRequest, () => [["Page", int32_type], ["PageSize", int32_type], ["OrderBy", string_type], ["OrderDirection", string_type], ["PurchaseOrderNumber", string_type], ["PurchaseOrderPartNumber", string_type], ["WarehouseCode", string_type], ["PurchaseOrderStatus", string_type], ["FromDate", class_type("System.Nullable`1", [class_type("System.DateTimeOffset")])], ["ToDate", class_type("System.Nullable`1", [class_type("System.DateTimeOffset")])], ["AccountIds", array_type(class_type("System.Guid"))]]);
}

export class MaterialReceiptSearchRequest extends Record {
    constructor(Page, PageSize, OrderBy, OrderDirection, MaterialReceiptNumber, MaterialReceiptPartNumber, WarehouseCode, MaterialReceiptStatus, FromDate, ToDate, AccountIds) {
        super();
        this.Page = (Page | 0);
        this.PageSize = (PageSize | 0);
        this.OrderBy = OrderBy;
        this.OrderDirection = OrderDirection;
        this.MaterialReceiptNumber = MaterialReceiptNumber;
        this.MaterialReceiptPartNumber = MaterialReceiptPartNumber;
        this.WarehouseCode = WarehouseCode;
        this.MaterialReceiptStatus = MaterialReceiptStatus;
        this.FromDate = FromDate;
        this.ToDate = ToDate;
        this.AccountIds = AccountIds;
    }
}

export function MaterialReceiptSearchRequest_$reflection() {
    return record_type("CView.DTOs.Inventory.MaterialReceiptSearchRequest", [], MaterialReceiptSearchRequest, () => [["Page", int32_type], ["PageSize", int32_type], ["OrderBy", string_type], ["OrderDirection", string_type], ["MaterialReceiptNumber", string_type], ["MaterialReceiptPartNumber", string_type], ["WarehouseCode", string_type], ["MaterialReceiptStatus", string_type], ["FromDate", class_type("System.Nullable`1", [class_type("System.DateTimeOffset")])], ["ToDate", class_type("System.Nullable`1", [class_type("System.DateTimeOffset")])], ["AccountIds", array_type(class_type("System.Guid"))]]);
}

export class PackingListSearchRequest extends Record {
    constructor(Page, PageSize, OrderBy, OrderDirection, PackingListNumber, ContainerNumber, WarehouseCode, ModeOfTransport, ShipFromDate, ShipToDate, AccountIds) {
        super();
        this.Page = (Page | 0);
        this.PageSize = (PageSize | 0);
        this.OrderBy = OrderBy;
        this.OrderDirection = OrderDirection;
        this.PackingListNumber = PackingListNumber;
        this.ContainerNumber = ContainerNumber;
        this.WarehouseCode = WarehouseCode;
        this.ModeOfTransport = ModeOfTransport;
        this.ShipFromDate = ShipFromDate;
        this.ShipToDate = ShipToDate;
        this.AccountIds = AccountIds;
    }
}

export function PackingListSearchRequest_$reflection() {
    return record_type("CView.DTOs.Inventory.PackingListSearchRequest", [], PackingListSearchRequest, () => [["Page", int32_type], ["PageSize", int32_type], ["OrderBy", string_type], ["OrderDirection", string_type], ["PackingListNumber", string_type], ["ContainerNumber", string_type], ["WarehouseCode", string_type], ["ModeOfTransport", string_type], ["ShipFromDate", class_type("System.Nullable`1", [class_type("System.DateTimeOffset")])], ["ShipToDate", class_type("System.Nullable`1", [class_type("System.DateTimeOffset")])], ["AccountIds", array_type(class_type("System.Guid"))]]);
}

export class PurchaseOrderSearchResult extends Record {
    constructor(Id, PurchaseOrderNumber, PurchaseOrderPartNumber, EnteredDate, QuantityOrdered, PurchaseOrderStatus) {
        super();
        this.Id = Id;
        this.PurchaseOrderNumber = PurchaseOrderNumber;
        this.PurchaseOrderPartNumber = PurchaseOrderPartNumber;
        this.EnteredDate = EnteredDate;
        this.QuantityOrdered = QuantityOrdered;
        this.PurchaseOrderStatus = PurchaseOrderStatus;
    }
}

export function PurchaseOrderSearchResult_$reflection() {
    return record_type("CView.DTOs.Inventory.PurchaseOrderSearchResult", [], PurchaseOrderSearchResult, () => [["Id", class_type("System.Guid")], ["PurchaseOrderNumber", string_type], ["PurchaseOrderPartNumber", string_type], ["EnteredDate", class_type("System.Nullable`1", [class_type("System.DateTime")])], ["QuantityOrdered", decimal_type], ["PurchaseOrderStatus", string_type]]);
}

export class MaterialReceiptSearchResult extends Record {
    constructor(Id, MaterialReceiptNumber, ReceivedDate, QuantityReceived, MaterialReceiptStatus) {
        super();
        this.Id = Id;
        this.MaterialReceiptNumber = MaterialReceiptNumber;
        this.ReceivedDate = ReceivedDate;
        this.QuantityReceived = QuantityReceived;
        this.MaterialReceiptStatus = MaterialReceiptStatus;
    }
}

export function MaterialReceiptSearchResult_$reflection() {
    return record_type("CView.DTOs.Inventory.MaterialReceiptSearchResult", [], MaterialReceiptSearchResult, () => [["Id", class_type("System.Guid")], ["MaterialReceiptNumber", string_type], ["ReceivedDate", class_type("System.Nullable`1", [class_type("System.DateTimeOffset")])], ["QuantityReceived", class_type("System.Nullable`1", [decimal_type])], ["MaterialReceiptStatus", string_type]]);
}

export class PackingListSearchResult extends Record {
    constructor(Id, PackingListNumber, ShipmentId, ShipmentNumber, ShipDate, ModeOfTransport) {
        super();
        this.Id = Id;
        this.PackingListNumber = PackingListNumber;
        this.ShipmentId = ShipmentId;
        this.ShipmentNumber = ShipmentNumber;
        this.ShipDate = ShipDate;
        this.ModeOfTransport = ModeOfTransport;
    }
}

export function PackingListSearchResult_$reflection() {
    return record_type("CView.DTOs.Inventory.PackingListSearchResult", [], PackingListSearchResult, () => [["Id", class_type("System.Guid")], ["PackingListNumber", string_type], ["ShipmentId", class_type("System.Nullable`1", [class_type("System.Guid")])], ["ShipmentNumber", string_type], ["ShipDate", class_type("System.Nullable`1", [class_type("System.DateTime")])], ["ModeOfTransport", string_type]]);
}

export class MaterialReceiptItem extends Record {
    constructor(Id, PurchaseOrderLineNumber, MaterialReceiptId, MaterialReceiptNumber, PurchaseOrderLineItemId, PurchaseOrderId, PurchaseOrderNumber, VendorName, StatusName, MaterialReceiptItem, QuantityReceived, QuantityReceivedUnit, Description, PartNumber) {
        super();
        this.Id = Id;
        this.PurchaseOrderLineNumber = PurchaseOrderLineNumber;
        this.MaterialReceiptId = MaterialReceiptId;
        this.MaterialReceiptNumber = MaterialReceiptNumber;
        this.PurchaseOrderLineItemId = PurchaseOrderLineItemId;
        this.PurchaseOrderId = PurchaseOrderId;
        this.PurchaseOrderNumber = PurchaseOrderNumber;
        this.VendorName = VendorName;
        this.StatusName = StatusName;
        this.MaterialReceiptItem = (MaterialReceiptItem | 0);
        this.QuantityReceived = QuantityReceived;
        this.QuantityReceivedUnit = QuantityReceivedUnit;
        this.Description = Description;
        this.PartNumber = PartNumber;
    }
}

export function MaterialReceiptItem_$reflection() {
    return record_type("CView.DTOs.Inventory.MaterialReceiptItem", [], MaterialReceiptItem, () => [["Id", class_type("System.Guid")], ["PurchaseOrderLineNumber", class_type("System.Nullable`1", [int32_type])], ["MaterialReceiptId", class_type("System.Guid")], ["MaterialReceiptNumber", string_type], ["PurchaseOrderLineItemId", class_type("System.Nullable`1", [class_type("System.Guid")])], ["PurchaseOrderId", class_type("System.Guid")], ["PurchaseOrderNumber", string_type], ["VendorName", string_type], ["StatusName", string_type], ["MaterialReceiptItem", int32_type], ["QuantityReceived", decimal_type], ["QuantityReceivedUnit", string_type], ["Description", string_type], ["PartNumber", string_type]]);
}

export class MaterialReceiptMilestone extends Record {
    constructor(Milestone, LastMilestone, CompletedMilestone, Date$) {
        super();
        this.Milestone = Milestone;
        this.LastMilestone = LastMilestone;
        this.CompletedMilestone = CompletedMilestone;
        this.Date = Date$;
    }
}

export function MaterialReceiptMilestone_$reflection() {
    return record_type("CView.DTOs.Inventory.MaterialReceiptMilestone", [], MaterialReceiptMilestone, () => [["Milestone", string_type], ["LastMilestone", bool_type], ["CompletedMilestone", bool_type], ["Date", class_type("System.Nullable`1", [class_type("System.DateTimeOffset")])]]);
}

export class MaterialReceipt extends Record {
    constructor(Id, MaterialReceiptNumber, StatusName, WarehouseName, MaterialReceiptDate, ReceivedDate, ReleaseToPackDate, PackedDate, ScannedOutDate, MaterialReceiptItems, Milestones) {
        super();
        this.Id = Id;
        this.MaterialReceiptNumber = MaterialReceiptNumber;
        this.StatusName = StatusName;
        this.WarehouseName = WarehouseName;
        this.MaterialReceiptDate = MaterialReceiptDate;
        this.ReceivedDate = ReceivedDate;
        this.ReleaseToPackDate = ReleaseToPackDate;
        this.PackedDate = PackedDate;
        this.ScannedOutDate = ScannedOutDate;
        this.MaterialReceiptItems = MaterialReceiptItems;
        this.Milestones = Milestones;
    }
}

export function MaterialReceipt_$reflection() {
    return record_type("CView.DTOs.Inventory.MaterialReceipt", [], MaterialReceipt, () => [["Id", class_type("System.Guid")], ["MaterialReceiptNumber", string_type], ["StatusName", string_type], ["WarehouseName", string_type], ["MaterialReceiptDate", class_type("System.Nullable`1", [class_type("System.DateTimeOffset")])], ["ReceivedDate", class_type("System.Nullable`1", [class_type("System.DateTimeOffset")])], ["ReleaseToPackDate", class_type("System.Nullable`1", [class_type("System.DateTimeOffset")])], ["PackedDate", class_type("System.Nullable`1", [class_type("System.DateTimeOffset")])], ["ScannedOutDate", class_type("System.Nullable`1", [class_type("System.DateTimeOffset")])], ["MaterialReceiptItems", array_type(MaterialReceiptItem_$reflection())], ["Milestones", array_type(MaterialReceiptMilestone_$reflection())]]);
}

export class PurchaseOrderMaterialReceipt extends Record {
    constructor(Id, MaterialReceiptNumber, StatusName, WarehouseName, MaterialReceiptDate, ReceivedDate, ReleaseToPackDate, PackedDate, ScannedOutDate, MaterialReceiptItems, Milestones) {
        super();
        this.Id = Id;
        this.MaterialReceiptNumber = MaterialReceiptNumber;
        this.StatusName = StatusName;
        this.WarehouseName = WarehouseName;
        this.MaterialReceiptDate = MaterialReceiptDate;
        this.ReceivedDate = ReceivedDate;
        this.ReleaseToPackDate = ReleaseToPackDate;
        this.PackedDate = PackedDate;
        this.ScannedOutDate = ScannedOutDate;
        this.MaterialReceiptItems = MaterialReceiptItems;
        this.Milestones = Milestones;
    }
}

export function PurchaseOrderMaterialReceipt_$reflection() {
    return record_type("CView.DTOs.Inventory.PurchaseOrderMaterialReceipt", [], PurchaseOrderMaterialReceipt, () => [["Id", class_type("System.Guid")], ["MaterialReceiptNumber", string_type], ["StatusName", string_type], ["WarehouseName", string_type], ["MaterialReceiptDate", class_type("System.Nullable`1", [class_type("System.DateTimeOffset")])], ["ReceivedDate", class_type("System.Nullable`1", [class_type("System.DateTimeOffset")])], ["ReleaseToPackDate", class_type("System.Nullable`1", [class_type("System.DateTimeOffset")])], ["PackedDate", class_type("System.Nullable`1", [class_type("System.DateTimeOffset")])], ["ScannedOutDate", class_type("System.Nullable`1", [class_type("System.DateTimeOffset")])], ["MaterialReceiptItems", array_type(MaterialReceiptItem_$reflection())], ["Milestones", array_type(MaterialReceiptMilestone_$reflection())]]);
}

export class PurchaseOrderLineItem extends Record {
    constructor(Id, PurchaseOrderId, ModeOfTransportName, LineItemNumber, QuantityOrdered, QuantityOrderedUnit, PartNumber, Description, VendorName, EntryDate, PromiseDate, StatusName, HtsCode, ExportControlClassificationNumber) {
        super();
        this.Id = Id;
        this.PurchaseOrderId = PurchaseOrderId;
        this.ModeOfTransportName = ModeOfTransportName;
        this.LineItemNumber = (LineItemNumber | 0);
        this.QuantityOrdered = QuantityOrdered;
        this.QuantityOrderedUnit = QuantityOrderedUnit;
        this.PartNumber = PartNumber;
        this.Description = Description;
        this.VendorName = VendorName;
        this.EntryDate = EntryDate;
        this.PromiseDate = PromiseDate;
        this.StatusName = StatusName;
        this.HtsCode = HtsCode;
        this.ExportControlClassificationNumber = ExportControlClassificationNumber;
    }
}

export function PurchaseOrderLineItem_$reflection() {
    return record_type("CView.DTOs.Inventory.PurchaseOrderLineItem", [], PurchaseOrderLineItem, () => [["Id", class_type("System.Guid")], ["PurchaseOrderId", class_type("System.Guid")], ["ModeOfTransportName", string_type], ["LineItemNumber", int32_type], ["QuantityOrdered", decimal_type], ["QuantityOrderedUnit", string_type], ["PartNumber", string_type], ["Description", string_type], ["VendorName", string_type], ["EntryDate", class_type("System.Nullable`1", [class_type("System.DateTimeOffset")])], ["PromiseDate", class_type("System.Nullable`1", [class_type("System.DateTimeOffset")])], ["StatusName", string_type], ["HtsCode", string_type], ["ExportControlClassificationNumber", string_type]]);
}

export class PurchaseOrderPackingListLowBox extends Record {
    constructor(PackingListId, MaterialReceiptId, MaterialReceiptNumber, MaterialReceiptItem, BoxNumber, PurchaseOrderId, PurchaseOrderNumber, PurchaseOrderLineNumber, Vendor, PartNumber, Description, QuantityReceived, QuantityReceivedUnit, PromiseDate) {
        super();
        this.PackingListId = PackingListId;
        this.MaterialReceiptId = MaterialReceiptId;
        this.MaterialReceiptNumber = MaterialReceiptNumber;
        this.MaterialReceiptItem = (MaterialReceiptItem | 0);
        this.BoxNumber = BoxNumber;
        this.PurchaseOrderId = PurchaseOrderId;
        this.PurchaseOrderNumber = PurchaseOrderNumber;
        this.PurchaseOrderLineNumber = (PurchaseOrderLineNumber | 0);
        this.Vendor = Vendor;
        this.PartNumber = PartNumber;
        this.Description = Description;
        this.QuantityReceived = QuantityReceived;
        this.QuantityReceivedUnit = QuantityReceivedUnit;
        this.PromiseDate = PromiseDate;
    }
}

export function PurchaseOrderPackingListLowBox_$reflection() {
    return record_type("CView.DTOs.Inventory.PurchaseOrderPackingListLowBox", [], PurchaseOrderPackingListLowBox, () => [["PackingListId", class_type("System.Guid")], ["MaterialReceiptId", class_type("System.Guid")], ["MaterialReceiptNumber", string_type], ["MaterialReceiptItem", int32_type], ["BoxNumber", string_type], ["PurchaseOrderId", class_type("System.Guid")], ["PurchaseOrderNumber", string_type], ["PurchaseOrderLineNumber", int32_type], ["Vendor", string_type], ["PartNumber", string_type], ["Description", string_type], ["QuantityReceived", decimal_type], ["QuantityReceivedUnit", string_type], ["PromiseDate", class_type("System.Nullable`1", [class_type("System.DateTimeOffset")])]]);
}

export class PackinglistMaterialReceiptItemExport extends Record {
    constructor(Id, MaterialReceiptId, PurchaseOrderNumber, MaterialReceiptNumber, BoxNumber, LineItemNumber, MaterialReceiptItem, QuantityReceived, QuantityReceivedUnit, StatusName, Vendor, PartNumber, Description) {
        super();
        this.Id = Id;
        this.MaterialReceiptId = MaterialReceiptId;
        this.PurchaseOrderNumber = PurchaseOrderNumber;
        this.MaterialReceiptNumber = MaterialReceiptNumber;
        this.BoxNumber = BoxNumber;
        this.LineItemNumber = (LineItemNumber | 0);
        this.MaterialReceiptItem = (MaterialReceiptItem | 0);
        this.QuantityReceived = QuantityReceived;
        this.QuantityReceivedUnit = QuantityReceivedUnit;
        this.StatusName = StatusName;
        this.Vendor = Vendor;
        this.PartNumber = PartNumber;
        this.Description = Description;
    }
}

export function PackinglistMaterialReceiptItemExport_$reflection() {
    return record_type("CView.DTOs.Inventory.PackinglistMaterialReceiptItemExport", [], PackinglistMaterialReceiptItemExport, () => [["Id", class_type("System.Guid")], ["MaterialReceiptId", class_type("System.Guid")], ["PurchaseOrderNumber", string_type], ["MaterialReceiptNumber", string_type], ["BoxNumber", string_type], ["LineItemNumber", int32_type], ["MaterialReceiptItem", int32_type], ["QuantityReceived", decimal_type], ["QuantityReceivedUnit", string_type], ["StatusName", string_type], ["Vendor", string_type], ["PartNumber", string_type], ["Description", string_type]]);
}

export class PackinglistMaterialReceiptItemExportRequest extends Record {
    constructor(Id) {
        super();
        this.Id = Id;
    }
}

export function PackinglistMaterialReceiptItemExportRequest_$reflection() {
    return record_type("CView.DTOs.Inventory.PackinglistMaterialReceiptItemExportRequest", [], PackinglistMaterialReceiptItemExportRequest, () => [["Id", class_type("System.Guid")]]);
}

export class PackingListMaterialReceipt extends Record {
    constructor(Id, MaterialReceiptNumber, StatusName, WarehouseName, MaterialReceiptDate, ReceivedDate, ReleaseToPackDate, PackedDate, ScannedOutDate, MaterialReceiptItems) {
        super();
        this.Id = Id;
        this.MaterialReceiptNumber = MaterialReceiptNumber;
        this.StatusName = StatusName;
        this.WarehouseName = WarehouseName;
        this.MaterialReceiptDate = MaterialReceiptDate;
        this.ReceivedDate = ReceivedDate;
        this.ReleaseToPackDate = ReleaseToPackDate;
        this.PackedDate = PackedDate;
        this.ScannedOutDate = ScannedOutDate;
        this.MaterialReceiptItems = MaterialReceiptItems;
    }
}

export function PackingListMaterialReceipt_$reflection() {
    return record_type("CView.DTOs.Inventory.PackingListMaterialReceipt", [], PackingListMaterialReceipt, () => [["Id", class_type("System.Guid")], ["MaterialReceiptNumber", string_type], ["StatusName", string_type], ["WarehouseName", string_type], ["MaterialReceiptDate", class_type("System.Nullable`1", [class_type("System.DateTimeOffset")])], ["ReceivedDate", class_type("System.Nullable`1", [class_type("System.DateTimeOffset")])], ["ReleaseToPackDate", class_type("System.Nullable`1", [class_type("System.DateTimeOffset")])], ["PackedDate", class_type("System.Nullable`1", [class_type("System.DateTimeOffset")])], ["ScannedOutDate", class_type("System.Nullable`1", [class_type("System.DateTimeOffset")])], ["MaterialReceiptItems", array_type(MaterialReceiptItem_$reflection())]]);
}

export class PackingListLowBox extends Record {
    constructor(BoxNumber, MaterialReceipts) {
        super();
        this.BoxNumber = BoxNumber;
        this.MaterialReceipts = MaterialReceipts;
    }
}

export function PackingListLowBox_$reflection() {
    return record_type("CView.DTOs.Inventory.PackingListLowBox", [], PackingListLowBox, () => [["BoxNumber", string_type], ["MaterialReceipts", array_type(PackingListMaterialReceipt_$reflection())]]);
}

export class PackingList extends Record {
    constructor(Id, PackingListNumber, ShipDate, ModeOfTransportName, ModeOfTransportIcon, ImperialVolume, ImperialVolumeUnit, ImperialWeight, ImperialWeightUnit, MetricVolume, MetricVolumeUnit, MetricWeight, MetricWeightUnit, ShipmentId, ShipmentNumber, ServiceLevelName, ServiceTypeName, Boxes) {
        super();
        this.Id = Id;
        this.PackingListNumber = PackingListNumber;
        this.ShipDate = ShipDate;
        this.ModeOfTransportName = ModeOfTransportName;
        this.ModeOfTransportIcon = ModeOfTransportIcon;
        this.ImperialVolume = ImperialVolume;
        this.ImperialVolumeUnit = ImperialVolumeUnit;
        this.ImperialWeight = ImperialWeight;
        this.ImperialWeightUnit = ImperialWeightUnit;
        this.MetricVolume = MetricVolume;
        this.MetricVolumeUnit = MetricVolumeUnit;
        this.MetricWeight = MetricWeight;
        this.MetricWeightUnit = MetricWeightUnit;
        this.ShipmentId = ShipmentId;
        this.ShipmentNumber = ShipmentNumber;
        this.ServiceLevelName = ServiceLevelName;
        this.ServiceTypeName = ServiceTypeName;
        this.Boxes = Boxes;
    }
}

export function PackingList_$reflection() {
    return record_type("CView.DTOs.Inventory.PackingList", [], PackingList, () => [["Id", class_type("System.Guid")], ["PackingListNumber", string_type], ["ShipDate", class_type("System.Nullable`1", [class_type("System.DateTime")])], ["ModeOfTransportName", string_type], ["ModeOfTransportIcon", string_type], ["ImperialVolume", class_type("System.Nullable`1", [decimal_type])], ["ImperialVolumeUnit", string_type], ["ImperialWeight", class_type("System.Nullable`1", [decimal_type])], ["ImperialWeightUnit", string_type], ["MetricVolume", class_type("System.Nullable`1", [decimal_type])], ["MetricVolumeUnit", string_type], ["MetricWeight", class_type("System.Nullable`1", [decimal_type])], ["MetricWeightUnit", string_type], ["ShipmentId", class_type("System.Nullable`1", [class_type("System.Guid")])], ["ShipmentNumber", string_type], ["ServiceLevelName", string_type], ["ServiceTypeName", string_type], ["Boxes", array_type(PackingListLowBox_$reflection())]]);
}

export class PurchaseOrderPackingList extends Record {
    constructor(Id, PackingListNumber, ShipDate, ModeOfTransportName, ImperialVolume, ImperialVolumeUnit, ImperialWeight, ImperialWeightUnit, MetricVolume, MetricVolumeUnit, MetricWeight, MetricWeightUnit, ShipmentId, ShipmentNumber, ServiceTypeName, ServiceLevelName, ModeOfTransportIcon) {
        super();
        this.Id = Id;
        this.PackingListNumber = PackingListNumber;
        this.ShipDate = ShipDate;
        this.ModeOfTransportName = ModeOfTransportName;
        this.ImperialVolume = ImperialVolume;
        this.ImperialVolumeUnit = ImperialVolumeUnit;
        this.ImperialWeight = ImperialWeight;
        this.ImperialWeightUnit = ImperialWeightUnit;
        this.MetricVolume = MetricVolume;
        this.MetricVolumeUnit = MetricVolumeUnit;
        this.MetricWeight = MetricWeight;
        this.MetricWeightUnit = MetricWeightUnit;
        this.ShipmentId = ShipmentId;
        this.ShipmentNumber = ShipmentNumber;
        this.ServiceTypeName = ServiceTypeName;
        this.ServiceLevelName = ServiceLevelName;
        this.ModeOfTransportIcon = ModeOfTransportIcon;
    }
}

export function PurchaseOrderPackingList_$reflection() {
    return record_type("CView.DTOs.Inventory.PurchaseOrderPackingList", [], PurchaseOrderPackingList, () => [["Id", class_type("System.Guid")], ["PackingListNumber", string_type], ["ShipDate", class_type("System.Nullable`1", [class_type("System.DateTime")])], ["ModeOfTransportName", string_type], ["ImperialVolume", class_type("System.Nullable`1", [decimal_type])], ["ImperialVolumeUnit", string_type], ["ImperialWeight", class_type("System.Nullable`1", [decimal_type])], ["ImperialWeightUnit", string_type], ["MetricVolume", class_type("System.Nullable`1", [decimal_type])], ["MetricVolumeUnit", string_type], ["MetricWeight", class_type("System.Nullable`1", [decimal_type])], ["MetricWeightUnit", string_type], ["ShipmentId", class_type("System.Nullable`1", [class_type("System.Guid")])], ["ShipmentNumber", string_type], ["ServiceTypeName", string_type], ["ServiceLevelName", string_type], ["ModeOfTransportIcon", string_type]]);
}

export class PurchaseOrder extends Record {
    constructor(Id, PurchaseOrderNumber, StatusName, EnteredDate, LineItems, MaterialReceipts, PackingLists, LineItemCount, QuantityOrderedCount, QuantityReceivedCount, MaterialReceiptCount, LinesShippedCount) {
        super();
        this.Id = Id;
        this.PurchaseOrderNumber = PurchaseOrderNumber;
        this.StatusName = StatusName;
        this.EnteredDate = EnteredDate;
        this.LineItems = LineItems;
        this.MaterialReceipts = MaterialReceipts;
        this.PackingLists = PackingLists;
        this.LineItemCount = (LineItemCount | 0);
        this.QuantityOrderedCount = QuantityOrderedCount;
        this.QuantityReceivedCount = QuantityReceivedCount;
        this.MaterialReceiptCount = (MaterialReceiptCount | 0);
        this.LinesShippedCount = (LinesShippedCount | 0);
    }
}

export function PurchaseOrder_$reflection() {
    return record_type("CView.DTOs.Inventory.PurchaseOrder", [], PurchaseOrder, () => [["Id", class_type("System.Guid")], ["PurchaseOrderNumber", string_type], ["StatusName", string_type], ["EnteredDate", class_type("System.Nullable`1", [class_type("System.DateTimeOffset")])], ["LineItems", array_type(PurchaseOrderLineItem_$reflection())], ["MaterialReceipts", array_type(PurchaseOrderMaterialReceipt_$reflection())], ["PackingLists", array_type(PurchaseOrderPackingList_$reflection())], ["LineItemCount", int32_type], ["QuantityOrderedCount", decimal_type], ["QuantityReceivedCount", decimal_type], ["MaterialReceiptCount", int32_type], ["LinesShippedCount", int32_type]]);
}

export class MaterialReceiptWarehouseCountResponse extends Record {
    constructor(WarehouseName, Count) {
        super();
        this.WarehouseName = WarehouseName;
        this.Count = (Count | 0);
    }
}

export function MaterialReceiptWarehouseCountResponse_$reflection() {
    return record_type("CView.DTOs.Inventory.MaterialReceiptWarehouseCountResponse", [], MaterialReceiptWarehouseCountResponse, () => [["WarehouseName", string_type], ["Count", int32_type]]);
}

export class PurchaseOrderByStatusCountResponse extends Record {
    constructor(StatusName, Count) {
        super();
        this.StatusName = StatusName;
        this.Count = (Count | 0);
    }
}

export function PurchaseOrderByStatusCountResponse_$reflection() {
    return record_type("CView.DTOs.Inventory.PurchaseOrderByStatusCountResponse", [], PurchaseOrderByStatusCountResponse, () => [["StatusName", string_type], ["Count", int32_type]]);
}

export class MaterialReceiptStatusCountResponse extends Record {
    constructor(StatusName, Count) {
        super();
        this.StatusName = StatusName;
        this.Count = (Count | 0);
    }
}

export function MaterialReceiptStatusCountResponse_$reflection() {
    return record_type("CView.DTOs.Inventory.MaterialReceiptStatusCountResponse", [], MaterialReceiptStatusCountResponse, () => [["StatusName", string_type], ["Count", int32_type]]);
}

export class WarehouseLocationWithMaterialReceiptsCount extends Record {
    constructor(WarehouseName, CityName, CityCode, StateOrProvinceCode, CountryCode, Latitude, Longitude, MaterialReceiptCount) {
        super();
        this.WarehouseName = WarehouseName;
        this.CityName = CityName;
        this.CityCode = CityCode;
        this.StateOrProvinceCode = StateOrProvinceCode;
        this.CountryCode = CountryCode;
        this.Latitude = Latitude;
        this.Longitude = Longitude;
        this.MaterialReceiptCount = (MaterialReceiptCount | 0);
    }
}

export function WarehouseLocationWithMaterialReceiptsCount_$reflection() {
    return record_type("CView.DTOs.Inventory.WarehouseLocationWithMaterialReceiptsCount", [], WarehouseLocationWithMaterialReceiptsCount, () => [["WarehouseName", string_type], ["CityName", string_type], ["CityCode", string_type], ["StateOrProvinceCode", string_type], ["CountryCode", string_type], ["Latitude", float64_type], ["Longitude", float64_type], ["MaterialReceiptCount", int32_type]]);
}

export class WarehouseSearchQuery extends Record {
    constructor(SearchCriteria, AccountIds) {
        super();
        this.SearchCriteria = SearchCriteria;
        this.AccountIds = AccountIds;
    }
}

export function WarehouseSearchQuery_$reflection() {
    return record_type("CView.DTOs.Inventory.WarehouseSearchQuery", [], WarehouseSearchQuery, () => [["SearchCriteria", string_type], ["AccountIds", array_type(class_type("System.Guid"))]]);
}

export class WarehouseSearchResponse extends Record {
    constructor(WarehouseCode, WarehouseName) {
        super();
        this.WarehouseCode = WarehouseCode;
        this.WarehouseName = WarehouseName;
    }
}

export function WarehouseSearchResponse_$reflection() {
    return record_type("CView.DTOs.Inventory.WarehouseSearchResponse", [], WarehouseSearchResponse, () => [["WarehouseCode", string_type], ["WarehouseName", string_type]]);
}

