import { Union, Record } from "../fable_modules/fable-library.4.10.0/Types.js";
import { record_type, union_type, string_type, class_type } from "../fable_modules/fable-library.4.10.0/Reflection.js";
import { ContainerItem_$reflection } from "../../CView.DTOs/DTOs.fs.js";
import { FSharpResult$2 } from "../fable_modules/fable-library.4.10.0/Choice.js";
import { DateTimeOffset_formatNullable, AsyncMsg$1, Deferred$1, AsyncMsg$1_$reflection, Deferred$1_$reflection } from "../Extensions.fs.js";
import { SelectableTabs, SelectableTabs_$reflection } from "../Domain/Container.fs.js";
import { ofArray, singleton } from "../fable_modules/fable-library.4.10.0/List.js";
import { Alert_text, Alert_title, Alert_error } from "../Components/SweetAlert.fs.js";
import { Cmd_none } from "../fable_modules/Fable.Elmish.4.0.2/cmd.fs.js";
import { comparePrimitives, createObj, equals } from "../fable_modules/fable-library.4.10.0/Util.js";
import { Cmd_OfAsyncWith_perform } from "../fable_modules/Fable.Elmish.4.0.2/./cmd.fs.js";
import { startImmediate } from "../fable_modules/fable-library.4.10.0/Async.js";
import { find } from "../Api/ContainerApi.fs.js";
import { createElement } from "react";
import { printf, join } from "../fable_modules/fable-library.4.10.0/String.js";
import { map, empty, append, singleton as singleton_1, delay, toList } from "../fable_modules/fable-library.4.10.0/Seq.js";
import { render as render_1 } from "../Components/Loader.fs.js";
import { RouterModule_encodeParts } from "../fable_modules/Feliz.Router.4.0.0/./Router.fs.js";
import { Interop_reactApi } from "../fable_modules/Feliz.2.6.0/./Interop.fs.js";
import { formatDecimalWithUnits, formatDecimal, formatInt } from "../Domain/Formatter.fs.js";
import { IconType, ModeOfTransportIcon_render } from "../Components/ModeOfTransportIcon.fs.js";
import { sortBy } from "../fable_modules/fable-library.4.10.0/Array.js";
import { value as value_353 } from "../fable_modules/fable-library.4.10.0/Option.js";
import { minValue } from "../fable_modules/fable-library.4.10.0/DateOffset.js";
import { compare } from "../fable_modules/fable-library.4.10.0/Date.js";
import { defaultOf } from "../fable_modules/Feliz.2.6.0/../fable-library.4.10.0/Util.js";

export class State extends Record {
    constructor(ContainerId, ShipmentId, Container, ActiveTab) {
        super();
        this.ContainerId = ContainerId;
        this.ShipmentId = ShipmentId;
        this.Container = Container;
        this.ActiveTab = ActiveTab;
    }
}

export function State_$reflection() {
    return record_type("CView.UI.Pages.ContainerDetails.State", [], State, () => [["ContainerId", class_type("System.Guid")], ["ShipmentId", class_type("System.Guid")], ["Container", Deferred$1_$reflection(union_type("Microsoft.FSharp.Core.FSharpResult`2", [ContainerItem_$reflection(), string_type], FSharpResult$2, () => [[["ResultValue", ContainerItem_$reflection()]], [["ErrorValue", string_type]]]))], ["ActiveTab", SelectableTabs_$reflection()]]);
}

export class Msg extends Union {
    constructor(tag, fields) {
        super();
        this.tag = tag;
        this.fields = fields;
    }
    cases() {
        return ["SetActiveTab", "GetContainer"];
    }
}

export function Msg_$reflection() {
    return union_type("CView.UI.Pages.ContainerDetails.Msg", [], Msg, () => [[["Item", SelectableTabs_$reflection()]], [["Item", AsyncMsg$1_$reflection(union_type("Microsoft.FSharp.Core.FSharpResult`2", [ContainerItem_$reflection(), string_type], FSharpResult$2, () => [[["ResultValue", ContainerItem_$reflection()]], [["ErrorValue", string_type]]]))]]]);
}

export function init(containertId, shipmentId) {
    return [new State(containertId, shipmentId, new Deferred$1(0, []), new SelectableTabs(0, [])), singleton((dispatch) => {
        dispatch(new Msg(1, [new AsyncMsg$1(0, [])]));
    })];
}

export function update(msg, state) {
    if (msg.tag === 1) {
        if (msg.fields[0].tag === 1) {
            return [new State(state.ContainerId, state.ShipmentId, new Deferred$1(2, [msg.fields[0].fields[0]]), state.ActiveTab), (msg.fields[0].fields[0].tag === 1) ? Alert_error(Alert_title("Get Container", Alert_text(msg.fields[0].fields[0].fields[0]))) : Cmd_none()];
        }
        else if (equals(state.Container, new Deferred$1(1, []))) {
            return [state, Cmd_none()];
        }
        else {
            return [new State(state.ContainerId, state.ShipmentId, new Deferred$1(1, []), state.ActiveTab), Cmd_OfAsyncWith_perform((computation) => {
                startImmediate(computation);
            }, () => find(state.ContainerId, state.ShipmentId), void 0, (arg) => (new Msg(1, [new AsyncMsg$1(1, [arg])])))];
        }
    }
    else {
        return [new State(state.ContainerId, state.ShipmentId, state.Container, msg.fields[0]), Cmd_none()];
    }
}

export function render(state, dispatch) {
    let elems_40;
    return createElement("div", createObj(ofArray([["className", join(" ", [])], (elems_40 = toList(delay(() => {
        let elems_3, elems_2, children, elems, elems_1;
        const matchValue_1 = state.Container;
        return (matchValue_1.tag === 1) ? singleton_1(render_1()) : ((matchValue_1.tag === 2) ? ((matchValue_1.fields[0].tag === 0) ? append(singleton_1(createElement("nav", createObj(ofArray([["aria-label", "breadcrumbs"], ["className", join(" ", ["breadcrumb", "is-small", "box", "is-flex", "is-flex-wrap-wrap", "is-justify-content-space-between", "is-align-items-center"])], (elems_3 = [createElement("ul", createObj(ofArray([["className", "has-text-weight-semibold"], (elems_2 = [(children = singleton(createElement("a", {
            className: "is-size-6",
            href: RouterModule_encodeParts(singleton("/shipments"), 1),
            children: "Shipments",
        })), createElement("li", {
            children: Interop_reactApi.Children.toArray(Array.from(children)),
        })), createElement("li", createObj(ofArray([["className", "is-active"], (elems = [createElement("a", {
            className: "is-size-6",
            children: "Container",
        })], ["children", Interop_reactApi.Children.toArray(Array.from(elems))])]))), createElement("li", createObj(ofArray([["className", join(" ", ["is-active", "is-size-6"])], (elems_1 = [createElement("a", {
            children: matchValue_1.fields[0].fields[0].Number,
        })], ["children", Interop_reactApi.Children.toArray(Array.from(elems_1))])])))], ["children", Interop_reactApi.Children.toArray(Array.from(elems_2))])])))], ["children", Interop_reactApi.Children.toArray(Array.from(elems_3))])])))), delay(() => {
            let container_1, elems_21, elems_20, elems_6, elems_5, elems_4, elems_19, elems_18, elems_17, elems_7, elems_8, elems_9, elems_10, elems_11, elems_16, elems_12, elems_13, elems_14, elems_15;
            return append(singleton_1((container_1 = matchValue_1.fields[0].fields[0], createElement("div", createObj(ofArray([["className", "box"], (elems_21 = [createElement("div", createObj(ofArray([["className", "columns"], (elems_20 = [createElement("div", createObj(ofArray([["className", join(" ", ["column", "is-narrow"])], (elems_6 = [createElement("div", createObj(ofArray([["className", join(" ", ["is-flex", "is-align-content-top", "is-flex-direction-column"])], (elems_5 = [createElement("h1", {
                className: join(" ", ["is-size-4", "has-text-weight-semibold"]),
                children: container_1.Number,
            }), createElement("div", createObj(ofArray([["className", "is-flex"], (elems_4 = [createElement("span", {
                className: "has-text-weight-semibold",
                children: "Seal",
            }), createElement("span", {
                className: "ml-1",
                children: container_1.SealNumber,
            })], ["children", Interop_reactApi.Children.toArray(Array.from(elems_4))])])))], ["children", Interop_reactApi.Children.toArray(Array.from(elems_5))])])))], ["children", Interop_reactApi.Children.toArray(Array.from(elems_6))])]))), createElement("div", createObj(ofArray([["className", join(" ", ["column"])], (elems_19 = [createElement("div", createObj(ofArray([["className", join(" ", ["block", "ml-6", "py-0"])], (elems_18 = [createElement("div", createObj(ofArray([["className", join(" ", ["columns", "is-multiline", "is-centered"])], (elems_17 = [createElement("div", createObj(ofArray([["className", "column"], (elems_7 = [createElement("p", {
                className: "is-size-6",
                children: "Load Type",
            }), createElement("p", {
                className: join(" ", ["is-size-4", "has-text-weight-bold"]),
                children: container_1.LoadType,
            })], ["children", Interop_reactApi.Children.toArray(Array.from(elems_7))])]))), createElement("div", createObj(ofArray([["className", "column"], (elems_8 = [createElement("p", {
                className: "is-size-6",
                children: "Container Type",
            }), createElement("p", {
                className: join(" ", ["is-size-4", "has-text-weight-bold"]),
                children: container_1.ContainerType,
            })], ["children", Interop_reactApi.Children.toArray(Array.from(elems_8))])]))), createElement("div", createObj(ofArray([["className", "column"], (elems_9 = [createElement("p", {
                className: "is-size-6",
                children: "Pieces",
            }), createElement("p", {
                className: join(" ", ["is-size-4", "has-text-weight-bold"]),
                children: formatInt(container_1.TotalPieces),
            })], ["children", Interop_reactApi.Children.toArray(Array.from(elems_9))])]))), createElement("div", createObj(ofArray([["className", "column"], (elems_10 = [createElement("p", {
                className: "is-size-6",
                children: "Volume",
            }), createElement("p", {
                className: join(" ", ["is-size-4", "has-text-weight-bold"]),
                children: formatDecimal(container_1.TotalVolume),
            })], ["children", Interop_reactApi.Children.toArray(Array.from(elems_10))])]))), createElement("div", createObj(ofArray([["className", "column"], (elems_11 = [createElement("p", {
                className: "is-size-6",
                children: "Weight",
            }), createElement("p", {
                className: join(" ", ["is-size-4", "has-text-weight-bold"]),
                children: formatDecimalWithUnits(container_1.TotalChargeableWeightUnit, container_1.TotalChargeableWeight),
            })], ["children", Interop_reactApi.Children.toArray(Array.from(elems_11))])]))), createElement("div", createObj(ofArray([["className", "column"], (elems_16 = [createElement("div", createObj(ofArray([["className", join(" ", ["is-flex", "is-align-items-center"])], (elems_12 = [ModeOfTransportIcon_render(new IconType(0, []), container_1.ModeOfTransportIcon), createElement("a", {
                className: join(" ", ["is-link"]),
                href: RouterModule_encodeParts(ofArray(["/shipment-details", container_1.ShipmentId]), 1),
                children: container_1.ShipmentNumber,
            })], ["children", Interop_reactApi.Children.toArray(Array.from(elems_12))])]))), createElement("div", createObj(singleton((elems_13 = [createElement("span", {
                className: "has-text-weight-semibold",
                children: "Carrier",
            }), createElement("span", {
                className: "ml-1",
                children: container_1.CarrierName,
            })], ["children", Interop_reactApi.Children.toArray(Array.from(elems_13))])))), createElement("div", createObj(singleton((elems_14 = [createElement("span", {
                className: "has-text-weight-semibold",
                children: "Vessel",
            }), createElement("span", {
                className: "ml-1",
                children: container_1.Vessel,
            })], ["children", Interop_reactApi.Children.toArray(Array.from(elems_14))])))), createElement("div", createObj(singleton((elems_15 = [createElement("span", {
                className: "has-text-weight-semibold",
                children: "Voyage",
            }), createElement("span", {
                className: "ml-1",
                children: container_1.Voyage,
            })], ["children", Interop_reactApi.Children.toArray(Array.from(elems_15))]))))], ["children", Interop_reactApi.Children.toArray(Array.from(elems_16))])])))], ["children", Interop_reactApi.Children.toArray(Array.from(elems_17))])])))], ["children", Interop_reactApi.Children.toArray(Array.from(elems_18))])])))], ["children", Interop_reactApi.Children.toArray(Array.from(elems_19))])])))], ["children", Interop_reactApi.Children.toArray(Array.from(elems_20))])])))], ["children", Interop_reactApi.Children.toArray(Array.from(elems_21))])]))))), delay(() => {
                let container_2, elems_39, elems_37, children_10, elems_35, fmt, elems_36, elems_38;
                return singleton_1((container_2 = matchValue_1.fields[0].fields[0], createElement("div", createObj(ofArray([["className", join(" ", ["box"])], (elems_39 = [createElement("div", createObj(ofArray([["className", join(" ", ["tabs", "is-small", "is-boxed"])], (elems_37 = [(children_10 = ofArray([createElement("li", createObj(ofArray([["className", join(" ", toList(delay(() => append(singleton_1("has-text-weight-semibold"), delay(() => append(singleton_1("tablink"), delay(() => (equals(state.ActiveTab, new SelectableTabs(0, [])) ? singleton_1("is-active") : empty()))))))))], (elems_35 = [createElement("a", createObj(ofArray([["onClick", (_arg_2) => {
                    dispatch(new Msg(0, [new SelectableTabs(0, [])]));
                }], ((fmt = printf("Purchase Order Line Items (%i)"), fmt.cont((value_337) => ["children", value_337])))(container_2.PurchaseOrderLineItems.length)])))], ["children", Interop_reactApi.Children.toArray(Array.from(elems_35))])]))), createElement("li", createObj(ofArray([["className", join(" ", toList(delay(() => append(singleton_1("has-text-weight-semibold"), delay(() => append(singleton_1("tablink"), delay(() => (equals(state.ActiveTab, new SelectableTabs(1, [])) ? singleton_1("is-active") : empty()))))))))], (elems_36 = [createElement("a", {
                    onClick: (_arg_3) => {
                        dispatch(new Msg(0, [new SelectableTabs(1, [])]));
                    },
                    children: "Transit Events",
                })], ["children", Interop_reactApi.Children.toArray(Array.from(elems_36))])])))]), createElement("ul", {
                    children: Interop_reactApi.Children.toArray(Array.from(children_10)),
                }))], ["children", Interop_reactApi.Children.toArray(Array.from(elems_37))])]))), createElement("div", createObj(ofArray([["className", "tabcontent"], (elems_38 = toList(delay(() => {
                    let carrierEvents, elems_34, purchaseOrderLineItems, elems_29;
                    return (state.ActiveTab.tag === 1) ? singleton_1((carrierEvents = container_2.ContainerEvents, createElement("div", createObj(ofArray([["className", "mx-4"], (elems_34 = toList(delay(() => {
                        let elems_33, elems_32, children_8, children_6, elems_31;
                        return (carrierEvents.length === 0) ? singleton_1(createElement("div", {
                            className: "has-text-centered",
                            children: "No container events were found for this container",
                        })) : singleton_1(createElement("div", createObj(ofArray([["className", join(" ", ["table-container", "table-has-fixed-header"])], ["style", {
                            maxHeight: 450 + "px",
                            overflowY: "auto",
                        }], (elems_33 = [createElement("table", createObj(ofArray([["className", join(" ", ["table", "is-narrow", "is-fullwidth"])], (elems_32 = [(children_8 = singleton((children_6 = ofArray([createElement("th", {
                            className: "p-2",
                            children: "Code",
                        }), createElement("th", {
                            className: "p-2",
                            children: "Event",
                        }), createElement("th", {
                            className: "p-2",
                            children: "Date",
                        }), createElement("th", {
                            className: "p-2",
                            children: "Provided By",
                        }), createElement("th", {
                            className: "p-2",
                            children: "Location",
                        })]), createElement("tr", {
                            children: Interop_reactApi.Children.toArray(Array.from(children_6)),
                        }))), createElement("thead", {
                            children: Interop_reactApi.Children.toArray(Array.from(children_8)),
                        })), createElement("tbody", createObj(ofArray([["className", ""], (elems_31 = toList(delay(() => map((carrierEvent) => {
                            let elems_30;
                            return createElement("tr", createObj(singleton((elems_30 = [createElement("td", {
                                className: "p-2",
                                children: carrierEvent.EventCode,
                            }), createElement("td", {
                                className: "p-2",
                                children: carrierEvent.EventName,
                            }), createElement("td", {
                                className: "p-2",
                                children: DateTimeOffset_formatNullable(carrierEvent.EventDateTime),
                            }), createElement("td", {
                                className: "p-2",
                                children: carrierEvent.PortName,
                            }), createElement("td", {
                                className: "p-2",
                                children: carrierEvent.Location,
                            })], ["children", Interop_reactApi.Children.toArray(Array.from(elems_30))]))));
                        }, sortBy((carrierEvent_1) => {
                            const _arg_1 = carrierEvent_1.EventDateTime != null;
                            if (_arg_1) {
                                return value_353(carrierEvent_1.EventDateTime);
                            }
                            else {
                                return minValue();
                            }
                        }, carrierEvents, {
                            Compare: compare,
                        })))), ["children", Interop_reactApi.Children.toArray(Array.from(elems_31))])])))], ["children", Interop_reactApi.Children.toArray(Array.from(elems_32))])])))], ["children", Interop_reactApi.Children.toArray(Array.from(elems_33))])]))));
                    })), ["children", Interop_reactApi.Children.toArray(Array.from(elems_34))])]))))) : singleton_1((purchaseOrderLineItems = container_2.PurchaseOrderLineItems, createElement("div", createObj(ofArray([["className", "mx-4"], (elems_29 = toList(delay(() => {
                        let elems_28, elems_27, children_4, children_2, elems_26;
                        return (purchaseOrderLineItems.length === 0) ? singleton_1(createElement("div", {
                            className: "has-text-centered",
                            children: "No purchase order line items were found for this container",
                        })) : singleton_1(createElement("div", createObj(ofArray([["className", join(" ", ["table-container", "table-has-fixed-header"])], ["style", {
                            maxHeight: 450 + "px",
                            overflowY: "auto",
                        }], (elems_28 = [createElement("table", createObj(ofArray([["className", join(" ", ["table", "is-narrow", "is-fullwidth"])], (elems_27 = [(children_4 = singleton((children_2 = ofArray([createElement("th", {
                            className: "p-2",
                            children: "Box Number",
                        }), createElement("th", {
                            className: "p-2",
                            children: "Packing List Number",
                        }), createElement("th", {
                            className: "p-2",
                            children: "Purchase Order",
                        }), createElement("th", {
                            className: "p-2",
                            children: "PO Item Number",
                        }), createElement("th", {
                            className: "p-2",
                            children: "MR Number",
                        }), createElement("th", {
                            className: "p-2",
                            children: "MR Item",
                        }), createElement("th", {
                            className: "p-2",
                            children: "Vendor",
                        }), createElement("th", {
                            className: "p-2",
                            children: "Part Number",
                        }), createElement("th", {
                            className: "p-2",
                            children: "Description",
                        }), createElement("th", {
                            className: "p-2",
                            children: "Quantity Received",
                        }), createElement("th", {
                            className: "p-2",
                            children: "Promise Date",
                        })]), createElement("tr", {
                            children: Interop_reactApi.Children.toArray(Array.from(children_2)),
                        }))), createElement("thead", {
                            children: Interop_reactApi.Children.toArray(Array.from(children_4)),
                        })), createElement("tbody", createObj(ofArray([["className", ""], (elems_26 = toList(delay(() => map((purchaseOrderLineItem) => {
                            let elems_25, elems_22, elems_23, elems_24, _arg;
                            return createElement("tr", createObj(singleton((elems_25 = [createElement("td", {
                                className: "p-2",
                                children: purchaseOrderLineItem.BoxNumber,
                            }), createElement("td", createObj(ofArray([["className", "p-2"], (elems_22 = [createElement("a", {
                                className: join(" ", ["is-link", "is-underlined"]),
                                href: RouterModule_encodeParts(ofArray(["/packing-list-details", purchaseOrderLineItem.PackingListId]), 1),
                                children: purchaseOrderLineItem.PackingListNumber,
                            })], ["children", Interop_reactApi.Children.toArray(Array.from(elems_22))])]))), createElement("td", createObj(ofArray([["className", "p-2"], (elems_23 = [createElement("a", {
                                className: join(" ", ["is-link", "is-underlined"]),
                                href: RouterModule_encodeParts(ofArray(["/purchase-order-details", purchaseOrderLineItem.PurchaseOrderId]), 1),
                                children: purchaseOrderLineItem.PurchaseOrderNumber,
                            })], ["children", Interop_reactApi.Children.toArray(Array.from(elems_23))])]))), createElement("td", {
                                className: "p-2",
                                children: formatInt(purchaseOrderLineItem.PurchaseOrderLineItemNumber),
                            }), createElement("td", createObj(ofArray([["className", "p-2"], (elems_24 = [createElement("a", {
                                className: join(" ", ["is-link", "is-underlined"]),
                                href: RouterModule_encodeParts(ofArray(["/material-receipt-details", (_arg = (purchaseOrderLineItem.MaterialReceiptId != null), _arg ? value_353(purchaseOrderLineItem.MaterialReceiptId) : "")]), 1),
                                children: purchaseOrderLineItem.MaterialReceiptNumber,
                            })], ["children", Interop_reactApi.Children.toArray(Array.from(elems_24))])]))), createElement("td", {
                                className: "p-2",
                                children: formatInt(purchaseOrderLineItem.MaterialReceiptItem),
                            }), createElement("td", {
                                className: "p-2",
                                children: purchaseOrderLineItem.VendorName,
                            }), createElement("td", {
                                className: "p-2",
                                children: purchaseOrderLineItem.PartNumber,
                            }), createElement("td", {
                                className: "p-2",
                                children: purchaseOrderLineItem.Description,
                            }), createElement("td", {
                                className: "p-2",
                                children: formatDecimalWithUnits(purchaseOrderLineItem.QuantityReceivedUnit, purchaseOrderLineItem.QuantityReceived),
                            }), createElement("td", {
                                className: "p-2",
                                children: DateTimeOffset_formatNullable(purchaseOrderLineItem.PromiseDate),
                            })], ["children", Interop_reactApi.Children.toArray(Array.from(elems_25))]))));
                        }, sortBy((purchaseOrderLineItem_1) => purchaseOrderLineItem_1.PurchaseOrderLineItemNumber, purchaseOrderLineItems, {
                            Compare: comparePrimitives,
                        })))), ["children", Interop_reactApi.Children.toArray(Array.from(elems_26))])])))], ["children", Interop_reactApi.Children.toArray(Array.from(elems_27))])])))], ["children", Interop_reactApi.Children.toArray(Array.from(elems_28))])]))));
                    })), ["children", Interop_reactApi.Children.toArray(Array.from(elems_29))])])))));
                })), ["children", Interop_reactApi.Children.toArray(Array.from(elems_38))])])))], ["children", Interop_reactApi.Children.toArray(Array.from(elems_39))])])))));
            }));
        })) : singleton_1(defaultOf())) : singleton_1(defaultOf()));
    })), ["children", Interop_reactApi.Children.toArray(Array.from(elems_40))])])));
}

