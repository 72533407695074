import { ShipmentSearchItem, FinalDestination as FinalDestination_1, PortOfDischarge as PortOfDischarge_1, PortOfLading as PortOfLading_1, PlaceOfReceipt as PlaceOfReceipt_1, Shipment, FinalDestination_get_Default, PortOfDischarge_get_Default, PortOfLading_get_Default, PlaceOfReceipt_get_Default, ShipmentSearchQuery, OnTimePercentageQuery, ForecastQuery, SpendingOverTimeQuery, LanePairVolumeQuery, DashboardQuery } from "../../CView.DTOs/DTOs.fs.js";
import { defaultOf } from "../fable_modules/fable-library.4.10.0/Util.js";
import { minValue } from "../fable_modules/fable-library.4.10.0/DateOffset.js";
import { fromParts } from "../fable_modules/fable-library.4.10.0/Decimal.js";
import { System_String__String_get_NonEmpty } from "../Extensions.fs.js";
import { Union } from "../fable_modules/fable-library.4.10.0/Types.js";
import { union_type } from "../fable_modules/fable-library.4.10.0/Reflection.js";

export const defaultDashboardQuery = new DashboardQuery(new Array(0));

export const defaultLanePairVolumeQuery = new LanePairVolumeQuery(new Array(0), "Month", "");

export const defaultSpendOverTimeQuery = new SpendingOverTimeQuery(new Array(0), "Month", "");

export const defaultForecastQuery = new ForecastQuery(new Array(0), "", "CurrentWeek");

export const defaultOnTimePercentageQuery = new OnTimePercentageQuery(new Array(0), "Year");

export const defaultShipmentSearchQuery = new ShipmentSearchQuery("", "Any", "", "", "", "", "", "", "", "", "", new Array(0), false, false, "", "Any", "", "", defaultOf(), defaultOf(), 1, 10);

export const defaultShipment = (() => {
    const BookingDate = defaultOf();
    const StartDate = defaultOf();
    const DueDate = defaultOf();
    const EstimatedDeliveryDate = defaultOf();
    const DeliveredDate = defaultOf();
    const PortOfDischargeLatitude = defaultOf();
    const PortOfDischargeLongitude = defaultOf();
    const PortOfDischargeEstimatedTimeOfArrival = defaultOf();
    const PortOfLadingLatitude = defaultOf();
    const PortOfLadingLongitude = defaultOf();
    const PortOfLadingEstimatedTimeOfDeparture = defaultOf();
    const PlaceOfReceiptLatitude = defaultOf();
    const PlaceOfReceiptLongitude = defaultOf();
    const FinalDestinationLatitude = defaultOf();
    const FinalDestinationLongitude = defaultOf();
    const TotalWeight = defaultOf();
    const TotalChargeableWeight = defaultOf();
    const TotalPieces = defaultOf();
    const EmissionsTotal = defaultOf();
    const EmissionsWellToTank = defaultOf();
    const EmissionsTankToWheel = defaultOf();
    const EmissionsIntensity = defaultOf();
    const DateSynced = minValue();
    const PlaceOfReceipt = PlaceOfReceipt_get_Default();
    const PortOfLading = PortOfLading_get_Default();
    const PortOfDischarge = PortOfDischarge_get_Default();
    const FinalDestination = FinalDestination_get_Default();
    return new Shipment("00000000-0000-0000-0000-000000000000", "", "", new Array(0), "", new Array(0), "", new Array(0), "", "", "", "", "", "", "", BookingDate, StartDate, DueDate, EstimatedDeliveryDate, DeliveredDate, "", "", "", "", "", "", PortOfDischargeLatitude, PortOfDischargeLongitude, PortOfDischargeEstimatedTimeOfArrival, PortOfLadingLatitude, PortOfLadingLongitude, PortOfLadingEstimatedTimeOfDeparture, PlaceOfReceiptLatitude, PlaceOfReceiptLongitude, FinalDestinationLatitude, FinalDestinationLongitude, TotalWeight, "", TotalChargeableWeight, "", TotalPieces, "", "", "", "", "", "", "", "", "", "", fromParts(0, 0, 0, false, 0), new Array(0), new Array(0), new Array(0), new Array(0), new Array(0), new Array(0), new Array(0), new Array(0), new Array(0), EmissionsTotal, EmissionsWellToTank, EmissionsTankToWheel, EmissionsIntensity, DateSynced, PlaceOfReceipt, PortOfLading, PortOfDischarge, FinalDestination, false, false, "", new Array(0), new Array(0), false);
})();

export function constructLocation(cityName, stateOrProvinceCode, countryCode) {
    const matchValue = System_String__String_get_NonEmpty(cityName);
    const matchValue_1 = System_String__String_get_NonEmpty(stateOrProvinceCode);
    const matchValue_2 = System_String__String_get_NonEmpty(countryCode);
    let matchResult;
    if (matchValue) {
        if (matchValue_1) {
            if (matchValue_2) {
                matchResult = 0;
            }
            else {
                matchResult = 3;
            }
        }
        else if (matchValue_2) {
            matchResult = 1;
        }
        else {
            matchResult = 3;
        }
    }
    else if (matchValue_1) {
        matchResult = 3;
    }
    else if (matchValue_2) {
        matchResult = 2;
    }
    else {
        matchResult = 3;
    }
    switch (matchResult) {
        case 0:
            return `${cityName} ${stateOrProvinceCode}, ${countryCode}`;
        case 1:
            return `${cityName}, ${countryCode}`;
        case 2:
            return `${countryCode}`;
        default:
            return "";
    }
}

export function toLanePairPortOfLading(lanePair) {
    return constructLocation(lanePair.PortOfLadingCityName, lanePair.PortOfLadingStateOrProvinceCode, lanePair.PortOfLadingCountryCode);
}

export function toLanePairPortOfDischarge(lanePair) {
    return constructLocation(lanePair.PortOfDischargeCityName, lanePair.PortOfDischargeStateOrProvinceCode, lanePair.PortOfDischargeCountryCode);
}

export function toPortOfLadingFavorited(favorited) {
    return constructLocation(favorited.PortOfLadingCityName, favorited.PortOfLadingStateOrProvinceCode, favorited.PortOfLadingCountryCode);
}

export function toPortOfDischargeFavorited(favorited) {
    return constructLocation(favorited.PortOfDischargeCityName, favorited.PortOfDischargeStateOrProvinceCode, favorited.PortOfDischargeCountryCode);
}

export function toOriginFavorited(favorited) {
    return constructLocation(favorited.PlaceOfReceiptCityName, favorited.PlaceOfReceiptStateOrProvinceCode, favorited.PlaceOfReceiptCountryCode);
}

export function toDestinationFavorited(favorited) {
    return constructLocation(favorited.FinalDestinationCityName, favorited.FinalDestinationStateOrProvinceCode, favorited.FinalDestinationCountryCode);
}

export function toShipmentPlaceOfReceipt(shipment) {
    return new PlaceOfReceipt_1(shipment.PlaceOfReceipt.Location, shipment.PlaceOfReceipt.Icon, shipment.PlaceOfReceipt.Date, shipment.PlaceOfReceipt.DelayReason, shipment.PlaceOfReceipt.IsVisible, shipment.PlaceOfReceipt.StepClass, shipment.PlaceOfReceipt.IsActualDateAvailable);
}

export function toShipmentPortOfLading(shipment) {
    return new PortOfLading_1(shipment.PortOfLading.Location, shipment.PortOfLading.Icon, shipment.PortOfLading.Date, shipment.PortOfLading.DelayReason, shipment.PortOfLading.IsVisible, shipment.PortOfLading.StepClass, shipment.PortOfLading.IsActualDateAvailable);
}

export function toShipmentPortOfDischarge(shipment) {
    return new PortOfDischarge_1(shipment.PortOfDischarge.Location, shipment.PortOfDischarge.Icon, shipment.PortOfDischarge.Date, shipment.PortOfDischarge.DelayReason, shipment.PortOfDischarge.IsVisible, shipment.PortOfDischarge.StepClass, shipment.PortOfDischarge.IsActualDateAvailable);
}

export function toShipmentFinalDestination(shipment) {
    return new FinalDestination_1(shipment.FinalDestination.Location, shipment.FinalDestination.Icon, shipment.FinalDestination.Date, shipment.FinalDestination.DelayReason, shipment.FinalDestination.IsVisible, shipment.FinalDestination.StepClass, shipment.FinalDestination.IsActualDateAvailable);
}

export function toShipmentSearchItem(shipment) {
    return new ShipmentSearchItem(shipment.Id, shipment.CustomerName, shipment.ShipperName, shipment.ConsigneeName, shipment.BookingNumber, shipment.ShipmentNumber, shipment.ShipmentStatus, "", toShipmentPlaceOfReceipt(shipment), toShipmentPortOfLading(shipment), toShipmentPortOfDischarge(shipment), toShipmentFinalDestination(shipment), shipment.BookingDate, shipment.StartDate, shipment.DueDate, shipment.DeliveredDate, shipment.EstimatedDeliveryDate, shipment.ServiceType, shipment.ServiceLevel, shipment.TotalChargeableWeight, shipment.TotalChargeableWeightUnit, shipment.TotalPieces, shipment.TotalPiecesUnit, shipment.EmissionsTotal, shipment.ModeOfTransport, shipment.ModeOfTransportIcon, shipment.Delays.some((x) => !x.IsPotential), shipment.Delays.some((x_1) => x_1.IsPotential), shipment.IsPinned, shipment.LatestCompletedMilestoneName, shipment.HasVisibleSteps);
}

export class SelectableTabs extends Union {
    constructor(tag, fields) {
        super();
        this.tag = tag;
        this.fields = fields;
    }
    cases() {
        return ["Details", "References", "PurchaseOrderLineItems", "Containers", "TransitEvents", "Delays", "Documents", "Invoices", "CO2Emissions"];
    }
}

export function SelectableTabs_$reflection() {
    return union_type("CView.UI.ShipmentDomain.SelectableTabs", [], SelectableTabs, () => [[], [], [], [], [], [], [], [], []]);
}

