import { Union, Record } from "../fable_modules/fable-library.4.10.0/Types.js";
import { union_type, record_type, class_type, unit_type, string_type } from "../fable_modules/fable-library.4.10.0/Reflection.js";
import { exists, add, tryFind, remove, empty } from "../fable_modules/fable-library.4.10.0/Map.js";
import { createObj, comparePrimitives } from "../fable_modules/fable-library.4.10.0/Util.js";
import { Cmd_none } from "../fable_modules/Fable.Elmish.4.0.2/cmd.fs.js";
import { map, defaultArgWith } from "../fable_modules/fable-library.4.10.0/Option.js";
import { createElement } from "react";
import { map as map_1, empty as empty_1, append, collect, singleton, delay, toList } from "../fable_modules/fable-library.4.10.0/Seq.js";
import { join } from "../fable_modules/fable-library.4.10.0/String.js";
import { Interop_reactApi } from "../fable_modules/Feliz.2.6.0/./Interop.fs.js";
import { singleton as singleton_1, ofArray } from "../fable_modules/fable-library.4.10.0/List.js";
import { DateTimeOffset_format, DateTimeOffset_formatNullable } from "../Extensions.fs.js";

export class State extends Record {
    constructor(SelectedMilestones, SelectedContainers) {
        super();
        this.SelectedMilestones = SelectedMilestones;
        this.SelectedContainers = SelectedContainers;
    }
}

export function State_$reflection() {
    return record_type("CView.UI.ShipmentTransitEvent.ShipmentTransitEvent.State", [], State, () => [["SelectedMilestones", class_type("Microsoft.FSharp.Collections.FSharpMap`2", [string_type, unit_type])], ["SelectedContainers", class_type("Microsoft.FSharp.Collections.FSharpMap`2", [string_type, unit_type])]]);
}

export class Msg extends Union {
    constructor(tag, fields) {
        super();
        this.tag = tag;
        this.fields = fields;
    }
    cases() {
        return ["SetSelectedMilestone", "SetSelectedContainer"];
    }
}

export function Msg_$reflection() {
    return union_type("CView.UI.ShipmentTransitEvent.ShipmentTransitEvent.Msg", [], Msg, () => [[["Item", string_type]], [["Item", string_type]]]);
}

export function init() {
    return [new State(empty({
        Compare: comparePrimitives,
    }), empty({
        Compare: comparePrimitives,
    })), Cmd_none()];
}

export function update(msg, state) {
    if (msg.tag === 1) {
        const selectedContainer = msg.fields[0];
        return [new State(state.SelectedMilestones, defaultArgWith(map(() => remove(selectedContainer, state.SelectedContainers), tryFind(selectedContainer, state.SelectedContainers)), () => add(selectedContainer, void 0, state.SelectedContainers))), Cmd_none()];
    }
    else {
        const selectedMilestone = msg.fields[0];
        return [new State(defaultArgWith(map(() => remove(selectedMilestone, state.SelectedMilestones), tryFind(selectedMilestone, state.SelectedMilestones)), () => add(selectedMilestone, void 0, state.SelectedMilestones)), state.SelectedContainers), Cmd_none()];
    }
}

export function render(state, dispatch, shipment) {
    let elems_18;
    return createElement("div", createObj(singleton_1((elems_18 = toList(delay(() => (shipment.Milestones.every((x) => (x.CarrierEvents.length === 0)) ? singleton(createElement("div", {
        className: join(" ", ["mt-4", "has-text-centered"]),
        children: "No transit events were found for this shipment",
    })) : collect((milestone) => {
        let elems_17;
        const isActualDateAvailable = milestone.MilestoneActualDate != null;
        const isMilestoneSelected = exists((key_2, _arg) => (key_2 === milestone.MilestoneName), state.SelectedMilestones);
        const containers = shipment.Containers.filter((container) => milestone.CarrierEvents.some((carrierEvent) => (carrierEvent.ContainerNumber === container.Number)));
        const carrierEvents = milestone.CarrierEvents.filter((carrierEvent_1) => !shipment.Containers.some((container_1) => (container_1.Number === carrierEvent_1.ContainerNumber)));
        const hasCarrierEvents = milestone.CarrierEvents.length > 0;
        return singleton(createElement("div", createObj(ofArray([["className", join(" ", ["milestone-panel", "is-bordered", "p-1"])], (elems_17 = toList(delay(() => {
            let elems_4, elems_3, elems, elems_2, elems_1, children;
            return append(singleton(createElement("div", createObj(ofArray([["className", join(" ", ["is-flex", "is-flex-direction-row", "is-flex-wrap-wrap"])], (elems_4 = [createElement("div", createObj(ofArray([["className", join(" ", ["is-flex", "is-align-items-center"])], (elems_3 = [createElement("span", {
                className: join(" ", toList(delay(() => append(singleton("material-icons"), delay(() => append(singleton("is-panel-expanded"), delay(() => (!hasCarrierEvents ? singleton("has-text-light") : singleton("is-clickable"))))))))),
                style: {
                    fontSize: 30 + "px",
                },
                children: isMilestoneSelected ? "expand_less" : "expand_more",
                onClick: (_arg_1) => {
                    if (hasCarrierEvents) {
                        dispatch(new Msg(0, [milestone.MilestoneName]));
                    }
                },
            }), createElement("span", createObj(ofArray([["className", join(" ", ["is-flex", "is-justify-content-center", "is-align-items-center"])], ["style", createObj(toList(delay(() => append(singleton(["borderRadius", 50 + "%"]), delay(() => append(singleton(["width", 1.5 + "rem"]), delay(() => append(singleton(["height", 1.5 + "rem"]), delay(() => (isActualDateAvailable ? singleton(["backgroundColor", "#48c78e"]) : singleton(["backgroundColor", "#dbdbdb"])))))))))))], (elems = toList(delay(() => (isActualDateAvailable ? singleton(createElement("i", {
                className: join(" ", toList(delay(() => append(singleton("material-icons"), delay(() => append(singleton("mot-icon"), delay(() => (isActualDateAvailable ? singleton("has-text-white-ter") : empty_1())))))))),
                style: {
                    fontSize: 15 + "px",
                },
                children: "check",
            })) : empty_1()))), ["children", Interop_reactApi.Children.toArray(Array.from(elems))])]))), createElement("div", createObj(ofArray([["className", join(" ", ["is-flex", "is-flex-direction-column", "p-2"])], (elems_2 = [createElement("div", {
                className: join(" ", ["has-text-weight-semibold", "is-size-7"]),
                children: milestone.MilestoneName,
            }), createElement("div", createObj(ofArray([["className", join(" ", ["is-size-7", "is-flex", "is-flex-wrap-wrap", "is-justify-content-space-between"])], (elems_1 = [(children = ofArray([createElement("span", {
                className: join(" ", ["has-text-weight-semibold", "mr-2"]),
                children: isActualDateAvailable ? "Actual" : "Estimated",
            }), isActualDateAvailable ? DateTimeOffset_formatNullable(milestone.MilestoneActualDate) : DateTimeOffset_formatNullable(milestone.MilestoneEstimatedDate)]), createElement("div", {
                children: Interop_reactApi.Children.toArray(Array.from(children)),
            }))], ["children", Interop_reactApi.Children.toArray(Array.from(elems_1))])])))], ["children", Interop_reactApi.Children.toArray(Array.from(elems_2))])])))], ["children", Interop_reactApi.Children.toArray(Array.from(elems_3))])])))], ["children", Interop_reactApi.Children.toArray(Array.from(elems_4))])])))), delay(() => append(collect((container_2) => {
                let elems_13, elems_12, elems_7, elems_6, elems_5, elems_11, elems_10, elems_9, children_4, children_2, elems_8;
                const carrierEvents_1 = milestone.CarrierEvents.filter((carrierEvent_2) => (carrierEvent_2.ContainerNumber === container_2.Number));
                const isContainerSelected = exists((key_33, _arg_2) => (key_33 === container_2.Number), state.SelectedContainers);
                const hasCarrierEvents_1 = carrierEvents_1.length > 0;
                return singleton(createElement("div", createObj(ofArray([["className", join(" ", toList(delay(() => append(singleton("my-2"), delay(() => append(singleton("mx-6"), delay(() => (!isMilestoneSelected ? singleton("is-hidden") : empty_1()))))))))], ["id", "expansion_panel_0"], ["style", {
                    display: "block",
                }], (elems_13 = [createElement("div", createObj(ofArray([["className", "my-2"], (elems_12 = [createElement("div", createObj(ofArray([["className", join(" ", ["is-flex", "is-flex-direction-row", "is-flex-wrap-wrap"])], (elems_7 = [createElement("div", createObj(ofArray([["className", join(" ", ["is-flex", "is-align-items-center"])], (elems_6 = [createElement("span", {
                    className: join(" ", toList(delay(() => append(singleton("material-icons"), delay(() => append(singleton("is-clickable"), delay(() => append(singleton("is-panel-expanded"), delay(() => (!hasCarrierEvents_1 ? singleton("has-text-light") : singleton("is-clickable"))))))))))),
                    onClick: (_arg_3) => {
                        if (carrierEvents_1.length > 0) {
                            dispatch(new Msg(1, [container_2.Number]));
                        }
                    },
                    style: {
                        fontSize: 20 + "px",
                    },
                    children: isContainerSelected ? "expand_less" : "expand_more",
                }), createElement("div", createObj(ofArray([["className", join(" ", ["is-flex", "is-flex-direction-row", "is-flex-wrap-wrap"])], (elems_5 = [createElement("span", {
                    className: "has-text-weight-semibold",
                    children: container_2.Number,
                })], ["children", Interop_reactApi.Children.toArray(Array.from(elems_5))])])))], ["children", Interop_reactApi.Children.toArray(Array.from(elems_6))])])))], ["children", Interop_reactApi.Children.toArray(Array.from(elems_7))])]))), createElement("div", createObj(ofArray([["className", join(" ", toList(delay(() => append(singleton("mx-4"), delay(() => (isContainerSelected ? singleton("mt-4") : singleton("is-hidden")))))))], ["id", "expansion_panel_0_1"], ["style", {
                    display: "block",
                }], (elems_11 = [createElement("div", createObj(ofArray([["className", join(" ", ["table-container", "table-has-fixed-header"])], ["style", {
                    overflowY: "auto",
                }], (elems_10 = [createElement("table", createObj(ofArray([["className", join(" ", ["table", "is-narrow", "is-hoverable", "is-fullwidth"])], (elems_9 = [(children_4 = singleton_1((children_2 = ofArray([createElement("th", {
                    className: join(" ", ["is-size-7", "p-2"]),
                    scope: "col",
                    children: "Code",
                }), createElement("th", {
                    className: join(" ", ["is-size-7", "p-2"]),
                    scope: "col",
                    children: "Event",
                }), createElement("th", {
                    className: join(" ", ["is-size-7", "p-2"]),
                    scope: "col",
                    children: "Date",
                }), createElement("th", {
                    className: join(" ", ["is-size-7", "p-2"]),
                    scope: "col",
                    children: "Provided By",
                }), createElement("th", {
                    className: join(" ", ["is-size-7", "p-2"]),
                    scope: "col",
                    children: "Location",
                })]), createElement("tr", {
                    children: Interop_reactApi.Children.toArray(Array.from(children_2)),
                }))), createElement("thead", {
                    children: Interop_reactApi.Children.toArray(Array.from(children_4)),
                })), createElement("tbody", createObj(singleton_1((elems_8 = toList(delay(() => map_1((carrierEvent_3) => {
                    const children_6 = ofArray([createElement("td", {
                        className: join(" ", ["is-size-7", "p-2"]),
                        children: carrierEvent_3.EventCode,
                    }), createElement("td", {
                        className: join(" ", ["is-size-7", "p-2"]),
                        children: carrierEvent_3.EventName,
                    }), createElement("td", {
                        className: join(" ", ["is-size-7", "p-2"]),
                        children: DateTimeOffset_format(carrierEvent_3.EventDate),
                    }), createElement("td", {
                        className: join(" ", ["is-size-7", "p-2"]),
                        children: carrierEvent_3.PortName,
                    }), createElement("td", {
                        className: join(" ", ["is-size-7", "p-2"]),
                        children: carrierEvent_3.Location,
                    })]);
                    return createElement("tr", {
                        children: Interop_reactApi.Children.toArray(Array.from(children_6)),
                    });
                }, carrierEvents_1))), ["children", Interop_reactApi.Children.toArray(Array.from(elems_8))]))))], ["children", Interop_reactApi.Children.toArray(Array.from(elems_9))])])))], ["children", Interop_reactApi.Children.toArray(Array.from(elems_10))])])))], ["children", Interop_reactApi.Children.toArray(Array.from(elems_11))])])))], ["children", Interop_reactApi.Children.toArray(Array.from(elems_12))])])))], ["children", Interop_reactApi.Children.toArray(Array.from(elems_13))])]))));
            }, containers), delay(() => {
                let elems_16, elems_15, elems_14;
                return (carrierEvents.length > 0) ? singleton(createElement("div", createObj(ofArray([["className", join(" ", toList(delay(() => append(singleton("mx-6"), delay(() => (!isMilestoneSelected ? singleton("is-hidden") : empty_1()))))))], ["id", "expansion_panel_0"], ["style", {
                    display: "block",
                }], (elems_16 = [createElement("div", createObj(ofArray([["className", join(" ", ["table-container", "table-has-fixed-header"])], ["style", {
                    overflowY: "auto",
                }], (elems_15 = [createElement("table", createObj(ofArray([["className", join(" ", ["table", "is-narrow", "is-hoverable", "is-fullwidth"])], (elems_14 = toList(delay(() => {
                    let children_10, children_8;
                    return append(singleton((children_10 = singleton_1((children_8 = ofArray([createElement("th", {
                        className: join(" ", ["is-size-7", "p-2"]),
                        scope: "col",
                        children: "Code",
                    }), createElement("th", {
                        className: join(" ", ["is-size-7", "p-2"]),
                        scope: "col",
                        children: "Event",
                    }), createElement("th", {
                        className: join(" ", ["is-size-7", "p-2"]),
                        scope: "col",
                        children: "Date",
                    }), createElement("th", {
                        className: join(" ", ["is-size-7", "p-2"]),
                        scope: "col",
                        children: "Provided By",
                    }), createElement("th", {
                        className: join(" ", ["is-size-7", "p-2"]),
                        scope: "col",
                        children: "Location",
                    })]), createElement("tr", {
                        children: Interop_reactApi.Children.toArray(Array.from(children_8)),
                    }))), createElement("thead", {
                        children: Interop_reactApi.Children.toArray(Array.from(children_10)),
                    }))), delay(() => map_1((carrierEvent_4) => {
                        let children_12;
                        const children_14 = singleton_1((children_12 = ofArray([createElement("td", {
                            className: join(" ", ["is-size-7", "p-2"]),
                            children: carrierEvent_4.EventCode,
                        }), createElement("td", {
                            className: join(" ", ["is-size-7", "p-2"]),
                            children: carrierEvent_4.EventName,
                        }), createElement("td", {
                            className: join(" ", ["is-size-7", "p-2"]),
                            children: DateTimeOffset_format(carrierEvent_4.EventDate),
                        }), createElement("td", {
                            className: join(" ", ["is-size-7", "p-2"]),
                            children: carrierEvent_4.PortName,
                        }), createElement("td", {
                            className: join(" ", ["is-size-7", "p-2"]),
                            children: carrierEvent_4.Location,
                        })]), createElement("tr", {
                            children: Interop_reactApi.Children.toArray(Array.from(children_12)),
                        })));
                        return createElement("tbody", {
                            children: Interop_reactApi.Children.toArray(Array.from(children_14)),
                        });
                    }, milestone.CarrierEvents)));
                })), ["children", Interop_reactApi.Children.toArray(Array.from(elems_14))])])))], ["children", Interop_reactApi.Children.toArray(Array.from(elems_15))])])))], ["children", Interop_reactApi.Children.toArray(Array.from(elems_16))])])))) : empty_1();
            }))));
        })), ["children", Interop_reactApi.Children.toArray(Array.from(elems_17))])]))));
    }, shipment.Milestones)))), ["children", Interop_reactApi.Children.toArray(Array.from(elems_18))]))));
}

