import { Union, Record } from "../fable_modules/fable-library.4.10.0/Types.js";
import { unit_type, lambda_type, record_type, bool_type, array_type, union_type, string_type, option_type, class_type } from "../fable_modules/fable-library.4.10.0/Reflection.js";
import { ClientUser, UserAccount, UserAccount_$reflection, GlobalAccountModules_$reflection, GlobalAccount_$reflection, ClientUser_$reflection } from "../../CView.DTOs/DTOs.fs.js";
import { FSharpResult$2 } from "../fable_modules/fable-library.4.10.0/Choice.js";
import { Deferred_map, AsyncMsg$1, Deferred$1, AsyncMsg$1_$reflection, Deferred$1_$reflection } from "../Extensions.fs.js";
import { defaultClientUser, updatePermissions, isNewClientUser } from "../Domain/User.fs.js";
import { UserTypeId_ClientAdministrator } from "../../CView.DTOs/Constants.fs.js";
import { Cmd_none, Cmd_batch } from "../fable_modules/Fable.Elmish.4.0.2/cmd.fs.js";
import { ofArray, singleton } from "../fable_modules/fable-library.4.10.0/List.js";
import { Alert_question, Toast_text, Toast_title, Toast_success, Alert_text, Alert_title, Alert_error } from "../Components/SweetAlert.fs.js";
import { comparePrimitives, createObj, equals } from "../fable_modules/fable-library.4.10.0/Util.js";
import { Cmd_OfAsyncWith_perform } from "../fable_modules/Fable.Elmish.4.0.2/./cmd.fs.js";
import { startImmediate } from "../fable_modules/fable-library.4.10.0/Async.js";
import { getGlobalAccountModules, getGlobalAccount } from "../Api/AccountApi.fs.js";
import { sortBy, append as append_1, collect, map } from "../fable_modules/fable-library.4.10.0/Array.js";
import { defaultArg } from "../fable_modules/fable-library.4.10.0/Option.js";
import { Cmd_ofEffect } from "../fable_modules/Feliz.Router.4.0.0/../Fable.Elmish.4.0.2/cmd.fs.js";
import { RouterModule_nav } from "../fable_modules/Feliz.Router.4.0.0/./Router.fs.js";
import { findClientUser, deleteClientUser as deleteClientUser_1, updateClientUser as updateClientUser_1, createClientUser as createClientUser_1 } from "../Api/UserApi.fs.js";
import { singleton as singleton_1 } from "../fable_modules/fable-library.4.10.0/AsyncBuilder.js";
import { createElement } from "react";
import { join } from "../fable_modules/fable-library.4.10.0/String.js";
import { map as map_1, collect as collect_1, empty, append, singleton as singleton_2, delay, toList } from "../fable_modules/fable-library.4.10.0/Seq.js";
import { render as render_1 } from "../Components/Loader.fs.js";
import { Interop_reactApi } from "../fable_modules/Feliz.2.6.0/./Interop.fs.js";
import { defaultOf } from "../fable_modules/Feliz.2.6.0/../fable-library.4.10.0/Util.js";

export class State extends Record {
    constructor(ClientUserId, UserTypeId, GlobalAccountId, User, GlobalAccount, GlobalAccountModules, SelectedAccounts, UserName, UserEmail, ChangesMade) {
        super();
        this.ClientUserId = ClientUserId;
        this.UserTypeId = UserTypeId;
        this.GlobalAccountId = GlobalAccountId;
        this.User = User;
        this.GlobalAccount = GlobalAccount;
        this.GlobalAccountModules = GlobalAccountModules;
        this.SelectedAccounts = SelectedAccounts;
        this.UserName = UserName;
        this.UserEmail = UserEmail;
        this.ChangesMade = ChangesMade;
    }
}

export function State_$reflection() {
    return record_type("CView.UI.Pages.ClientUser.State", [], State, () => [["ClientUserId", option_type(class_type("System.Guid"))], ["UserTypeId", class_type("System.Guid")], ["GlobalAccountId", class_type("System.Guid")], ["User", Deferred$1_$reflection(union_type("Microsoft.FSharp.Core.FSharpResult`2", [ClientUser_$reflection(), string_type], FSharpResult$2, () => [[["ResultValue", ClientUser_$reflection()]], [["ErrorValue", string_type]]]))], ["GlobalAccount", Deferred$1_$reflection(union_type("Microsoft.FSharp.Core.FSharpResult`2", [GlobalAccount_$reflection(), string_type], FSharpResult$2, () => [[["ResultValue", GlobalAccount_$reflection()]], [["ErrorValue", string_type]]]))], ["GlobalAccountModules", Deferred$1_$reflection(union_type("Microsoft.FSharp.Core.FSharpResult`2", [GlobalAccountModules_$reflection(), string_type], FSharpResult$2, () => [[["ResultValue", GlobalAccountModules_$reflection()]], [["ErrorValue", string_type]]]))], ["SelectedAccounts", array_type(UserAccount_$reflection())], ["UserName", string_type], ["UserEmail", string_type], ["ChangesMade", bool_type]]);
}

export class Msg extends Union {
    constructor(tag, fields) {
        super();
        this.tag = tag;
        this.fields = fields;
    }
    cases() {
        return ["GetClientUser", "GetGlobalAccount", "GetGlobalAccountModules", "SetSelectedAccounts", "UpdateClientUserModel", "CreateClientUser", "UpdateClientUser", "DeleteClientUser", "ShowDeleteUserConfirmation", "SetEditUserName", "SetEditUserEmail"];
    }
}

export function Msg_$reflection() {
    return union_type("CView.UI.Pages.ClientUser.Msg", [], Msg, () => [[["Item", AsyncMsg$1_$reflection(union_type("Microsoft.FSharp.Core.FSharpResult`2", [ClientUser_$reflection(), string_type], FSharpResult$2, () => [[["ResultValue", ClientUser_$reflection()]], [["ErrorValue", string_type]]]))]], [["Item", AsyncMsg$1_$reflection(union_type("Microsoft.FSharp.Core.FSharpResult`2", [GlobalAccount_$reflection(), string_type], FSharpResult$2, () => [[["ResultValue", GlobalAccount_$reflection()]], [["ErrorValue", string_type]]]))]], [["Item", AsyncMsg$1_$reflection(union_type("Microsoft.FSharp.Core.FSharpResult`2", [GlobalAccountModules_$reflection(), string_type], FSharpResult$2, () => [[["ResultValue", GlobalAccountModules_$reflection()]], [["ErrorValue", string_type]]]))]], [["Item1", bool_type], ["Item2", array_type(UserAccount_$reflection())]], [["Item1", bool_type], ["Item2", lambda_type(ClientUser_$reflection(), ClientUser_$reflection())]], [["Item", AsyncMsg$1_$reflection(union_type("Microsoft.FSharp.Core.FSharpResult`2", [class_type("System.Guid"), string_type], FSharpResult$2, () => [[["ResultValue", class_type("System.Guid")]], [["ErrorValue", string_type]]]))]], [["Item", AsyncMsg$1_$reflection(union_type("Microsoft.FSharp.Core.FSharpResult`2", [unit_type, string_type], FSharpResult$2, () => [[["ResultValue", unit_type]], [["ErrorValue", string_type]]]))]], [["Item", AsyncMsg$1_$reflection(union_type("Microsoft.FSharp.Core.FSharpResult`2", [unit_type, string_type], FSharpResult$2, () => [[["ResultValue", unit_type]], [["ErrorValue", string_type]]]))]], [], [["Item", string_type]], [["Item", string_type]]]);
}

function isInactiveUser(user) {
    if (!isNewClientUser(user)) {
        return !user.IsActive;
    }
    else {
        return false;
    }
}

function isReadOnlyUser(state, user) {
    if (isInactiveUser(user)) {
        return true;
    }
    else {
        return !(state.UserTypeId === UserTypeId_ClientAdministrator);
    }
}

function isUserAuthorizedToEdit(state) {
    return state.UserTypeId === UserTypeId_ClientAdministrator;
}

export function init(clientUserId, userTypeId, globalAccountId) {
    return [new State(clientUserId, userTypeId, globalAccountId, new Deferred$1(0, []), new Deferred$1(0, []), new Deferred$1(0, []), new Array(0), "", "", false), Cmd_batch(ofArray([singleton((dispatch) => {
        dispatch(new Msg(1, [new AsyncMsg$1(0, [])]));
    }), singleton((dispatch_1) => {
        dispatch_1(new Msg(2, [new AsyncMsg$1(0, [])]));
    })]))];
}

export function update(msg, state) {
    let matchValue_2, xs, matchValue_1;
    switch (msg.tag) {
        case 1:
            if (msg.fields[0].tag === 1) {
                return [new State(state.ClientUserId, state.UserTypeId, state.GlobalAccountId, state.User, new Deferred$1(2, [msg.fields[0].fields[0]]), state.GlobalAccountModules, state.SelectedAccounts, state.UserName, state.UserEmail, state.ChangesMade), (msg.fields[0].fields[0].tag === 1) ? Alert_error(Alert_title("Global Account", Alert_text(msg.fields[0].fields[0].fields[0]))) : Cmd_none()];
            }
            else if (equals(state.GlobalAccount, new Deferred$1(1, []))) {
                return [state, Cmd_none()];
            }
            else {
                return [new State(state.ClientUserId, state.UserTypeId, state.GlobalAccountId, state.User, new Deferred$1(1, []), state.GlobalAccountModules, state.SelectedAccounts, state.UserName, state.UserEmail, state.ChangesMade), Cmd_OfAsyncWith_perform((computation_1) => {
                    startImmediate(computation_1);
                }, getGlobalAccount, state.GlobalAccountId, (arg_2) => (new Msg(1, [new AsyncMsg$1(1, [arg_2])])))];
            }
        case 3:
            return [new State(state.ClientUserId, state.UserTypeId, state.GlobalAccountId, state.User, state.GlobalAccount, state.GlobalAccountModules, msg.fields[1], state.UserName, state.UserEmail, state.ChangesMade), singleton((dispatch_1) => {
                dispatch_1(new Msg(4, [msg.fields[0], (user_2) => (new ClientUser(user_2.Id, user_2.UserTypeId, user_2.Email, user_2.DisplayName, user_2.IsActive, user_2.CanViewAirShipments, user_2.CanViewOceanShipments, user_2.CanViewGroundShipments, user_2.CanCreateBookings, user_2.CanViewBookings, user_2.CanExportSearchResults, user_2.CanViewInventory, user_2.CanViewAnalytics, user_2.CanViewRailShipments, user_2.CanViewCO2Emissions, user_2.CanViewBilling, map((account) => (new UserAccount(defaultArg(state.ClientUserId, "00000000-0000-0000-0000-000000000000"), account.AccountId, account.AccountName)), msg.fields[1])))]));
            })];
        case 4:
            return [new State(state.ClientUserId, state.UserTypeId, state.GlobalAccountId, Deferred_map((input) => {
                const input_1 = input;
                if (input_1.tag === 1) {
                    return new FSharpResult$2(1, [input_1.fields[0]]);
                }
                else {
                    return new FSharpResult$2(0, [msg.fields[1](input_1.fields[0])]);
                }
            }, state.User), state.GlobalAccount, state.GlobalAccountModules, state.SelectedAccounts, state.UserName, state.UserEmail, msg.fields[0]), Cmd_none()];
        case 2:
            if (msg.fields[0].tag === 1) {
                const command_5 = (msg.fields[0].fields[0].tag === 1) ? Alert_error(Alert_title("Global Account Modules", Alert_text(msg.fields[0].fields[0].fields[0]))) : singleton((dispatch_2) => {
                    dispatch_2(new Msg(0, [new AsyncMsg$1(0, [])]));
                });
                return [new State(state.ClientUserId, state.UserTypeId, state.GlobalAccountId, (msg.fields[0].fields[0].tag === 0) ? ((matchValue_2 = state.User, (matchValue_2.tag === 2) ? ((matchValue_2.fields[0].tag === 0) ? (new Deferred$1(2, [new FSharpResult$2(0, [updatePermissions(matchValue_2.fields[0].fields[0], msg.fields[0].fields[0].fields[0])])])) : state.User) : state.User)) : state.User, state.GlobalAccount, new Deferred$1(2, [msg.fields[0].fields[0]]), state.SelectedAccounts, state.UserName, state.UserEmail, state.ChangesMade), command_5];
            }
            else if (equals(state.GlobalAccountModules, new Deferred$1(1, []))) {
                return [state, Cmd_none()];
            }
            else {
                return [new State(state.ClientUserId, state.UserTypeId, state.GlobalAccountId, state.User, state.GlobalAccount, new Deferred$1(1, []), state.SelectedAccounts, state.UserName, state.UserEmail, state.ChangesMade), Cmd_OfAsyncWith_perform((computation_2) => {
                    startImmediate(computation_2);
                }, getGlobalAccountModules, state.GlobalAccountId, (arg_4) => (new Msg(2, [new AsyncMsg$1(1, [arg_4])])))];
            }
        case 5:
            if (msg.fields[0].tag === 1) {
                let patternInput_1;
                if (msg.fields[0].fields[0].tag === 1) {
                    patternInput_1 = [Alert_error(Alert_title("Create User", Alert_text(msg.fields[0].fields[0].fields[0]))), state];
                }
                else {
                    const clientUserId = msg.fields[0].fields[0].fields[0];
                    patternInput_1 = [Cmd_batch(ofArray([Toast_success(Toast_title("Create User", Toast_text("The user has been created"))), (xs = ["user-details", clientUserId], Cmd_ofEffect((_arg) => {
                        RouterModule_nav(ofArray(xs), 1, 1);
                    }))])), new State(clientUserId, state.UserTypeId, state.GlobalAccountId, state.User, state.GlobalAccount, state.GlobalAccountModules, state.SelectedAccounts, state.UserName, state.UserEmail, false)];
                }
                return [patternInput_1[1], patternInput_1[0]];
            }
            else {
                const matchValue_3 = state.User;
                let matchResult, user_4;
                if (matchValue_3.tag === 2) {
                    if (matchValue_3.fields[0].tag === 0) {
                        matchResult = 0;
                        user_4 = matchValue_3.fields[0].fields[0];
                    }
                    else {
                        matchResult = 1;
                    }
                }
                else {
                    matchResult = 1;
                }
                switch (matchResult) {
                    case 0:
                        return [state, Cmd_OfAsyncWith_perform((computation_3) => {
                            startImmediate(computation_3);
                        }, () => createClientUser_1(state.GlobalAccountId, user_4), void 0, (arg_6) => (new Msg(5, [new AsyncMsg$1(1, [arg_6])])))];
                    default:
                        return [state, Cmd_none()];
                }
            }
        case 6:
            if (msg.fields[0].tag === 1) {
                return [new State(state.ClientUserId, state.UserTypeId, state.GlobalAccountId, state.User, state.GlobalAccount, state.GlobalAccountModules, state.SelectedAccounts, state.UserName, state.UserEmail, false), (msg.fields[0].fields[0].tag === 1) ? Alert_error(Alert_title("Update User", Alert_text(msg.fields[0].fields[0].fields[0]))) : Cmd_batch(ofArray([Toast_success(Toast_title("Update User", Toast_text("The user has been updated"))), singleton((dispatch_3) => {
                    dispatch_3(new Msg(0, [new AsyncMsg$1(0, [])]));
                })]))];
            }
            else {
                const matchValue_4 = state.User;
                let matchResult_1, user_5;
                if (matchValue_4.tag === 2) {
                    if (matchValue_4.fields[0].tag === 0) {
                        matchResult_1 = 0;
                        user_5 = matchValue_4.fields[0].fields[0];
                    }
                    else {
                        matchResult_1 = 1;
                    }
                }
                else {
                    matchResult_1 = 1;
                }
                switch (matchResult_1) {
                    case 0: {
                        const user_6 = new ClientUser(user_5.Id, user_5.UserTypeId, state.UserEmail, state.UserName, user_5.IsActive, user_5.CanViewAirShipments, user_5.CanViewOceanShipments, user_5.CanViewGroundShipments, user_5.CanCreateBookings, user_5.CanViewBookings, user_5.CanExportSearchResults, user_5.CanViewInventory, user_5.CanViewAnalytics, user_5.CanViewRailShipments, user_5.CanViewCO2Emissions, user_5.CanViewBilling, user_5.Accounts);
                        return [state, Cmd_OfAsyncWith_perform((computation_4) => {
                            startImmediate(computation_4);
                        }, () => updateClientUser_1(state.GlobalAccountId, user_6), void 0, (arg_8) => (new Msg(6, [new AsyncMsg$1(1, [arg_8])])))];
                    }
                    default:
                        return [state, Cmd_none()];
                }
            }
        case 7:
            if (msg.fields[0].tag === 1) {
                return [state, (msg.fields[0].fields[0].tag === 1) ? Alert_error(Alert_title("Delete User", Alert_text(msg.fields[0].fields[0].fields[0]))) : Cmd_batch(ofArray([Toast_success(Toast_title("Delete User", Toast_text("The user has been deleted"))), Cmd_ofEffect((_arg_1) => {
                    RouterModule_nav(singleton("users"), 1, 1);
                })]))];
            }
            else {
                const matchValue_5 = state.User;
                let matchResult_2, user_7;
                if (matchValue_5.tag === 2) {
                    if (matchValue_5.fields[0].tag === 0) {
                        matchResult_2 = 0;
                        user_7 = matchValue_5.fields[0].fields[0];
                    }
                    else {
                        matchResult_2 = 1;
                    }
                }
                else {
                    matchResult_2 = 1;
                }
                switch (matchResult_2) {
                    case 0:
                        return [state, Cmd_OfAsyncWith_perform((computation_5) => {
                            startImmediate(computation_5);
                        }, () => deleteClientUser_1(state.GlobalAccountId, user_7.Id), void 0, (arg_10) => (new Msg(7, [new AsyncMsg$1(1, [arg_10])])))];
                    default:
                        return [state, Cmd_none()];
                }
            }
        case 8:
            return [state, Alert_question(new Msg(7, [new AsyncMsg$1(0, [])]), Alert_title("Delete User", Alert_text("Are you sure you want to delete the user?")))];
        case 9:
            return [new State(state.ClientUserId, state.UserTypeId, state.GlobalAccountId, state.User, state.GlobalAccount, state.GlobalAccountModules, state.SelectedAccounts, msg.fields[0], state.UserEmail, true), Cmd_none()];
        case 10:
            return [new State(state.ClientUserId, state.UserTypeId, state.GlobalAccountId, state.User, state.GlobalAccount, state.GlobalAccountModules, state.SelectedAccounts, state.UserName, msg.fields[0], true), Cmd_none()];
        default:
            if (msg.fields[0].tag === 1) {
                let patternInput;
                if (msg.fields[0].fields[0].tag === 1) {
                    patternInput = [Alert_error(Alert_title("Get User", Alert_text(msg.fields[0].fields[0].fields[0]))), "", ""];
                }
                else {
                    const user = msg.fields[0].fields[0].fields[0];
                    patternInput = ((user.Id === "00000000-0000-0000-0000-000000000000") ? [Cmd_none(), "", ""] : [singleton((dispatch) => {
                        dispatch(new Msg(3, [false, user.Accounts]));
                    }), user.DisplayName, user.Email]);
                }
                return [new State(state.ClientUserId, state.UserTypeId, state.GlobalAccountId, (msg.fields[0].fields[0].tag === 0) ? ((matchValue_1 = state.GlobalAccountModules, (matchValue_1.tag === 2) ? ((matchValue_1.fields[0].tag === 0) ? (new Deferred$1(2, [new FSharpResult$2(0, [updatePermissions(msg.fields[0].fields[0].fields[0], matchValue_1.fields[0].fields[0])])])) : state.User) : state.User)) : state.User, state.GlobalAccount, state.GlobalAccountModules, state.SelectedAccounts, patternInput[1], patternInput[2], state.ChangesMade), patternInput[0]];
            }
            else if (equals(state.User, new Deferred$1(1, []))) {
                return [state, Cmd_none()];
            }
            else {
                return [new State(state.ClientUserId, state.UserTypeId, state.GlobalAccountId, new Deferred$1(1, []), state.GlobalAccount, state.GlobalAccountModules, state.SelectedAccounts, state.UserName, state.UserEmail, state.ChangesMade), Cmd_OfAsyncWith_perform((computation) => {
                    startImmediate(computation);
                }, () => {
                    const matchValue = state.ClientUserId;
                    if (matchValue == null) {
                        return singleton_1.Return(new FSharpResult$2(0, [defaultClientUser]));
                    }
                    else {
                        return findClientUser(matchValue);
                    }
                }, void 0, (arg) => (new Msg(0, [new AsyncMsg$1(1, [arg])])))];
            }
    }
}

export function render(state, dispatch) {
    let elems_50;
    return createElement("div", createObj(ofArray([["className", join(" ", [])], (elems_50 = toList(delay(() => {
        let user, elems_5, elems_4;
        const matchValue_2 = state.User;
        return (matchValue_2.tag === 1) ? singleton_2(render_1()) : ((matchValue_2.tag === 2) ? ((matchValue_2.fields[0].tag === 0) ? append(singleton_2((user = matchValue_2.fields[0].fields[0], createElement("div", createObj(ofArray([["className", "box"], (elems_5 = [createElement("h1", {
            className: join(" ", ["has-text-weight-semibold", "has-text-link"]),
            children: isNewClientUser(user) ? "Users | New User" : "Users | User Details",
        }), createElement("div", createObj(ofArray([["className", join(" ", ["columns", "is-align-items-center"])], (elems_4 = toList(delay(() => {
            let elems;
            return append(singleton_2(createElement("div", createObj(ofArray([["className", "column"], (elems = toList(delay(() => append(singleton_2(createElement("h1", {
                className: "has-text-link",
                children: isInactiveUser(user) ? (`${user.DisplayName} [${user.Email}] (inactive)`) : (isNewClientUser(user) ? "" : (`${user.DisplayName} [${user.Email}]`)),
            })), delay(() => (state.ChangesMade ? singleton_2(createElement("span", {
                className: join(" ", ["has-text-danger"]),
                children: "Attention: Changes have been made, please save.",
            })) : empty()))))), ["children", Interop_reactApi.Children.toArray(Array.from(elems))])])))), delay(() => {
                let elems_3, elems_1, elems_2;
                return isUserAuthorizedToEdit(state) ? singleton_2(createElement("div", createObj(ofArray([["className", join(" ", ["column", "is-flex", "is-justify-content-right"])], (elems_3 = [createElement("button", createObj(ofArray([["className", join(" ", ["button", "is-small", "ml-2"])], ["type", "button"], (elems_1 = [createElement("span", {
                    className: join(" ", ["material-icons", "is-small"]),
                    children: "delete",
                }), createElement("span", {
                    className: "is-sr-only",
                    children: "Delete User",
                })], ["children", Interop_reactApi.Children.toArray(Array.from(elems_1))]), ["onClick", (_arg) => {
                    dispatch(new Msg(8, []));
                }], ["disabled", isNewClientUser(user) ? true : isReadOnlyUser(state, user)]]))), createElement("button", createObj(ofArray([["className", join(" ", ["button", "is-small", "ml-2"])], ["disabled", !state.ChangesMade], ["type", "button"], (elems_2 = [createElement("span", {
                    className: join(" ", ["material-icons", "is-small"]),
                    children: "save",
                }), createElement("span", {
                    className: "is-sr-only",
                    children: "Save User",
                })], ["children", Interop_reactApi.Children.toArray(Array.from(elems_2))]), ["disabled", isReadOnlyUser(state, user)], ["onClick", (_arg_1) => {
                    if (isNewClientUser(user)) {
                        dispatch(new Msg(5, [new AsyncMsg$1(0, [])]));
                    }
                    else {
                        dispatch(new Msg(6, [new AsyncMsg$1(0, [])]));
                    }
                }]])))], ["children", Interop_reactApi.Children.toArray(Array.from(elems_3))])])))) : empty();
            }));
        })), ["children", Interop_reactApi.Children.toArray(Array.from(elems_4))])])))], ["children", Interop_reactApi.Children.toArray(Array.from(elems_5))])]))))), delay(() => {
            let user_1, elems_49, elems_12, elems_8, elems_7, elems_6, value_74, elems_11, elems_10, elems_9, value_102, elems_48, elems_18, elems_47;
            return singleton_2((user_1 = matchValue_2.fields[0].fields[0], createElement("div", createObj(ofArray([["className", join(" ", ["box"])], ["style", {
                maxHeight: 1200,
                overflowY: "auto",
            }], (elems_49 = [createElement("div", createObj(ofArray([["className", "columns"], (elems_12 = [createElement("div", createObj(ofArray([["className", "column"], (elems_8 = [createElement("div", createObj(ofArray([["className", "field"], (elems_7 = [createElement("label", {
                className: join(" ", ["label", "is-small"]),
                children: "Name",
            }), createElement("div", createObj(ofArray([["className", "control"], (elems_6 = [createElement("input", createObj(ofArray([["className", join(" ", ["input", "is-small"])], ["type", "text"], ["maxLength", 256], ["placeholder", "Name..."], (value_74 = (isNewClientUser(user_1) ? user_1.DisplayName : state.UserName), ["ref", (e) => {
                if (!(e == null) && !equals(e.value, value_74)) {
                    e.value = value_74;
                }
            }]), ["onChange", (ev) => {
                const value_76 = ev.target.value;
                if (isNewClientUser(user_1)) {
                    dispatch(new Msg(4, [true, (user_2) => (new ClientUser(user_2.Id, user_2.UserTypeId, user_2.Email, value_76, user_2.IsActive, user_2.CanViewAirShipments, user_2.CanViewOceanShipments, user_2.CanViewGroundShipments, user_2.CanCreateBookings, user_2.CanViewBookings, user_2.CanExportSearchResults, user_2.CanViewInventory, user_2.CanViewAnalytics, user_2.CanViewRailShipments, user_2.CanViewCO2Emissions, user_2.CanViewBilling, user_2.Accounts))]));
                }
                else {
                    dispatch(new Msg(9, [value_76]));
                }
            }], ["disabled", isInactiveUser(user_1)], ["readOnly", !isUserAuthorizedToEdit(state)]])))], ["children", Interop_reactApi.Children.toArray(Array.from(elems_6))])])))], ["children", Interop_reactApi.Children.toArray(Array.from(elems_7))])])))], ["children", Interop_reactApi.Children.toArray(Array.from(elems_8))])]))), createElement("div", createObj(ofArray([["className", "column"], (elems_11 = [createElement("div", createObj(ofArray([["className", "field"], (elems_10 = [createElement("label", {
                className: join(" ", ["label", "is-small"]),
                children: "Email",
            }), createElement("div", createObj(ofArray([["className", "control"], (elems_9 = [createElement("input", createObj(ofArray([["className", join(" ", ["input", "is-small"])], ["type", "email"], ["placeholder", "Email..."], ["maxLength", 256], (value_102 = (isNewClientUser(user_1) ? user_1.Email : state.UserEmail), ["ref", (e_1) => {
                if (!(e_1 == null) && !equals(e_1.value, value_102)) {
                    e_1.value = value_102;
                }
            }]), ["onChange", (ev_1) => {
                const value_104 = ev_1.target.value;
                if (isNewClientUser(user_1)) {
                    dispatch(new Msg(4, [true, (user_3) => (new ClientUser(user_3.Id, user_3.UserTypeId, value_104, user_3.DisplayName, user_3.IsActive, user_3.CanViewAirShipments, user_3.CanViewOceanShipments, user_3.CanViewGroundShipments, user_3.CanCreateBookings, user_3.CanViewBookings, user_3.CanExportSearchResults, user_3.CanViewInventory, user_3.CanViewAnalytics, user_3.CanViewRailShipments, user_3.CanViewCO2Emissions, user_3.CanViewBilling, user_3.Accounts))]));
                }
                else {
                    dispatch(new Msg(10, [value_104]));
                }
            }], ["disabled", isInactiveUser(user_1)], ["readOnly", !isUserAuthorizedToEdit(state)]])))], ["children", Interop_reactApi.Children.toArray(Array.from(elems_9))])])))], ["children", Interop_reactApi.Children.toArray(Array.from(elems_10))])])))], ["children", Interop_reactApi.Children.toArray(Array.from(elems_11))])])))], ["children", Interop_reactApi.Children.toArray(Array.from(elems_12))])]))), createElement("hr", {}), createElement("div", createObj(ofArray([["className", "columns"], (elems_48 = [createElement("div", createObj(ofArray([["className", "column"], (elems_18 = toList(delay(() => append(singleton_2(createElement("h1", {
                className: join(" ", ["block", "has-text-weight-semibold"]),
                children: "Account Access",
            })), delay(() => {
                let elems_13;
                const matchValue = state.GlobalAccount;
                let matchResult, globalAccount;
                switch (matchValue.tag) {
                    case 2: {
                        if (matchValue.fields[0].tag === 0) {
                            matchResult = 0;
                            globalAccount = matchValue.fields[0].fields[0];
                        }
                        else {
                            matchResult = 2;
                        }
                        break;
                    }
                    case 1: {
                        matchResult = 1;
                        break;
                    }
                    default:
                        matchResult = 2;
                }
                switch (matchResult) {
                    case 0: {
                        const accounts = map((x_1) => (new UserAccount(user_1.Id, x_1.Id, x_1.Name)), collect((x) => x.Accounts, globalAccount.Accounts));
                        return append(singleton_2(createElement("label", createObj(ofArray([["className", join(" ", ["checkbox", "is-flex", "is-align-items-center"])], (elems_13 = [createElement("input", {
                            type: "checkbox",
                            onChange: (ev_2) => {
                                dispatch(new Msg(3, [true, ev_2.target.checked ? accounts : (new Array(0))]));
                            },
                            checked: accounts.every((accountId) => state.SelectedAccounts.some((selectedAccount) => equals(selectedAccount, accountId))),
                            disabled: isReadOnlyUser(state, user_1),
                        }), createElement("span", {
                            className: "ml-2",
                            children: globalAccount.Name,
                        })], ["children", Interop_reactApi.Children.toArray(Array.from(elems_13))])])))), delay(() => collect_1((regionalAccount) => {
                            let elems_17;
                            const filteredRegionalAccounts = map((x_4) => (new UserAccount(user_1.Id, x_4.Id, x_4.Name)), collect((x_3) => x_3.Accounts, globalAccount.Accounts.filter((x_2) => (x_2.Id === regionalAccount.Id))));
                            return singleton_2(createElement("div", createObj(ofArray([["className", "mx-5"], (elems_17 = toList(delay(() => {
                                let elems_14;
                                return append(singleton_2(createElement("label", createObj(ofArray([["className", join(" ", ["checkbox", "is-flex", "is-align-items-center"])], (elems_14 = [createElement("input", {
                                    type: "checkbox",
                                    onChange: (ev_3) => {
                                        dispatch(new Msg(3, [true, ev_3.target.checked ? append_1(filteredRegionalAccounts, state.SelectedAccounts) : state.SelectedAccounts.filter((selectedAccount_1) => !filteredRegionalAccounts.some((account) => (account.AccountId === selectedAccount_1.AccountId)))]));
                                    },
                                    checked: filteredRegionalAccounts.every((filterdRegionalAccount) => state.SelectedAccounts.some((selectedAccount_2) => (selectedAccount_2.AccountId === filterdRegionalAccount.AccountId))),
                                    disabled: isReadOnlyUser(state, user_1),
                                }), createElement("span", {
                                    className: "ml-2",
                                    children: regionalAccount.Name,
                                })], ["children", Interop_reactApi.Children.toArray(Array.from(elems_14))])])))), delay(() => map_1((account_1) => {
                                    let elems_16, elems_15;
                                    return createElement("div", createObj(ofArray([["className", "mx-5"], (elems_16 = [createElement("label", createObj(ofArray([["className", join(" ", ["checkbox", "is-flex", "is-align-items-center"])], (elems_15 = [createElement("input", {
                                        type: "checkbox",
                                        onChange: (ev_4) => {
                                            dispatch(new Msg(3, [true, ev_4.target.checked ? append_1([new UserAccount(user_1.Id, account_1.Id, account_1.Name)], state.SelectedAccounts) : state.SelectedAccounts.filter((selectedAccount_3) => !(selectedAccount_3.AccountId === account_1.Id))]));
                                        },
                                        checked: state.SelectedAccounts.some((selectedAccount_4) => (selectedAccount_4.AccountId === account_1.Id)),
                                        disabled: isReadOnlyUser(state, user_1),
                                    }), createElement("span", {
                                        className: "ml-2",
                                        children: account_1.Name,
                                    })], ["children", Interop_reactApi.Children.toArray(Array.from(elems_15))])])))], ["children", Interop_reactApi.Children.toArray(Array.from(elems_16))])])));
                                }, sortBy((x_5) => x_5.Name, regionalAccount.Accounts, {
                                    Compare: comparePrimitives,
                                }))));
                            })), ["children", Interop_reactApi.Children.toArray(Array.from(elems_17))])]))));
                        }, sortBy((x_7) => x_7.Name, globalAccount.Accounts, {
                            Compare: comparePrimitives,
                        }))));
                    }
                    case 1:
                        return singleton_2(render_1());
                    default:
                        return singleton_2(defaultOf());
                }
            })))), ["children", Interop_reactApi.Children.toArray(Array.from(elems_18))])]))), createElement("div", createObj(ofArray([["className", "column"], (elems_47 = toList(delay(() => append(singleton_2(createElement("h1", {
                className: join(" ", ["block", "has-text-weight-semibold"]),
                children: "Permissions",
            })), delay(() => {
                let elems_23, elems_20, elems_19, elems_22, elems_21;
                const matchValue_1 = state.GlobalAccountModules;
                let matchResult_1, globalAccountModules;
                switch (matchValue_1.tag) {
                    case 2: {
                        if (matchValue_1.fields[0].tag === 0) {
                            matchResult_1 = 0;
                            globalAccountModules = matchValue_1.fields[0].fields[0];
                        }
                        else {
                            matchResult_1 = 2;
                        }
                        break;
                    }
                    case 1: {
                        matchResult_1 = 1;
                        break;
                    }
                    default:
                        matchResult_1 = 2;
                }
                switch (matchResult_1) {
                    case 0:
                        return append(globalAccountModules.CanViewBookings ? singleton_2(createElement("div", createObj(ofArray([["className", "block"], (elems_23 = [createElement("h2", {
                            className: join(" ", ["block", "has-text-weight-semibold"]),
                            children: "Bookings",
                        }), createElement("label", createObj(ofArray([["className", join(" ", ["checkbox", "is-flex", "is-align-items-center"])], (elems_20 = [createElement("input", {
                            type: "checkbox",
                            onChange: (ev_5) => {
                                const isChecked_3 = ev_5.target.checked;
                                dispatch(new Msg(4, [true, (user_4) => (new ClientUser(user_4.Id, user_4.UserTypeId, user_4.Email, user_4.DisplayName, user_4.IsActive, user_4.CanViewAirShipments, user_4.CanViewOceanShipments, user_4.CanViewGroundShipments, user_4.CanCreateBookings, isChecked_3, user_4.CanExportSearchResults, user_4.CanViewInventory, user_4.CanViewAnalytics, user_4.CanViewRailShipments, user_4.CanViewCO2Emissions, user_4.CanViewBilling, user_4.Accounts))]));
                            },
                            checked: user_1.CanViewBookings,
                            disabled: isReadOnlyUser(state, user_1),
                        }), createElement("span", createObj(ofArray([["className", "ml-2"], (elems_19 = [createElement("span", {
                            className: "has-text-weight-semibold",
                            children: "View Bookings",
                        }), " - view requested bookings"], ["children", Interop_reactApi.Children.toArray(Array.from(elems_19))])])))], ["children", Interop_reactApi.Children.toArray(Array.from(elems_20))])]))), createElement("label", createObj(ofArray([["className", join(" ", ["checkbox", "is-flex", "is-align-items-center"])], (elems_22 = [createElement("input", {
                            type: "checkbox",
                            onChange: (ev_6) => {
                                const isChecked_4 = ev_6.target.checked;
                                dispatch(new Msg(4, [true, (user_5) => (new ClientUser(user_5.Id, user_5.UserTypeId, user_5.Email, user_5.DisplayName, user_5.IsActive, user_5.CanViewAirShipments, user_5.CanViewOceanShipments, user_5.CanViewGroundShipments, isChecked_4, user_5.CanViewBookings, user_5.CanExportSearchResults, user_5.CanViewInventory, user_5.CanViewAnalytics, user_5.CanViewRailShipments, user_5.CanViewCO2Emissions, user_5.CanViewBilling, user_5.Accounts))]));
                            },
                            checked: user_1.CanCreateBookings,
                            disabled: isReadOnlyUser(state, user_1),
                        }), createElement("span", createObj(ofArray([["className", "ml-2"], (elems_21 = [createElement("span", {
                            className: "has-text-weight-semibold",
                            children: "Create Bookings",
                        }), " - request new bookings"], ["children", Interop_reactApi.Children.toArray(Array.from(elems_21))])])))], ["children", Interop_reactApi.Children.toArray(Array.from(elems_22))])])))], ["children", Interop_reactApi.Children.toArray(Array.from(elems_23))])])))) : empty(), delay(() => {
                            let elems_34, elems_25, elems_24, elems_27, elems_26, elems_29, elems_28, elems_31, elems_30, elems_33, elems_32;
                            return append(globalAccountModules.CanViewShipments ? singleton_2(createElement("div", createObj(ofArray([["className", "block"], (elems_34 = [createElement("h2", {
                                className: join(" ", ["block", "has-text-weight-semibold"]),
                                children: "Shipments",
                            }), createElement("label", createObj(ofArray([["className", join(" ", ["checkbox", "is-flex", "is-align-items-center"])], (elems_25 = [createElement("input", {
                                type: "checkbox",
                                onChange: (ev_7) => {
                                    const isChecked_5 = ev_7.target.checked;
                                    dispatch(new Msg(4, [true, (user_6) => (new ClientUser(user_6.Id, user_6.UserTypeId, user_6.Email, user_6.DisplayName, user_6.IsActive, isChecked_5, user_6.CanViewOceanShipments, user_6.CanViewGroundShipments, user_6.CanCreateBookings, user_6.CanViewBookings, user_6.CanExportSearchResults, user_6.CanViewInventory, user_6.CanViewAnalytics, user_6.CanViewRailShipments, user_6.CanViewCO2Emissions, user_6.CanViewBilling, user_6.Accounts))]));
                                },
                                checked: user_1.CanViewAirShipments,
                                disabled: isReadOnlyUser(state, user_1),
                            }), createElement("span", createObj(ofArray([["className", "ml-2"], (elems_24 = [createElement("span", {
                                className: "has-text-weight-semibold",
                                children: "View Air Shipments",
                            }), " - view shipments with air mode of transport"], ["children", Interop_reactApi.Children.toArray(Array.from(elems_24))])])))], ["children", Interop_reactApi.Children.toArray(Array.from(elems_25))])]))), createElement("label", createObj(ofArray([["className", join(" ", ["checkbox", "is-flex", "is-align-items-center"])], (elems_27 = [createElement("input", {
                                type: "checkbox",
                                onChange: (ev_8) => {
                                    const isChecked_6 = ev_8.target.checked;
                                    dispatch(new Msg(4, [true, (user_7) => (new ClientUser(user_7.Id, user_7.UserTypeId, user_7.Email, user_7.DisplayName, user_7.IsActive, user_7.CanViewAirShipments, isChecked_6, user_7.CanViewGroundShipments, user_7.CanCreateBookings, user_7.CanViewBookings, user_7.CanExportSearchResults, user_7.CanViewInventory, user_7.CanViewAnalytics, user_7.CanViewRailShipments, user_7.CanViewCO2Emissions, user_7.CanViewBilling, user_7.Accounts))]));
                                },
                                checked: user_1.CanViewOceanShipments,
                                disabled: isReadOnlyUser(state, user_1),
                            }), createElement("span", createObj(ofArray([["className", "ml-2"], (elems_26 = [createElement("span", {
                                className: "has-text-weight-semibold",
                                children: "View Ocean Shipments",
                            }), " - view shipments with ocean mode of transport"], ["children", Interop_reactApi.Children.toArray(Array.from(elems_26))])])))], ["children", Interop_reactApi.Children.toArray(Array.from(elems_27))])]))), createElement("label", createObj(ofArray([["className", join(" ", ["checkbox", "is-flex", "is-align-items-center"])], (elems_29 = [createElement("input", {
                                type: "checkbox",
                                onChange: (ev_9) => {
                                    const isChecked_7 = ev_9.target.checked;
                                    dispatch(new Msg(4, [true, (user_8) => (new ClientUser(user_8.Id, user_8.UserTypeId, user_8.Email, user_8.DisplayName, user_8.IsActive, user_8.CanViewAirShipments, user_8.CanViewOceanShipments, isChecked_7, user_8.CanCreateBookings, user_8.CanViewBookings, user_8.CanExportSearchResults, user_8.CanViewInventory, user_8.CanViewAnalytics, user_8.CanViewRailShipments, user_8.CanViewCO2Emissions, user_8.CanViewBilling, user_8.Accounts))]));
                                },
                                checked: user_1.CanViewGroundShipments,
                                disabled: isReadOnlyUser(state, user_1),
                            }), createElement("span", createObj(ofArray([["className", "ml-2"], (elems_28 = [createElement("span", {
                                className: "has-text-weight-semibold",
                                children: "View Ground Shipments",
                            }), " - view shipments with ground mode of transport"], ["children", Interop_reactApi.Children.toArray(Array.from(elems_28))])])))], ["children", Interop_reactApi.Children.toArray(Array.from(elems_29))])]))), createElement("label", createObj(ofArray([["className", join(" ", ["checkbox", "is-flex", "is-align-items-center"])], (elems_31 = [createElement("input", {
                                type: "checkbox",
                                onChange: (ev_10) => {
                                    const isChecked_8 = ev_10.target.checked;
                                    dispatch(new Msg(4, [true, (user_9) => (new ClientUser(user_9.Id, user_9.UserTypeId, user_9.Email, user_9.DisplayName, user_9.IsActive, user_9.CanViewAirShipments, user_9.CanViewOceanShipments, user_9.CanViewGroundShipments, user_9.CanCreateBookings, user_9.CanViewBookings, user_9.CanExportSearchResults, user_9.CanViewInventory, user_9.CanViewAnalytics, isChecked_8, user_9.CanViewCO2Emissions, user_9.CanViewBilling, user_9.Accounts))]));
                                },
                                checked: user_1.CanViewRailShipments,
                                disabled: isReadOnlyUser(state, user_1),
                            }), createElement("span", createObj(ofArray([["className", "ml-2"], (elems_30 = [createElement("span", {
                                className: "has-text-weight-semibold",
                                children: "View Rail Shipments",
                            }), " - view shipments with rail mode of transport"], ["children", Interop_reactApi.Children.toArray(Array.from(elems_30))])])))], ["children", Interop_reactApi.Children.toArray(Array.from(elems_31))])]))), createElement("label", createObj(ofArray([["className", join(" ", ["checkbox", "is-flex", "is-align-items-center"])], (elems_33 = [createElement("input", {
                                type: "checkbox",
                                onChange: (ev_11) => {
                                    const isChecked_9 = ev_11.target.checked;
                                    dispatch(new Msg(4, [true, (user_10) => (new ClientUser(user_10.Id, user_10.UserTypeId, user_10.Email, user_10.DisplayName, user_10.IsActive, user_10.CanViewAirShipments, user_10.CanViewOceanShipments, user_10.CanViewGroundShipments, user_10.CanCreateBookings, user_10.CanViewBookings, user_10.CanExportSearchResults, user_10.CanViewInventory, user_10.CanViewAnalytics, user_10.CanViewRailShipments, isChecked_9, user_10.CanViewBilling, user_10.Accounts))]));
                                },
                                checked: user_1.CanViewCO2Emissions,
                                disabled: isReadOnlyUser(state, user_1),
                            }), createElement("span", createObj(ofArray([["className", "ml-2"], (elems_32 = [createElement("span", {
                                className: "has-text-weight-semibold",
                                children: "View CO2 Emissions",
                            }), " - view shipment CO2 emissions"], ["children", Interop_reactApi.Children.toArray(Array.from(elems_32))])])))], ["children", Interop_reactApi.Children.toArray(Array.from(elems_33))])])))], ["children", Interop_reactApi.Children.toArray(Array.from(elems_34))])])))) : empty(), delay(() => {
                                let elems_37, elems_36, elems_35;
                                return append(globalAccountModules.CanViewInventory ? singleton_2(createElement("div", createObj(ofArray([["className", "block"], (elems_37 = [createElement("h2", {
                                    className: join(" ", ["block", "has-text-weight-semibold"]),
                                    children: "Inventory",
                                }), createElement("label", createObj(ofArray([["className", join(" ", ["checkbox", "is-flex", "is-align-items-center"])], (elems_36 = [createElement("input", {
                                    type: "checkbox",
                                    onChange: (ev_12) => {
                                        const isChecked_10 = ev_12.target.checked;
                                        dispatch(new Msg(4, [true, (user_11) => (new ClientUser(user_11.Id, user_11.UserTypeId, user_11.Email, user_11.DisplayName, user_11.IsActive, user_11.CanViewAirShipments, user_11.CanViewOceanShipments, user_11.CanViewGroundShipments, user_11.CanCreateBookings, user_11.CanViewBookings, user_11.CanExportSearchResults, isChecked_10, user_11.CanViewAnalytics, user_11.CanViewRailShipments, user_11.CanViewCO2Emissions, user_11.CanViewBilling, user_11.Accounts))]));
                                    },
                                    checked: user_1.CanViewInventory,
                                    disabled: isReadOnlyUser(state, user_1),
                                }), createElement("span", createObj(ofArray([["className", "ml-2"], (elems_35 = [createElement("span", {
                                    className: "has-text-weight-semibold",
                                    children: "View Inventory",
                                }), " - view purchase orders, material receipts, and packing lists"], ["children", Interop_reactApi.Children.toArray(Array.from(elems_35))])])))], ["children", Interop_reactApi.Children.toArray(Array.from(elems_36))])])))], ["children", Interop_reactApi.Children.toArray(Array.from(elems_37))])])))) : empty(), delay(() => {
                                    let elems_40, elems_39, elems_38;
                                    return append(globalAccountModules.CanViewBilling ? singleton_2(createElement("div", createObj(ofArray([["className", "block"], (elems_40 = [createElement("h2", {
                                        className: join(" ", ["block", "has-text-weight-semibold"]),
                                        children: "Billing",
                                    }), createElement("label", createObj(ofArray([["className", join(" ", ["checkbox", "is-flex", "is-align-items-center"])], (elems_39 = [createElement("input", {
                                        type: "checkbox",
                                        onChange: (ev_13) => {
                                            const isChecked_11 = ev_13.target.checked;
                                            dispatch(new Msg(4, [true, (user_12) => (new ClientUser(user_12.Id, user_12.UserTypeId, user_12.Email, user_12.DisplayName, user_12.IsActive, user_12.CanViewAirShipments, user_12.CanViewOceanShipments, user_12.CanViewGroundShipments, user_12.CanCreateBookings, user_12.CanViewBookings, user_12.CanExportSearchResults, user_12.CanViewInventory, user_12.CanViewAnalytics, user_12.CanViewRailShipments, user_12.CanViewCO2Emissions, isChecked_11, user_12.Accounts))]));
                                        },
                                        checked: user_1.CanViewBilling,
                                        disabled: isReadOnlyUser(state, user_1),
                                    }), createElement("span", createObj(ofArray([["className", "ml-2"], (elems_38 = [createElement("span", {
                                        className: "has-text-weight-semibold",
                                        children: "View Invoices",
                                    }), " - view invoices"], ["children", Interop_reactApi.Children.toArray(Array.from(elems_38))])])))], ["children", Interop_reactApi.Children.toArray(Array.from(elems_39))])])))], ["children", Interop_reactApi.Children.toArray(Array.from(elems_40))])])))) : empty(), delay(() => {
                                        let elems_43, elems_42, elems_41;
                                        return append(globalAccountModules.CanViewAnalytics ? singleton_2(createElement("div", createObj(ofArray([["className", "block"], (elems_43 = [createElement("h2", {
                                            className: join(" ", ["block", "has-text-weight-semibold"]),
                                            children: "Custom Analytics",
                                        }), createElement("label", createObj(ofArray([["className", join(" ", ["checkbox", "is-flex", "is-align-items-center"])], (elems_42 = [createElement("input", {
                                            type: "checkbox",
                                            onChange: (ev_14) => {
                                                const isChecked_12 = ev_14.target.checked;
                                                dispatch(new Msg(4, [true, (user_13) => (new ClientUser(user_13.Id, user_13.UserTypeId, user_13.Email, user_13.DisplayName, user_13.IsActive, user_13.CanViewAirShipments, user_13.CanViewOceanShipments, user_13.CanViewGroundShipments, user_13.CanCreateBookings, user_13.CanViewBookings, user_13.CanExportSearchResults, user_13.CanViewInventory, isChecked_12, user_13.CanViewRailShipments, user_13.CanViewCO2Emissions, user_13.CanViewBilling, user_13.Accounts))]));
                                            },
                                            checked: user_1.CanViewAnalytics,
                                            disabled: isReadOnlyUser(state, user_1),
                                        }), createElement("span", createObj(ofArray([["className", "ml-2"], (elems_41 = [createElement("span", {
                                            className: "has-text-weight-semibold",
                                            children: "View Custom Analytics",
                                        }), " - view embedded Power BI dashboards"], ["children", Interop_reactApi.Children.toArray(Array.from(elems_41))])])))], ["children", Interop_reactApi.Children.toArray(Array.from(elems_42))])])))], ["children", Interop_reactApi.Children.toArray(Array.from(elems_43))])])))) : empty(), delay(() => {
                                            let elems_46, elems_45, elems_44;
                                            return singleton_2(createElement("div", createObj(ofArray([["className", "block"], (elems_46 = [createElement("h2", {
                                                className: join(" ", ["block", "has-text-weight-semibold"]),
                                                children: "General",
                                            }), createElement("label", createObj(ofArray([["className", join(" ", ["checkbox", "is-flex", "is-align-items-center"])], (elems_45 = [createElement("input", {
                                                type: "checkbox",
                                                onChange: (ev_15) => {
                                                    const isChecked_13 = ev_15.target.checked;
                                                    dispatch(new Msg(4, [true, (user_14) => (new ClientUser(user_14.Id, user_14.UserTypeId, user_14.Email, user_14.DisplayName, user_14.IsActive, user_14.CanViewAirShipments, user_14.CanViewOceanShipments, user_14.CanViewGroundShipments, user_14.CanCreateBookings, user_14.CanViewBookings, isChecked_13, user_14.CanViewInventory, user_14.CanViewAnalytics, user_14.CanViewRailShipments, user_14.CanViewCO2Emissions, user_14.CanViewBilling, user_14.Accounts))]));
                                                },
                                                checked: user_1.CanExportSearchResults,
                                                disabled: isReadOnlyUser(state, user_1),
                                            }), createElement("span", createObj(ofArray([["className", "ml-2"], (elems_44 = [createElement("span", {
                                                className: "has-text-weight-semibold",
                                                children: "Export Search Results",
                                            }), " - download search results to a CSV file"], ["children", Interop_reactApi.Children.toArray(Array.from(elems_44))])])))], ["children", Interop_reactApi.Children.toArray(Array.from(elems_45))])])))], ["children", Interop_reactApi.Children.toArray(Array.from(elems_46))])]))));
                                        }));
                                    }));
                                }));
                            }));
                        }));
                    case 1:
                        return singleton_2(render_1());
                    default:
                        return singleton_2(defaultOf());
                }
            })))), ["children", Interop_reactApi.Children.toArray(Array.from(elems_47))])])))], ["children", Interop_reactApi.Children.toArray(Array.from(elems_48))])])))], ["children", Interop_reactApi.Children.toArray(Array.from(elems_49))])])))));
        })) : singleton_2(defaultOf())) : singleton_2(defaultOf()));
    })), ["children", Interop_reactApi.Children.toArray(Array.from(elems_50))])])));
}

