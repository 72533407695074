import { Union, Record } from "../fable_modules/fable-library.4.10.0/Types.js";
import { lambda_type, bool_type, record_type, union_type, string_type, class_type } from "../fable_modules/fable-library.4.10.0/Reflection.js";
import { ClientUserSearchQuery, ClientUserQuery as ClientUserQuery_2, ClientUserQuery_get_Default, PagedDataResponse$1_$reflection, UserListItem_$reflection, ClientUserQuery_$reflection } from "../../CView.DTOs/DTOs.fs.js";
import { FSharpResult$2 } from "../fable_modules/fable-library.4.10.0/Choice.js";
import { AsyncMsg$1, Deferred$1, Deferred_exists, AsyncMsg$1_$reflection, Deferred$1_$reflection } from "../Extensions.fs.js";
import { equals as equals_1, fromInt32, toInt64, compare, toInt32 } from "../fable_modules/fable-library.4.10.0/BigInt.js";
import { ofArray, singleton } from "../fable_modules/fable-library.4.10.0/List.js";
import { Alert_text, Alert_title, Alert_error } from "../Components/SweetAlert.fs.js";
import { Cmd_none } from "../fable_modules/Fable.Elmish.4.0.2/cmd.fs.js";
import { createObj, equals } from "../fable_modules/fable-library.4.10.0/Util.js";
import { Cmd_OfAsyncWith_perform } from "../fable_modules/Fable.Elmish.4.0.2/./cmd.fs.js";
import { startImmediate } from "../fable_modules/fable-library.4.10.0/Async.js";
import { searchUsersByDomain } from "../Api/UserApi.fs.js";
import { createElement } from "react";
import { join } from "../fable_modules/fable-library.4.10.0/String.js";
import { formatInt } from "../Domain/Formatter.fs.js";
import { Interop_reactApi } from "../fable_modules/Feliz.2.6.0/./Interop.fs.js";
import { defaultOf } from "../fable_modules/FsToolkit.ErrorHandling.4.9.0/../../Pages/../fable_modules/fable-library.4.10.0/Util.js";
import { defaultArg } from "../fable_modules/fable-library.4.10.0/Option.js";
import { map as map_1, empty, singleton as singleton_1, append, delay, toList } from "../fable_modules/fable-library.4.10.0/Seq.js";
import { UserTypeId_ClientAdministrator } from "../../CView.DTOs/Constants.fs.js";
import { RouterModule_nav } from "../fable_modules/Feliz.Router.4.0.0/./Router.fs.js";
import { render as render_1 } from "../Components/Loader.fs.js";
import { defaultOf as defaultOf_1 } from "../fable_modules/Feliz.2.6.0/../fable-library.4.10.0/Util.js";

export class State extends Record {
    constructor(UserTypeId, ClientUserQuery, PreviousClientUserQuery, UsersDeferred) {
        super();
        this.UserTypeId = UserTypeId;
        this.ClientUserQuery = ClientUserQuery;
        this.PreviousClientUserQuery = PreviousClientUserQuery;
        this.UsersDeferred = UsersDeferred;
    }
}

export function State_$reflection() {
    return record_type("CView.UI.Pages.ClientUserSearch.State", [], State, () => [["UserTypeId", class_type("System.Guid")], ["ClientUserQuery", ClientUserQuery_$reflection()], ["PreviousClientUserQuery", ClientUserQuery_$reflection()], ["UsersDeferred", Deferred$1_$reflection(union_type("Microsoft.FSharp.Core.FSharpResult`2", [PagedDataResponse$1_$reflection(UserListItem_$reflection()), string_type], FSharpResult$2, () => [[["ResultValue", PagedDataResponse$1_$reflection(UserListItem_$reflection())]], [["ErrorValue", string_type]]]))]]);
}

export class Msg extends Union {
    constructor(tag, fields) {
        super();
        this.tag = tag;
        this.fields = fields;
    }
    cases() {
        return ["GetClientUsers", "UpdateClientUserQuery"];
    }
}

export function Msg_$reflection() {
    return union_type("CView.UI.Pages.ClientUserSearch.Msg", [], Msg, () => [[["Item1", AsyncMsg$1_$reflection(union_type("Microsoft.FSharp.Core.FSharpResult`2", [PagedDataResponse$1_$reflection(UserListItem_$reflection()), string_type], FSharpResult$2, () => [[["ResultValue", PagedDataResponse$1_$reflection(UserListItem_$reflection())]], [["ErrorValue", string_type]]]))], ["Item2", bool_type]], [["Item1", lambda_type(ClientUserQuery_$reflection(), ClientUserQuery_$reflection())], ["Item2", bool_type]]]);
}

function hasPreviousPage(state) {
    return Deferred_exists((result) => {
        if (result.tag === 0) {
            return result.fields[0].Page > 1;
        }
        else {
            return false;
        }
    }, state.UsersDeferred);
}

function hasNextPage(state) {
    return Deferred_exists((result) => {
        if (result.tag === 0) {
            const pagedData = result.fields[0];
            return (pagedData.Page * pagedData.PageSize) < ~~toInt32(pagedData.TotalCount);
        }
        else {
            return false;
        }
    }, state.UsersDeferred);
}

export function init(userTypeId, globalAccountId) {
    let clientUserQuery;
    const bind$0040 = ClientUserQuery_get_Default();
    clientUserQuery = (new ClientUserQuery_2(globalAccountId, bind$0040.SearchCriteria, bind$0040.OnlyShowActive, bind$0040.Page, "DisplayName", bind$0040.OrderDirection));
    return [new State(userTypeId, clientUserQuery, clientUserQuery, new Deferred$1(0, [])), singleton((dispatch) => {
        dispatch(new Msg(0, [new AsyncMsg$1(0, []), false]));
    })];
}

export function update(msg, state) {
    let bind$0040;
    if (msg.tag === 0) {
        if (msg.fields[0].tag === 1) {
            return [new State(state.UserTypeId, (bind$0040 = state.ClientUserQuery, new ClientUserQuery_2(bind$0040.GlobalAccountId, bind$0040.SearchCriteria, state.PreviousClientUserQuery.OnlyShowActive, bind$0040.Page, bind$0040.OrderBy, bind$0040.OrderDirection)), msg.fields[1] ? state.PreviousClientUserQuery : state.ClientUserQuery, new Deferred$1(2, [msg.fields[0].fields[0]])), (msg.fields[0].fields[0].tag === 1) ? Alert_error(Alert_title("Search Users", Alert_text(msg.fields[0].fields[0].fields[0]))) : Cmd_none()];
        }
        else if (equals(state.UsersDeferred, new Deferred$1(1, []))) {
            return [state, Cmd_none()];
        }
        else {
            return [new State(state.UserTypeId, state.ClientUserQuery, state.PreviousClientUserQuery, new Deferred$1(1, [])), Cmd_OfAsyncWith_perform((computation) => {
                startImmediate(computation);
            }, searchUsersByDomain, msg.fields[1] ? (new ClientUserSearchQuery(state.PreviousClientUserQuery.GlobalAccountId, state.PreviousClientUserQuery.SearchCriteria, state.PreviousClientUserQuery.OnlyShowActive, state.PreviousClientUserQuery.Page, state.PreviousClientUserQuery.OrderBy, state.PreviousClientUserQuery.OrderDirection)) : (new ClientUserSearchQuery(state.ClientUserQuery.GlobalAccountId, state.ClientUserQuery.SearchCriteria, state.PreviousClientUserQuery.OnlyShowActive, 1, state.PreviousClientUserQuery.OrderBy, state.PreviousClientUserQuery.OrderDirection)), (arg) => (new Msg(0, [new AsyncMsg$1(1, [arg]), msg.fields[1]])))];
        }
    }
    else {
        return [new State(state.UserTypeId, msg.fields[1] ? state.ClientUserQuery : msg.fields[0](state.PreviousClientUserQuery), msg.fields[1] ? msg.fields[0](state.PreviousClientUserQuery) : state.PreviousClientUserQuery, state.UsersDeferred), Cmd_none()];
    }
}

export function render(state, dispatch) {
    let elems_24, elems_16, elems_1, elems, matchValue, elems_15, elems_14, elems_13, elems_6, elems_5, elems_2, value_36, elems_4, elems_3, elems_12, elems_11, elems_23, elems_22;
    return createElement("div", createObj(ofArray([["className", join(" ", [])], (elems_24 = [createElement("div", createObj(ofArray([["className", join(" ", ["box"])], (elems_16 = [createElement("h1", {
        className: join(" ", ["has-text-weight-semibold", "has-text-link"]),
        children: "Users",
    }), createElement("div", createObj(ofArray([["className", join(" ", ["columns"])], (elems_1 = [createElement("div", createObj(ofArray([["className", join(" ", ["column", "is-flex", "is-align-items-center"])], (elems = [createElement("h1", {
        className: join(" ", ["has-text-link"]),
        children: (matchValue = state.UsersDeferred, (matchValue.tag === 1) ? "Searching..." : ((matchValue.tag === 2) ? ((matchValue.fields[0].tag === 0) ? ((compare(matchValue.fields[0].fields[0].TotalCount, toInt64(fromInt32(0))) > 0) ? (`${formatInt(~~toInt32(matchValue.fields[0].fields[0].TotalCount))} users found | showing page ${formatInt(matchValue.fields[0].fields[0].Page)} of ${formatInt(matchValue.fields[0].fields[0].NumberOfPages)}`) : "0 users found") : "") : "")),
    })], ["children", Interop_reactApi.Children.toArray(Array.from(elems))])])))], ["children", Interop_reactApi.Children.toArray(Array.from(elems_1))])]))), createElement("div", createObj(ofArray([["className", join(" ", ["columns"])], (elems_15 = [createElement("div", createObj(ofArray([["className", join(" ", ["column"])], (elems_14 = [createElement("div", createObj(ofArray([["className", join(" ", ["columns"])], (elems_13 = [createElement("div", createObj(ofArray([["className", join(" ", ["column"])], (elems_6 = [createElement("div", createObj(ofArray([["className", join(" ", ["field", "has-addons", "has-addons-right"])], (elems_5 = [createElement("p", createObj(ofArray([["className", join(" ", ["control", "is-expanded"])], (elems_2 = [createElement("input", createObj(ofArray([["className", join(" ", ["input", "is-small"])], ["maxLength", 256], ["placeholder", "Search..."], ["onChange", (ev) => {
        const value_30 = ev.target.value;
        dispatch(new Msg(1, [(query) => {
            let value_32;
            return new ClientUserQuery_2(query.GlobalAccountId, (value_32 = value_30, (value_32 === defaultOf()) ? void 0 : value_32), query.OnlyShowActive, query.Page, query.OrderBy, query.OrderDirection);
        }, false]));
    }], ["onKeyPress", (keyPress) => {
        if (keyPress.key === "Enter") {
            dispatch(new Msg(0, [new AsyncMsg$1(0, []), false]));
        }
    }], (value_36 = defaultArg(state.ClientUserQuery.SearchCriteria, ""), ["ref", (e) => {
        if (!(e == null) && !equals(e.value, value_36)) {
            e.value = value_36;
        }
    }])])))], ["children", Interop_reactApi.Children.toArray(Array.from(elems_2))])]))), createElement("p", createObj(ofArray([["className", join(" ", ["control"])], (elems_4 = [createElement("button", createObj(ofArray([["className", join(" ", ["button", "is-small"])], ["type", "button"], ["onClick", (_arg) => {
        dispatch(new Msg(0, [new AsyncMsg$1(0, []), false]));
    }], (elems_3 = [createElement("span", {
        className: join(" ", ["material-icons", "is-small"]),
        children: "search",
    }), createElement("span", {
        className: "is-sr-only",
        children: "Search",
    })], ["children", Interop_reactApi.Children.toArray(Array.from(elems_3))])])))], ["children", Interop_reactApi.Children.toArray(Array.from(elems_4))])])))], ["children", Interop_reactApi.Children.toArray(Array.from(elems_5))])])))], ["children", Interop_reactApi.Children.toArray(Array.from(elems_6))])]))), createElement("div", createObj(ofArray([["className", join(" ", ["column", "is-narrow"])], (elems_12 = [createElement("div", createObj(ofArray([["className", join(" ", ["is-flex", "is-justify-content-right"])], (elems_11 = toList(delay(() => {
        let elems_7;
        return append(singleton_1(createElement("div", createObj(ofArray([["className", join(" ", ["is-flex", "is-align-items-center", "is-justify-content-right"])], (elems_7 = [createElement("input", {
            id: "view-active-users",
            id: "view-active-users",
            name: "view-active-users",
            type: "checkbox",
            className: join(" ", ["is-clickable", "ml-2"]),
            checked: state.PreviousClientUserQuery.OnlyShowActive,
            onChange: (ev_1) => {
                const value_67 = ev_1.target.checked;
                dispatch(new Msg(1, [(query_1) => (new ClientUserQuery_2(query_1.GlobalAccountId, query_1.SearchCriteria, value_67, 1, query_1.OrderBy, query_1.OrderDirection)), true]));
                dispatch(new Msg(0, [new AsyncMsg$1(0, []), true]));
            },
        }), createElement("label", {
            className: join(" ", ["is-clickable", "mx-2"]),
            for: "view-active-users",
            children: "Active Only",
        })], ["children", Interop_reactApi.Children.toArray(Array.from(elems_7))])])))), delay(() => {
            let elems_8;
            return append(singleton_1(createElement("button", createObj(ofArray([["className", join(" ", ["button", "is-small", "ml-2"])], ["type", "button"], ["disabled", !hasPreviousPage(state)], ["onClick", (_arg_1) => {
                if (hasPreviousPage(state)) {
                    dispatch(new Msg(1, [(query_2) => (new ClientUserQuery_2(query_2.GlobalAccountId, query_2.SearchCriteria, query_2.OnlyShowActive, state.PreviousClientUserQuery.Page - 1, query_2.OrderBy, query_2.OrderDirection)), false]));
                    dispatch(new Msg(0, [new AsyncMsg$1(0, []), true]));
                }
            }], (elems_8 = [createElement("span", {
                className: join(" ", ["material-icons", "is-small"]),
                children: "navigate_before",
            }), createElement("span", {
                className: "is-sr-only",
                children: "Previous",
            })], ["children", Interop_reactApi.Children.toArray(Array.from(elems_8))])])))), delay(() => {
                let elems_9;
                return append(singleton_1(createElement("button", createObj(ofArray([["className", join(" ", ["button", "is-small", "ml-2"])], ["type", "button"], ["disabled", !hasNextPage(state)], ["onClick", (_arg_2) => {
                    if (hasNextPage(state)) {
                        dispatch(new Msg(1, [(query_3) => (new ClientUserQuery_2(query_3.GlobalAccountId, query_3.SearchCriteria, query_3.OnlyShowActive, state.PreviousClientUserQuery.Page + 1, query_3.OrderBy, query_3.OrderDirection)), false]));
                        dispatch(new Msg(0, [new AsyncMsg$1(0, []), true]));
                    }
                }], (elems_9 = [createElement("span", {
                    className: join(" ", ["material-icons", "is-small"]),
                    children: "navigate_next",
                }), createElement("span", {
                    className: join(" ", ["is-sr-only"]),
                    children: "Next",
                })], ["children", Interop_reactApi.Children.toArray(Array.from(elems_9))])])))), delay(() => {
                    let elems_10;
                    return (state.UserTypeId === UserTypeId_ClientAdministrator) ? singleton_1(createElement("button", createObj(ofArray([["className", join(" ", ["button", "is-small", "ml-2"])], ["type", "button"], ["onClick", (_arg_3) => {
                        RouterModule_nav(singleton("new-user"), 1, 1);
                    }], (elems_10 = [createElement("span", {
                        className: join(" ", ["material-icons", "is-small"]),
                        children: "add",
                    }), createElement("span", {
                        className: join(" ", ["is-sr-only"]),
                        children: "New User",
                    })], ["children", Interop_reactApi.Children.toArray(Array.from(elems_10))]), ["disabled", state.ClientUserQuery.GlobalAccountId === "00000000-0000-0000-0000-000000000000"]])))) : empty();
                }));
            }));
        }));
    })), ["children", Interop_reactApi.Children.toArray(Array.from(elems_11))])])))], ["children", Interop_reactApi.Children.toArray(Array.from(elems_12))])])))], ["children", Interop_reactApi.Children.toArray(Array.from(elems_13))])])))], ["children", Interop_reactApi.Children.toArray(Array.from(elems_14))])])))], ["children", Interop_reactApi.Children.toArray(Array.from(elems_15))])])))], ["children", Interop_reactApi.Children.toArray(Array.from(elems_16))])]))), createElement("div", createObj(ofArray([["className", join(" ", ["box"])], (elems_23 = [createElement("div", createObj(ofArray([["className", join(" ", ["table-container"])], (elems_22 = toList(delay(() => {
        let elems_21, elems_17, children, elems_20;
        const matchValue_2 = state.UsersDeferred;
        return (matchValue_2.tag === 1) ? singleton_1(render_1()) : ((matchValue_2.tag === 2) ? ((matchValue_2.fields[0].tag === 0) ? (equals_1(matchValue_2.fields[0].fields[0].TotalCount, toInt64(fromInt32(0))) ? singleton_1(createElement("div", {
            className: join(" ", ["has-text-centered"]),
            children: "No users were found that meet the search criteria",
        })) : singleton_1(createElement("table", createObj(ofArray([["className", join(" ", ["table", "is-narrow", "is-hoverable", "is-fullwidth"])], (elems_21 = [createElement("thead", createObj(singleton((elems_17 = [(children = ofArray([createElement("th", {
            className: join(" ", ["p-2", "has-text-link", "is-underlined", "is-clickable"]),
            scope: "col",
            children: "Name",
            onClick: (_arg_4) => {
                dispatch(new Msg(1, [(query_4) => (new ClientUserQuery_2(query_4.GlobalAccountId, query_4.SearchCriteria, query_4.OnlyShowActive, query_4.Page, "DisplayName", (state.PreviousClientUserQuery.OrderDirection === "ASC") ? "DESC" : "ASC")), true]));
                dispatch(new Msg(0, [new AsyncMsg$1(0, []), true]));
            },
        }), createElement("th", {
            className: join(" ", ["p-2", "has-text-link", "is-underlined", "is-clickable"]),
            scope: "col",
            children: "Email",
            onClick: (_arg_5) => {
                dispatch(new Msg(1, [(query_5) => (new ClientUserQuery_2(query_5.GlobalAccountId, query_5.SearchCriteria, query_5.OnlyShowActive, query_5.Page, "Email", (state.PreviousClientUserQuery.OrderDirection === "ASC") ? "DESC" : "ASC")), true]));
                dispatch(new Msg(0, [new AsyncMsg$1(0, []), true]));
            },
        }), createElement("th", {
            className: join(" ", ["p-2", "has-text-link"]),
            scope: "col",
            children: "Active",
        })]), createElement("tr", {
            children: Interop_reactApi.Children.toArray(Array.from(children)),
        }))], ["children", Interop_reactApi.Children.toArray(Array.from(elems_17))])))), createElement("tbody", createObj(singleton((elems_20 = toList(delay(() => {
            const matchValue_3 = state.UsersDeferred;
            return (matchValue_3.tag === 1) ? singleton_1(defaultOf_1()) : ((matchValue_3.tag === 2) ? ((matchValue_3.fields[0].tag === 0) ? map_1((user) => {
                let elems_19, elems_18;
                return createElement("tr", createObj(ofArray([["className", join(" ", ["is-clickable"])], ["onClick", (_arg_6) => {
                    RouterModule_nav(ofArray(["user-details", user.UserId]), 1, 1);
                }], (elems_19 = [createElement("td", {
                    className: join(" ", ["p-2"]),
                    children: user.DisplayName,
                }), createElement("td", {
                    className: join(" ", ["p-2"]),
                    children: user.Email,
                }), createElement("td", createObj(ofArray([["className", join(" ", ["p-2"])], (elems_18 = [createElement("span", {
                    className: join(" ", toList(delay(() => append(singleton_1("tag"), delay(() => (user.IsActive ? singleton_1("is-success") : empty())))))),
                    children: user.IsActive ? "Yes" : "No",
                })], ["children", Interop_reactApi.Children.toArray(Array.from(elems_18))])])))], ["children", Interop_reactApi.Children.toArray(Array.from(elems_19))])])));
            }, matchValue_3.fields[0].fields[0].Data) : singleton_1(defaultOf_1())) : singleton_1(defaultOf_1()));
        })), ["children", Interop_reactApi.Children.toArray(Array.from(elems_20))]))))], ["children", Interop_reactApi.Children.toArray(Array.from(elems_21))])]))))) : singleton_1(defaultOf_1())) : singleton_1(defaultOf_1()));
    })), ["children", Interop_reactApi.Children.toArray(Array.from(elems_22))])])))], ["children", Interop_reactApi.Children.toArray(Array.from(elems_23))])])))], ["children", Interop_reactApi.Children.toArray(Array.from(elems_24))])])));
}

