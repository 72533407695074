import { toString, Union, Record } from "../fable_modules/fable-library.4.10.0/Types.js";
import { ShipmentSearchQuery, UserDataSources_$reflection, User_$reflection, Account_$reflection, PagedDataResponse$1_$reflection, ShipmentSearchItem_$reflection, ShipmentSearchQuery_$reflection } from "../../CView.DTOs/DTOs.fs.js";
import { int32_type, option_type, class_type, bool_type, lambda_type, record_type, array_type, unit_type, union_type, string_type } from "../fable_modules/fable-library.4.10.0/Reflection.js";
import { FSharpResult$2 } from "../fable_modules/fable-library.4.10.0/Choice.js";
import { AsyncMsg$1, Deferred$1, Deferred_exists, AsyncMsg$1_$reflection, Deferred$1_$reflection } from "../Extensions.fs.js";
import { render as render_1, update as update_1, State as State_1, init as init_1, Msg_$reflection as Msg_$reflection_1, State_$reflection as State_$reflection_1 } from "../Components/SelectSearch.fs.js";
import { toInt32, fromInt32, toInt64, compare } from "../fable_modules/fable-library.4.10.0/BigInt.js";
import { map as map_1, sortBy } from "../fable_modules/fable-library.4.10.0/Array.js";
import { defaultOf as defaultOf_1, createObj, equals, comparePrimitives } from "../fable_modules/fable-library.4.10.0/Util.js";
import { getShippers, getConsignees, getLocations } from "../Api/DataSourceApi.fs.js";
import { SearchType_Entity, Location_LocationType, Location_Entity } from "../../CView.DTOs/Constants.fs.js";
import { Cmd_none, Cmd_map, Cmd_batch } from "../fable_modules/Fable.Elmish.4.0.2/cmd.fs.js";
import { ofArray, singleton } from "../fable_modules/fable-library.4.10.0/List.js";
import { Alert_text, Alert_title, Alert_error } from "../Components/SweetAlert.fs.js";
import { Cmd_OfAsyncWith_perform } from "../fable_modules/Fable.Elmish.4.0.2/./cmd.fs.js";
import { startImmediate } from "../fable_modules/fable-library.4.10.0/Async.js";
import { export$, search } from "../Api/ShipmentApi.fs.js";
import { defaultShipmentSearchQuery } from "../Domain/Shipment.fs.js";
import { toNullable, value as value_349, some, defaultArg } from "../fable_modules/fable-library.4.10.0/Option.js";
import { defaultOf } from "../fable_modules/FsToolkit.ErrorHandling.4.9.0/../../Pages/../fable_modules/fable-library.4.10.0/Util.js";
import { DateTimeOffset_fromString, String_defaultValue } from "../../CView.DTOs/PrimitiveTypes.fs.js";
import { createElement } from "react";
import { compare as compare_1, isNullOrWhiteSpace, join } from "../fable_modules/fable-library.4.10.0/String.js";
import { Interop_reactApi } from "../fable_modules/Feliz.2.6.0/./Interop.fs.js";
import { empty, map as map_2, singleton as singleton_1, append, delay, toList } from "../fable_modules/fable-library.4.10.0/Seq.js";
import { maxValue, minValue, toString as toString_1 } from "../fable_modules/fable-library.4.10.0/Date.js";
import { formatInt, toDateTimeOffsetInput } from "../Domain/Formatter.fs.js";
import { render as render_2 } from "../Components/Loader.fs.js";
import { render as render_3 } from "../Components/ShipmentInfo.fs.js";
import { defaultOf as defaultOf_2 } from "../fable_modules/Feliz.2.6.0/../fable-library.4.10.0/Util.js";

export class State extends Record {
    constructor(ShipmentSearchQuery, PreviousShipmentSearchQuery, ShipmentsDeferred, ExportShipments, OriginSearch, DestinationSearch, PortOfLadingSearch, PortOfDischargeSearch, ConsigneeSearch, ShipperSearch, Accounts, User, UserDataSources) {
        super();
        this.ShipmentSearchQuery = ShipmentSearchQuery;
        this.PreviousShipmentSearchQuery = PreviousShipmentSearchQuery;
        this.ShipmentsDeferred = ShipmentsDeferred;
        this.ExportShipments = ExportShipments;
        this.OriginSearch = OriginSearch;
        this.DestinationSearch = DestinationSearch;
        this.PortOfLadingSearch = PortOfLadingSearch;
        this.PortOfDischargeSearch = PortOfDischargeSearch;
        this.ConsigneeSearch = ConsigneeSearch;
        this.ShipperSearch = ShipperSearch;
        this.Accounts = Accounts;
        this.User = User;
        this.UserDataSources = UserDataSources;
    }
}

export function State_$reflection() {
    return record_type("CView.UI.Pages.Shipments.State", [], State, () => [["ShipmentSearchQuery", ShipmentSearchQuery_$reflection()], ["PreviousShipmentSearchQuery", ShipmentSearchQuery_$reflection()], ["ShipmentsDeferred", Deferred$1_$reflection(union_type("Microsoft.FSharp.Core.FSharpResult`2", [PagedDataResponse$1_$reflection(ShipmentSearchItem_$reflection()), string_type], FSharpResult$2, () => [[["ResultValue", PagedDataResponse$1_$reflection(ShipmentSearchItem_$reflection())]], [["ErrorValue", string_type]]]))], ["ExportShipments", Deferred$1_$reflection(union_type("Microsoft.FSharp.Core.FSharpResult`2", [unit_type, string_type], FSharpResult$2, () => [[["ResultValue", unit_type]], [["ErrorValue", string_type]]]))], ["OriginSearch", State_$reflection_1()], ["DestinationSearch", State_$reflection_1()], ["PortOfLadingSearch", State_$reflection_1()], ["PortOfDischargeSearch", State_$reflection_1()], ["ConsigneeSearch", State_$reflection_1()], ["ShipperSearch", State_$reflection_1()], ["Accounts", array_type(Account_$reflection())], ["User", User_$reflection()], ["UserDataSources", UserDataSources_$reflection()]]);
}

export class Intent extends Union {
    constructor(tag, fields) {
        super();
        this.tag = tag;
        this.fields = fields;
    }
    cases() {
        return ["ShipmentQueryChanged", "NoOp"];
    }
}

export function Intent_$reflection() {
    return union_type("CView.UI.Pages.Shipments.Intent", [], Intent, () => [[["Item", ShipmentSearchQuery_$reflection()]], []]);
}

export class Msg extends Union {
    constructor(tag, fields) {
        super();
        this.tag = tag;
        this.fields = fields;
    }
    cases() {
        return ["UpdateShipmentSearchQuery", "SearchShipments", "OriginSearchMsg", "SetSelectedOrigin", "DestinationSearchMsg", "SetSelectedDestination", "PortOfLadingSearchMsg", "SetSelectedPortOfLading", "PortOfDischargeSearchMsg", "SetSelectedPortOfDischarge", "ConsigneeSearchMsg", "SetSelectedConsignee", "ShipperSearchMsg", "SetSelectedShipper", "ClearSearch", "ExportShipments", "SetPageNumber"];
    }
}

export function Msg_$reflection() {
    return union_type("CView.UI.Pages.Shipments.Msg", [], Msg, () => [[["Item", lambda_type(ShipmentSearchQuery_$reflection(), ShipmentSearchQuery_$reflection())]], [["Item1", AsyncMsg$1_$reflection(union_type("Microsoft.FSharp.Core.FSharpResult`2", [PagedDataResponse$1_$reflection(ShipmentSearchItem_$reflection()), string_type], FSharpResult$2, () => [[["ResultValue", PagedDataResponse$1_$reflection(ShipmentSearchItem_$reflection())]], [["ErrorValue", string_type]]]))], ["Item2", bool_type]], [["Item", Msg_$reflection_1()]], [["Item", option_type(class_type("CView.UI.Models.IDataSourceItem"))]], [["Item", Msg_$reflection_1()]], [["Item", option_type(class_type("CView.UI.Models.IDataSourceItem"))]], [["Item", Msg_$reflection_1()]], [["Item", option_type(class_type("CView.UI.Models.IDataSourceItem"))]], [["Item", Msg_$reflection_1()]], [["Item", option_type(class_type("CView.UI.Models.IDataSourceItem"))]], [["Item", Msg_$reflection_1()]], [["Item", option_type(class_type("CView.UI.Models.IDataSourceItem"))]], [["Item", Msg_$reflection_1()]], [["Item", option_type(class_type("CView.UI.Models.IDataSourceItem"))]], [], [["Item", AsyncMsg$1_$reflection(union_type("Microsoft.FSharp.Core.FSharpResult`2", [unit_type, string_type], FSharpResult$2, () => [[["ResultValue", unit_type]], [["ErrorValue", string_type]]]))]], [["Item", int32_type]]]);
}

function hasPreviousPage(state) {
    return Deferred_exists((result) => {
        if (result.tag === 0) {
            return result.fields[0].Page > 1;
        }
        else {
            return false;
        }
    }, state.ShipmentsDeferred);
}

function hasNextPage(state) {
    return Deferred_exists((result) => {
        if (result.tag === 0) {
            const pagedData = result.fields[0];
            return compare(toInt64(fromInt32(pagedData.Page * pagedData.PageSize)), pagedData.TotalCount) < 0;
        }
        else {
            return false;
        }
    }, state.ShipmentsDeferred);
}

function serviceLevelSearch(state) {
    const serviceLevels = sortBy((x) => x.Name, state.UserDataSources.ServiceLevels, {
        Compare: comparePrimitives,
    });
    const matchValue = state.ShipmentSearchQuery.ModeOfTransport;
    switch (matchValue) {
        case "Air":
            return serviceLevels.filter((x_2) => x_2.IsAir);
        case "Ground":
            return serviceLevels.filter((x_3) => x_3.IsGround);
        case "Ocean":
            return serviceLevels.filter((x_4) => x_4.IsOcean);
        default:
            return new Array(0);
    }
}

export function init(accounts, user, shipmentSearchQuery, userDataSources) {
    const accountIds = map_1((dataSourceItem) => dataSourceItem.Id, accounts);
    const patternInput = init_1("Place Of Receipt", shipmentSearchQuery.PlaceOfReceiptName, (searchCriteria) => getLocations(new Location_Entity(0, []), new Location_LocationType(0, []), accountIds, searchCriteria));
    const patternInput_1 = init_1("Final Destination", shipmentSearchQuery.FinalDestinationName, (searchCriteria_1) => getLocations(new Location_Entity(0, []), new Location_LocationType(1, []), accountIds, searchCriteria_1));
    const patternInput_2 = init_1("Port Of Lading", shipmentSearchQuery.PortOfLadingName, (searchCriteria_2) => getLocations(new Location_Entity(0, []), new Location_LocationType(2, []), accountIds, searchCriteria_2));
    const patternInput_3 = init_1("Port Of Discharge", shipmentSearchQuery.PortOfDischargeName, (searchCriteria_3) => getLocations(new Location_Entity(0, []), new Location_LocationType(3, []), accountIds, searchCriteria_3));
    const patternInput_4 = init_1("Consignee", shipmentSearchQuery.Consignee, (searchCriteria_4) => getConsignees(new SearchType_Entity(0, []), accountIds, searchCriteria_4));
    const patternInput_5 = init_1("Shipper", shipmentSearchQuery.Shipper, (searchCriteria_5) => getShippers(new SearchType_Entity(0, []), accountIds, searchCriteria_5));
    return [new State(shipmentSearchQuery, shipmentSearchQuery, new Deferred$1(0, []), new Deferred$1(0, []), patternInput[0], patternInput_1[0], patternInput_2[0], patternInput_3[0], patternInput_4[0], patternInput_5[0], accounts, user, userDataSources), Cmd_batch(ofArray([singleton((dispatch) => {
        dispatch(new Msg(1, [new AsyncMsg$1(0, []), false]));
    }), Cmd_map((Item) => (new Msg(2, [Item])), patternInput[1]), Cmd_map((Item_1) => (new Msg(4, [Item_1])), patternInput_1[1]), Cmd_map((Item_2) => (new Msg(6, [Item_2])), patternInput_2[1]), Cmd_map((Item_3) => (new Msg(8, [Item_3])), patternInput_3[1]), Cmd_map((Item_4) => (new Msg(10, [Item_4])), patternInput_4[1]), Cmd_map((Item_5) => (new Msg(12, [Item_5])), patternInput_5[1])]))];
}

export function update(msg, state) {
    let bind$0040, bind$0040_1, bind$0040_2, bind$0040_3, bind$0040_4, bind$0040_5, bind$0040_6, bind$0040_7, bind$0040_8, bind$0040_9, x_7, x_5, x_3, value_3, x_12, x_10, x_8, value_7, bind$0040_10, x_17, x_15, x_13, value_11, x_22, x_20, x_18, value_15, bind$0040_11, x_27, x_25, x_23, value_19, x_32, x_30, x_28, value_23, bind$0040_12, x_37, x_35, x_33, value_27, x_42, x_40, x_38, value_31, bind$0040_13, x_45, x_43, bind$0040_14, x_48, x_46, bind$0040_15;
    switch (msg.tag) {
        case 1:
            if (msg.fields[0].tag === 1) {
                return [new State(state.ShipmentSearchQuery, msg.fields[1] ? state.PreviousShipmentSearchQuery : state.ShipmentSearchQuery, new Deferred$1(2, [msg.fields[0].fields[0]]), state.ExportShipments, state.OriginSearch, state.DestinationSearch, state.PortOfLadingSearch, state.PortOfDischargeSearch, state.ConsigneeSearch, state.ShipperSearch, state.Accounts, state.User, state.UserDataSources), (msg.fields[0].fields[0].tag === 1) ? Alert_error(Alert_title("Search Shipments", Alert_text(msg.fields[0].fields[0].fields[0]))) : Cmd_none(), new Intent(1, [])];
            }
            else if (equals(state.ShipmentsDeferred, new Deferred$1(1, [])) ? true : (state.Accounts.length === 0)) {
                return [state, Cmd_none(), new Intent(1, [])];
            }
            else {
                return [new State(state.ShipmentSearchQuery, state.PreviousShipmentSearchQuery, new Deferred$1(1, []), state.ExportShipments, state.OriginSearch, state.DestinationSearch, state.PortOfLadingSearch, state.PortOfDischargeSearch, state.ConsigneeSearch, state.ShipperSearch, state.Accounts, state.User, state.UserDataSources), Cmd_OfAsyncWith_perform((computation) => {
                    startImmediate(computation);
                }, search, msg.fields[1] ? ((bind$0040 = state.PreviousShipmentSearchQuery, new ShipmentSearchQuery(bind$0040.TrackingNumber, bind$0040.ModeOfTransport, bind$0040.ServiceLevel, bind$0040.PlaceOfReceiptCode, bind$0040.PlaceOfReceiptName, bind$0040.FinalDestinationCode, bind$0040.FinalDestinationName, bind$0040.PortOfLadingCode, bind$0040.PortOfLadingName, bind$0040.PortOfDischargeCode, bind$0040.PortOfDischargeName, map_1((x) => x.Id, state.Accounts), bind$0040.HasException, bind$0040.HasPotentialException, bind$0040.SelectedShipmentDate, bind$0040.ShipmentStatus, bind$0040.Shipper, bind$0040.Consignee, bind$0040.ShipmentFromDate, bind$0040.ShipmentToDate, bind$0040.Page, bind$0040.PageSize))) : ((bind$0040_1 = state.ShipmentSearchQuery, new ShipmentSearchQuery(bind$0040_1.TrackingNumber, bind$0040_1.ModeOfTransport, bind$0040_1.ServiceLevel, bind$0040_1.PlaceOfReceiptCode, bind$0040_1.PlaceOfReceiptName, bind$0040_1.FinalDestinationCode, bind$0040_1.FinalDestinationName, bind$0040_1.PortOfLadingCode, bind$0040_1.PortOfLadingName, bind$0040_1.PortOfDischargeCode, bind$0040_1.PortOfDischargeName, map_1((x_1) => x_1.Id, state.Accounts), bind$0040_1.HasException, bind$0040_1.HasPotentialException, bind$0040_1.SelectedShipmentDate, bind$0040_1.ShipmentStatus, bind$0040_1.Shipper, bind$0040_1.Consignee, bind$0040_1.ShipmentFromDate, bind$0040_1.ShipmentToDate, 1, bind$0040_1.PageSize))), (arg) => (new Msg(1, [new AsyncMsg$1(1, [arg]), msg.fields[1]]))), new Intent(1, [])];
            }
        case 14: {
            const newState_1 = new State(defaultShipmentSearchQuery, state.PreviousShipmentSearchQuery, state.ShipmentsDeferred, state.ExportShipments, (bind$0040_2 = state.OriginSearch, new State_1(bind$0040_2.Name, bind$0040_2.Disabled, bind$0040_2.Search, bind$0040_2.SearchDeferred, bind$0040_2.IsModalActive, bind$0040_2.SearchCriteria, void 0)), (bind$0040_3 = state.DestinationSearch, new State_1(bind$0040_3.Name, bind$0040_3.Disabled, bind$0040_3.Search, bind$0040_3.SearchDeferred, bind$0040_3.IsModalActive, bind$0040_3.SearchCriteria, void 0)), (bind$0040_4 = state.PortOfLadingSearch, new State_1(bind$0040_4.Name, bind$0040_4.Disabled, bind$0040_4.Search, bind$0040_4.SearchDeferred, bind$0040_4.IsModalActive, bind$0040_4.SearchCriteria, void 0)), (bind$0040_5 = state.PortOfDischargeSearch, new State_1(bind$0040_5.Name, bind$0040_5.Disabled, bind$0040_5.Search, bind$0040_5.SearchDeferred, bind$0040_5.IsModalActive, bind$0040_5.SearchCriteria, void 0)), (bind$0040_6 = state.ConsigneeSearch, new State_1(bind$0040_6.Name, bind$0040_6.Disabled, bind$0040_6.Search, bind$0040_6.SearchDeferred, bind$0040_6.IsModalActive, bind$0040_6.SearchCriteria, void 0)), (bind$0040_7 = state.ShipperSearch, new State_1(bind$0040_7.Name, bind$0040_7.Disabled, bind$0040_7.Search, bind$0040_7.SearchDeferred, bind$0040_7.IsModalActive, bind$0040_7.SearchCriteria, void 0)), state.Accounts, state.User, state.UserDataSources);
            return [newState_1, singleton((dispatch) => {
                dispatch(new Msg(1, [new AsyncMsg$1(0, []), false]));
            }), new Intent(0, [newState_1.ShipmentSearchQuery])];
        }
        case 15:
            if (msg.fields[0].tag === 1) {
                return [new State(state.ShipmentSearchQuery, state.PreviousShipmentSearchQuery, state.ShipmentsDeferred, new Deferred$1(2, [msg.fields[0].fields[0]]), state.OriginSearch, state.DestinationSearch, state.PortOfLadingSearch, state.PortOfDischargeSearch, state.ConsigneeSearch, state.ShipperSearch, state.Accounts, state.User, state.UserDataSources), (msg.fields[0].fields[0].tag === 1) ? Alert_error(Alert_title("Export Track And Trace", Alert_text(msg.fields[0].fields[0].fields[0]))) : Cmd_none(), new Intent(1, [])];
            }
            else if (equals(state.ExportShipments, new Deferred$1(1, [])) ? true : (state.Accounts.length === 0)) {
                return [state, Cmd_none(), new Intent(1, [])];
            }
            else {
                return [new State(state.ShipmentSearchQuery, state.PreviousShipmentSearchQuery, state.ShipmentsDeferred, new Deferred$1(1, []), state.OriginSearch, state.DestinationSearch, state.PortOfLadingSearch, state.PortOfDischargeSearch, state.ConsigneeSearch, state.ShipperSearch, state.Accounts, state.User, state.UserDataSources), Cmd_OfAsyncWith_perform((computation_1) => {
                    startImmediate(computation_1);
                }, export$, (bind$0040_8 = state.PreviousShipmentSearchQuery, new ShipmentSearchQuery(bind$0040_8.TrackingNumber, bind$0040_8.ModeOfTransport, bind$0040_8.ServiceLevel, bind$0040_8.PlaceOfReceiptCode, bind$0040_8.PlaceOfReceiptName, bind$0040_8.FinalDestinationCode, bind$0040_8.FinalDestinationName, bind$0040_8.PortOfLadingCode, bind$0040_8.PortOfLadingName, bind$0040_8.PortOfDischargeCode, bind$0040_8.PortOfDischargeName, map_1((x_2) => x_2.Id, state.Accounts), bind$0040_8.HasException, bind$0040_8.HasPotentialException, bind$0040_8.SelectedShipmentDate, bind$0040_8.ShipmentStatus, bind$0040_8.Shipper, bind$0040_8.Consignee, bind$0040_8.ShipmentFromDate, bind$0040_8.ShipmentToDate, bind$0040_8.Page, bind$0040_8.PageSize)), (arg_2) => (new Msg(15, [new AsyncMsg$1(1, [arg_2])]))), new Intent(1, [])];
            }
        case 3:
            return [new State((bind$0040_9 = state.ShipmentSearchQuery, new ShipmentSearchQuery(bind$0040_9.TrackingNumber, bind$0040_9.ModeOfTransport, bind$0040_9.ServiceLevel, defaultArg((x_7 = ((x_5 = msg.fields[0], (x_5 == null) ? void 0 : ((x_3 = x_5, (value_3 = x_3.Value, (value_3 === defaultOf()) ? void 0 : some(value_3)))))), (x_7 == null) ? void 0 : toString(value_349(x_7))), String_defaultValue()), defaultArg((x_12 = ((x_10 = msg.fields[0], (x_10 == null) ? void 0 : ((x_8 = x_10, (value_7 = x_8.DisplayValue, (value_7 === defaultOf()) ? void 0 : value_7))))), (x_12 == null) ? void 0 : x_12), String_defaultValue()), bind$0040_9.FinalDestinationCode, bind$0040_9.FinalDestinationName, bind$0040_9.PortOfLadingCode, bind$0040_9.PortOfLadingName, bind$0040_9.PortOfDischargeCode, bind$0040_9.PortOfDischargeName, bind$0040_9.AccountIds, bind$0040_9.HasException, bind$0040_9.HasPotentialException, bind$0040_9.SelectedShipmentDate, bind$0040_9.ShipmentStatus, bind$0040_9.Shipper, bind$0040_9.Consignee, bind$0040_9.ShipmentFromDate, bind$0040_9.ShipmentToDate, bind$0040_9.Page, bind$0040_9.PageSize)), state.PreviousShipmentSearchQuery, state.ShipmentsDeferred, state.ExportShipments, state.OriginSearch, state.DestinationSearch, state.PortOfLadingSearch, state.PortOfDischargeSearch, state.ConsigneeSearch, state.ShipperSearch, state.Accounts, state.User, state.UserDataSources), Cmd_none(), new Intent(1, [])];
        case 2: {
            const patternInput = update_1(msg.fields[0], state.OriginSearch);
            const componentIntent = patternInput[2];
            const componentCmd = patternInput[1];
            return [new State(state.ShipmentSearchQuery, state.PreviousShipmentSearchQuery, state.ShipmentsDeferred, state.ExportShipments, patternInput[0], state.DestinationSearch, state.PortOfLadingSearch, state.PortOfDischargeSearch, state.ConsigneeSearch, state.ShipperSearch, state.Accounts, state.User, state.UserDataSources), (componentIntent.tag === 1) ? Cmd_map((Item_4) => (new Msg(2, [Item_4])), componentCmd) : Cmd_batch(ofArray([Cmd_map((Item_3) => (new Msg(2, [Item_3])), componentCmd), singleton((dispatch_1) => {
                dispatch_1(new Msg(3, [componentIntent.fields[0]]));
            })])), new Intent(1, [])];
        }
        case 5:
            return [new State((bind$0040_10 = state.ShipmentSearchQuery, new ShipmentSearchQuery(bind$0040_10.TrackingNumber, bind$0040_10.ModeOfTransport, bind$0040_10.ServiceLevel, bind$0040_10.PlaceOfReceiptCode, bind$0040_10.PlaceOfReceiptName, defaultArg((x_17 = ((x_15 = msg.fields[0], (x_15 == null) ? void 0 : ((x_13 = x_15, (value_11 = x_13.Value, (value_11 === defaultOf()) ? void 0 : some(value_11)))))), (x_17 == null) ? void 0 : toString(value_349(x_17))), String_defaultValue()), defaultArg((x_22 = ((x_20 = msg.fields[0], (x_20 == null) ? void 0 : ((x_18 = x_20, (value_15 = x_18.DisplayValue, (value_15 === defaultOf()) ? void 0 : value_15))))), (x_22 == null) ? void 0 : x_22), String_defaultValue()), bind$0040_10.PortOfLadingCode, bind$0040_10.PortOfLadingName, bind$0040_10.PortOfDischargeCode, bind$0040_10.PortOfDischargeName, bind$0040_10.AccountIds, bind$0040_10.HasException, bind$0040_10.HasPotentialException, bind$0040_10.SelectedShipmentDate, bind$0040_10.ShipmentStatus, bind$0040_10.Shipper, bind$0040_10.Consignee, bind$0040_10.ShipmentFromDate, bind$0040_10.ShipmentToDate, bind$0040_10.Page, bind$0040_10.PageSize)), state.PreviousShipmentSearchQuery, state.ShipmentsDeferred, state.ExportShipments, state.OriginSearch, state.DestinationSearch, state.PortOfLadingSearch, state.PortOfDischargeSearch, state.ConsigneeSearch, state.ShipperSearch, state.Accounts, state.User, state.UserDataSources), Cmd_none(), new Intent(1, [])];
        case 4: {
            const patternInput_1 = update_1(msg.fields[0], state.DestinationSearch);
            const componentIntent_1 = patternInput_1[2];
            const componentCmd_1 = patternInput_1[1];
            return [new State(state.ShipmentSearchQuery, state.PreviousShipmentSearchQuery, state.ShipmentsDeferred, state.ExportShipments, state.OriginSearch, patternInput_1[0], state.PortOfLadingSearch, state.PortOfDischargeSearch, state.ConsigneeSearch, state.ShipperSearch, state.Accounts, state.User, state.UserDataSources), (componentIntent_1.tag === 1) ? Cmd_map((Item_6) => (new Msg(4, [Item_6])), componentCmd_1) : Cmd_batch(ofArray([Cmd_map((Item_5) => (new Msg(4, [Item_5])), componentCmd_1), singleton((dispatch_2) => {
                dispatch_2(new Msg(5, [componentIntent_1.fields[0]]));
            })])), new Intent(1, [])];
        }
        case 7:
            return [new State((bind$0040_11 = state.ShipmentSearchQuery, new ShipmentSearchQuery(bind$0040_11.TrackingNumber, bind$0040_11.ModeOfTransport, bind$0040_11.ServiceLevel, bind$0040_11.PlaceOfReceiptCode, bind$0040_11.PlaceOfReceiptName, bind$0040_11.FinalDestinationCode, bind$0040_11.FinalDestinationName, defaultArg((x_27 = ((x_25 = msg.fields[0], (x_25 == null) ? void 0 : ((x_23 = x_25, (value_19 = x_23.Value, (value_19 === defaultOf()) ? void 0 : some(value_19)))))), (x_27 == null) ? void 0 : toString(value_349(x_27))), String_defaultValue()), defaultArg((x_32 = ((x_30 = msg.fields[0], (x_30 == null) ? void 0 : ((x_28 = x_30, (value_23 = x_28.DisplayValue, (value_23 === defaultOf()) ? void 0 : value_23))))), (x_32 == null) ? void 0 : x_32), String_defaultValue()), bind$0040_11.PortOfDischargeCode, bind$0040_11.PortOfDischargeName, bind$0040_11.AccountIds, bind$0040_11.HasException, bind$0040_11.HasPotentialException, bind$0040_11.SelectedShipmentDate, bind$0040_11.ShipmentStatus, bind$0040_11.Shipper, bind$0040_11.Consignee, bind$0040_11.ShipmentFromDate, bind$0040_11.ShipmentToDate, bind$0040_11.Page, bind$0040_11.PageSize)), state.PreviousShipmentSearchQuery, state.ShipmentsDeferred, state.ExportShipments, state.OriginSearch, state.DestinationSearch, state.PortOfLadingSearch, state.PortOfDischargeSearch, state.ConsigneeSearch, state.ShipperSearch, state.Accounts, state.User, state.UserDataSources), Cmd_none(), new Intent(1, [])];
        case 6: {
            const patternInput_2 = update_1(msg.fields[0], state.PortOfLadingSearch);
            const componentIntent_2 = patternInput_2[2];
            const componentCmd_2 = patternInput_2[1];
            return [new State(state.ShipmentSearchQuery, state.PreviousShipmentSearchQuery, state.ShipmentsDeferred, state.ExportShipments, state.OriginSearch, state.DestinationSearch, patternInput_2[0], state.PortOfDischargeSearch, state.ConsigneeSearch, state.ShipperSearch, state.Accounts, state.User, state.UserDataSources), (componentIntent_2.tag === 1) ? Cmd_map((Item_8) => (new Msg(6, [Item_8])), componentCmd_2) : Cmd_batch(ofArray([Cmd_map((Item_7) => (new Msg(6, [Item_7])), componentCmd_2), singleton((dispatch_3) => {
                dispatch_3(new Msg(7, [componentIntent_2.fields[0]]));
            })])), new Intent(1, [])];
        }
        case 9:
            return [new State((bind$0040_12 = state.ShipmentSearchQuery, new ShipmentSearchQuery(bind$0040_12.TrackingNumber, bind$0040_12.ModeOfTransport, bind$0040_12.ServiceLevel, bind$0040_12.PlaceOfReceiptCode, bind$0040_12.PlaceOfReceiptName, bind$0040_12.FinalDestinationCode, bind$0040_12.FinalDestinationName, bind$0040_12.PortOfLadingCode, bind$0040_12.PortOfLadingName, defaultArg((x_37 = ((x_35 = msg.fields[0], (x_35 == null) ? void 0 : ((x_33 = x_35, (value_27 = x_33.Value, (value_27 === defaultOf()) ? void 0 : some(value_27)))))), (x_37 == null) ? void 0 : toString(value_349(x_37))), String_defaultValue()), defaultArg((x_42 = ((x_40 = msg.fields[0], (x_40 == null) ? void 0 : ((x_38 = x_40, (value_31 = x_38.DisplayValue, (value_31 === defaultOf()) ? void 0 : value_31))))), (x_42 == null) ? void 0 : x_42), String_defaultValue()), bind$0040_12.AccountIds, bind$0040_12.HasException, bind$0040_12.HasPotentialException, bind$0040_12.SelectedShipmentDate, bind$0040_12.ShipmentStatus, bind$0040_12.Shipper, bind$0040_12.Consignee, bind$0040_12.ShipmentFromDate, bind$0040_12.ShipmentToDate, bind$0040_12.Page, bind$0040_12.PageSize)), state.PreviousShipmentSearchQuery, state.ShipmentsDeferred, state.ExportShipments, state.OriginSearch, state.DestinationSearch, state.PortOfLadingSearch, state.PortOfDischargeSearch, state.ConsigneeSearch, state.ShipperSearch, state.Accounts, state.User, state.UserDataSources), Cmd_none(), new Intent(1, [])];
        case 8: {
            const patternInput_3 = update_1(msg.fields[0], state.PortOfDischargeSearch);
            const componentIntent_3 = patternInput_3[2];
            const componentCmd_3 = patternInput_3[1];
            return [new State(state.ShipmentSearchQuery, state.PreviousShipmentSearchQuery, state.ShipmentsDeferred, state.ExportShipments, state.OriginSearch, state.DestinationSearch, state.PortOfLadingSearch, patternInput_3[0], state.ConsigneeSearch, state.ShipperSearch, state.Accounts, state.User, state.UserDataSources), (componentIntent_3.tag === 1) ? Cmd_map((Item_10) => (new Msg(8, [Item_10])), componentCmd_3) : Cmd_batch(ofArray([Cmd_map((Item_9) => (new Msg(8, [Item_9])), componentCmd_3), singleton((dispatch_4) => {
                dispatch_4(new Msg(9, [componentIntent_3.fields[0]]));
            })])), new Intent(1, [])];
        }
        case 11:
            return [new State((bind$0040_13 = state.ShipmentSearchQuery, new ShipmentSearchQuery(bind$0040_13.TrackingNumber, bind$0040_13.ModeOfTransport, bind$0040_13.ServiceLevel, bind$0040_13.PlaceOfReceiptCode, bind$0040_13.PlaceOfReceiptName, bind$0040_13.FinalDestinationCode, bind$0040_13.FinalDestinationName, bind$0040_13.PortOfLadingCode, bind$0040_13.PortOfLadingName, bind$0040_13.PortOfDischargeCode, bind$0040_13.PortOfDischargeName, bind$0040_13.AccountIds, bind$0040_13.HasException, bind$0040_13.HasPotentialException, bind$0040_13.SelectedShipmentDate, bind$0040_13.ShipmentStatus, bind$0040_13.Shipper, defaultArg((x_45 = msg.fields[0], (x_45 == null) ? void 0 : ((x_43 = x_45, x_43.DisplayValue))), String_defaultValue()), bind$0040_13.ShipmentFromDate, bind$0040_13.ShipmentToDate, bind$0040_13.Page, bind$0040_13.PageSize)), state.PreviousShipmentSearchQuery, state.ShipmentsDeferred, state.ExportShipments, state.OriginSearch, state.DestinationSearch, state.PortOfLadingSearch, state.PortOfDischargeSearch, state.ConsigneeSearch, state.ShipperSearch, state.Accounts, state.User, state.UserDataSources), Cmd_none(), new Intent(1, [])];
        case 10: {
            const patternInput_4 = update_1(msg.fields[0], state.ConsigneeSearch);
            const componentIntent_4 = patternInput_4[2];
            const componentCmd_4 = patternInput_4[1];
            return [new State(state.ShipmentSearchQuery, state.PreviousShipmentSearchQuery, state.ShipmentsDeferred, state.ExportShipments, state.OriginSearch, state.DestinationSearch, state.PortOfLadingSearch, state.PortOfDischargeSearch, patternInput_4[0], state.ShipperSearch, state.Accounts, state.User, state.UserDataSources), (componentIntent_4.tag === 1) ? Cmd_map((Item_12) => (new Msg(10, [Item_12])), componentCmd_4) : Cmd_batch(ofArray([Cmd_map((Item_11) => (new Msg(10, [Item_11])), componentCmd_4), singleton((dispatch_5) => {
                dispatch_5(new Msg(11, [componentIntent_4.fields[0]]));
            })])), new Intent(1, [])];
        }
        case 13:
            return [new State((bind$0040_14 = state.ShipmentSearchQuery, new ShipmentSearchQuery(bind$0040_14.TrackingNumber, bind$0040_14.ModeOfTransport, bind$0040_14.ServiceLevel, bind$0040_14.PlaceOfReceiptCode, bind$0040_14.PlaceOfReceiptName, bind$0040_14.FinalDestinationCode, bind$0040_14.FinalDestinationName, bind$0040_14.PortOfLadingCode, bind$0040_14.PortOfLadingName, bind$0040_14.PortOfDischargeCode, bind$0040_14.PortOfDischargeName, bind$0040_14.AccountIds, bind$0040_14.HasException, bind$0040_14.HasPotentialException, bind$0040_14.SelectedShipmentDate, bind$0040_14.ShipmentStatus, defaultArg((x_48 = msg.fields[0], (x_48 == null) ? void 0 : ((x_46 = x_48, x_46.DisplayValue))), String_defaultValue()), bind$0040_14.Consignee, bind$0040_14.ShipmentFromDate, bind$0040_14.ShipmentToDate, bind$0040_14.Page, bind$0040_14.PageSize)), state.PreviousShipmentSearchQuery, state.ShipmentsDeferred, state.ExportShipments, state.OriginSearch, state.DestinationSearch, state.PortOfLadingSearch, state.PortOfDischargeSearch, state.ConsigneeSearch, state.ShipperSearch, state.Accounts, state.User, state.UserDataSources), Cmd_none(), new Intent(1, [])];
        case 12: {
            const patternInput_5 = update_1(msg.fields[0], state.ShipperSearch);
            const componentIntent_5 = patternInput_5[2];
            const componentCmd_5 = patternInput_5[1];
            return [new State(state.ShipmentSearchQuery, state.PreviousShipmentSearchQuery, state.ShipmentsDeferred, state.ExportShipments, state.OriginSearch, state.DestinationSearch, state.PortOfLadingSearch, state.PortOfDischargeSearch, state.ConsigneeSearch, patternInput_5[0], state.Accounts, state.User, state.UserDataSources), (componentIntent_5.tag === 1) ? Cmd_map((Item_14) => (new Msg(12, [Item_14])), componentCmd_5) : Cmd_batch(ofArray([Cmd_map((Item_13) => (new Msg(12, [Item_13])), componentCmd_5), singleton((dispatch_6) => {
                dispatch_6(new Msg(13, [componentIntent_5.fields[0]]));
            })])), new Intent(1, [])];
        }
        case 16:
            return [new State(state.ShipmentSearchQuery, (bind$0040_15 = state.PreviousShipmentSearchQuery, new ShipmentSearchQuery(bind$0040_15.TrackingNumber, bind$0040_15.ModeOfTransport, bind$0040_15.ServiceLevel, bind$0040_15.PlaceOfReceiptCode, bind$0040_15.PlaceOfReceiptName, bind$0040_15.FinalDestinationCode, bind$0040_15.FinalDestinationName, bind$0040_15.PortOfLadingCode, bind$0040_15.PortOfLadingName, bind$0040_15.PortOfDischargeCode, bind$0040_15.PortOfDischargeName, bind$0040_15.AccountIds, bind$0040_15.HasException, bind$0040_15.HasPotentialException, bind$0040_15.SelectedShipmentDate, bind$0040_15.ShipmentStatus, bind$0040_15.Shipper, bind$0040_15.Consignee, bind$0040_15.ShipmentFromDate, bind$0040_15.ShipmentToDate, msg.fields[0], bind$0040_15.PageSize)), state.ShipmentsDeferred, state.ExportShipments, state.OriginSearch, state.DestinationSearch, state.PortOfLadingSearch, state.PortOfDischargeSearch, state.ConsigneeSearch, state.ShipperSearch, state.Accounts, state.User, state.UserDataSources), singleton((dispatch_7) => {
                dispatch_7(new Msg(1, [new AsyncMsg$1(0, []), true]));
            }), new Intent(1, [])];
        default:
            return [new State(msg.fields[0](state.ShipmentSearchQuery), state.PreviousShipmentSearchQuery, state.ShipmentsDeferred, state.ExportShipments, state.OriginSearch, state.DestinationSearch, state.PortOfLadingSearch, state.PortOfDischargeSearch, state.ConsigneeSearch, state.ShipperSearch, state.Accounts, state.User, state.UserDataSources), Cmd_none(), new Intent(1, [])];
    }
}

export function render(state, dispatch) {
    let elems_25, elems_1, elems, value_24, elems_4, elems_3, value_41, elems_2, elems_7, elems_5, elems_6, elems_8, elems_9, elems_10, elems_11, elems_14, elems_13, value_135, elems_12, elems_15, value_167, elems_16, value_190, elems_17, elems_18, elems_21, elems_20, value_223, elems_19, elems_24, elems_23, value_251, elems_22, elems_33, elems_32, elems_31, elems_26, elems_27, matchValue_8, elems_30, elems_37, elems_36;
    const divider = createElement("hr", {
        className: join(" ", ["my-4"]),
    });
    let searchControls;
    const labelCss = ofArray(["is-block", "mb-1", "has-text-weight-semibold"]);
    const inputCss = ofArray(["input", "is-small", "is-fullwidth"]);
    const selectContainerCss = ofArray(["select", "is-small", "is-fullwidth"]);
    searchControls = createElement("form", createObj(ofArray([["onSubmit", (e) => {
        e.preventDefault();
        if (state.Accounts.length === 0) {
        }
        else {
            dispatch(new Msg(1, [new AsyncMsg$1(0, []), false]));
        }
    }], ["className", join(" ", ["box"])], ["style", {
        minWidth: 300,
    }], (elems_25 = [createElement("div", createObj(ofArray([["className", join(" ", ["block"])], (elems_1 = [createElement("div", createObj(ofArray([["className", join(" ", ["is-flex", "is-justify-content-space-between", "is-align-items-center", "mb-1"])], (elems = [createElement("label", {
        className: join(" ", ["has-text-weight-semibold"]),
        for: "trackingNumber",
        children: "Tracking Number",
    })], ["children", Interop_reactApi.Children.toArray(Array.from(elems))])]))), createElement("input", createObj(ofArray([["className", join(" ", inputCss)], ["id", "trackingNumber"], ["name", "trackingNumber"], ["type", "text"], ["maxLength", 50], ["placeholder", "Tracking Number..."], (value_24 = state.ShipmentSearchQuery.TrackingNumber, ["ref", (e_1) => {
        if (!(e_1 == null) && !equals(e_1.value, value_24)) {
            e_1.value = value_24;
        }
    }]), ["onChange", (ev) => {
        const value_26 = ev.target.value;
        dispatch(new Msg(0, [(query) => (new ShipmentSearchQuery(value_26, query.ModeOfTransport, query.ServiceLevel, query.PlaceOfReceiptCode, query.PlaceOfReceiptName, query.FinalDestinationCode, query.FinalDestinationName, query.PortOfLadingCode, query.PortOfLadingName, query.PortOfDischargeCode, query.PortOfDischargeName, query.AccountIds, query.HasException, query.HasPotentialException, query.SelectedShipmentDate, query.ShipmentStatus, query.Shipper, query.Consignee, query.ShipmentFromDate, query.ShipmentToDate, query.Page, query.PageSize))]));
    }]])))], ["children", Interop_reactApi.Children.toArray(Array.from(elems_1))])]))), createElement("div", createObj(ofArray([["className", join(" ", ["block"])], (elems_4 = [createElement("label", {
        className: join(" ", labelCss),
        for: "status",
        children: "Status",
    }), createElement("div", createObj(ofArray([["className", join(" ", selectContainerCss)], (elems_3 = [createElement("select", createObj(ofArray([["className", join(" ", [])], ["id", "status"], ["name", "status"], (value_41 = state.ShipmentSearchQuery.ShipmentStatus, ["ref", (e_2) => {
        if (!(e_2 == null) && !equals(e_2.value, value_41)) {
            e_2.value = value_41;
        }
    }]), (elems_2 = toList(delay(() => append(singleton_1(createElement("option", {
        value: "Any",
        children: "Any",
    })), delay(() => map_2((status) => createElement("option", {
        value: status.ShipmentStatus,
        children: status.ShipmentStatus,
    }), state.UserDataSources.ShipmentStatuses))))), ["children", Interop_reactApi.Children.toArray(Array.from(elems_2))]), ["onChange", (e_3) => {
        const element = e_3.target;
        dispatch(new Msg(0, [(query_1) => (new ShipmentSearchQuery(query_1.TrackingNumber, query_1.ModeOfTransport, query_1.ServiceLevel, query_1.PlaceOfReceiptCode, query_1.PlaceOfReceiptName, query_1.FinalDestinationCode, query_1.FinalDestinationName, query_1.PortOfLadingCode, query_1.PortOfLadingName, query_1.PortOfDischargeCode, query_1.PortOfDischargeName, query_1.AccountIds, query_1.HasException, query_1.HasPotentialException, query_1.SelectedShipmentDate, element.value, query_1.Shipper, query_1.Consignee, query_1.ShipmentFromDate, query_1.ShipmentToDate, query_1.Page, query_1.PageSize))]));
    }]])))], ["children", Interop_reactApi.Children.toArray(Array.from(elems_3))])])))], ["children", Interop_reactApi.Children.toArray(Array.from(elems_4))])]))), createElement("div", createObj(ofArray([["className", join(" ", ["block"])], (elems_7 = [createElement("div", createObj(ofArray([["className", join(" ", ["is-flex", "is-align-items-center", "mb-2"])], (elems_5 = [createElement("input", {
        className: join(" ", ["is-clickable"]),
        id: "Delayed",
        name: "Delayed",
        type: "checkbox",
        checked: state.ShipmentSearchQuery.HasException,
        onChange: (ev_1) => {
            const value_65 = ev_1.target.checked;
            dispatch(new Msg(0, [(query_2) => (new ShipmentSearchQuery(query_2.TrackingNumber, query_2.ModeOfTransport, query_2.ServiceLevel, query_2.PlaceOfReceiptCode, query_2.PlaceOfReceiptName, query_2.FinalDestinationCode, query_2.FinalDestinationName, query_2.PortOfLadingCode, query_2.PortOfLadingName, query_2.PortOfDischargeCode, query_2.PortOfDischargeName, query_2.AccountIds, value_65, query_2.HasPotentialException, query_2.SelectedShipmentDate, query_2.ShipmentStatus, query_2.Shipper, query_2.Consignee, query_2.ShipmentFromDate, query_2.ShipmentToDate, query_2.Page, query_2.PageSize))]));
        },
    }), createElement("label", {
        className: join(" ", ["is-clickable", "ml-2", "has-text-weight-semibold"]),
        for: "Delayed",
        children: "Delayed",
    })], ["children", Interop_reactApi.Children.toArray(Array.from(elems_5))])]))), createElement("div", createObj(ofArray([["className", join(" ", ["is-flex", "is-align-items-center"])], (elems_6 = [createElement("input", {
        className: join(" ", ["is-clickable"]),
        id: "PotentialDelays",
        name: "PotentialDelays",
        type: "checkbox",
        checked: state.ShipmentSearchQuery.HasPotentialException,
        onChange: (ev_2) => {
            const value_82 = ev_2.target.checked;
            dispatch(new Msg(0, [(query_3) => (new ShipmentSearchQuery(query_3.TrackingNumber, query_3.ModeOfTransport, query_3.ServiceLevel, query_3.PlaceOfReceiptCode, query_3.PlaceOfReceiptName, query_3.FinalDestinationCode, query_3.FinalDestinationName, query_3.PortOfLadingCode, query_3.PortOfLadingName, query_3.PortOfDischargeCode, query_3.PortOfDischargeName, query_3.AccountIds, query_3.HasException, value_82, query_3.SelectedShipmentDate, query_3.ShipmentStatus, query_3.Shipper, query_3.Consignee, query_3.ShipmentFromDate, query_3.ShipmentToDate, query_3.Page, query_3.PageSize))]));
        },
    }), createElement("label", {
        className: join(" ", ["is-clickable", "ml-2", "has-text-weight-semibold"]),
        for: "PotentialDelays",
        children: "Potential Delays",
    })], ["children", Interop_reactApi.Children.toArray(Array.from(elems_6))])])))], ["children", Interop_reactApi.Children.toArray(Array.from(elems_7))])]))), createElement("div", createObj(ofArray([["className", join(" ", ["block"])], ["onKeyPress", (keyPress) => {
        if (keyPress.key === "Enter") {
            keyPress.preventDefault();
        }
    }], (elems_8 = [createElement("label", {
        className: join(" ", labelCss),
        for: "origin",
        children: "Place Of Receipt",
    }), render_1(state.OriginSearch, (arg) => {
        dispatch(new Msg(2, [arg]));
    })], ["children", Interop_reactApi.Children.toArray(Array.from(elems_8))])]))), createElement("div", createObj(ofArray([["className", join(" ", ["block"])], ["onKeyPress", (keyPress_1) => {
        if (keyPress_1.key === "Enter") {
            keyPress_1.preventDefault();
        }
    }], (elems_9 = [createElement("label", {
        className: join(" ", labelCss),
        for: "portOfLading",
        children: "Port Of Lading",
    }), render_1(state.PortOfLadingSearch, (arg_1) => {
        dispatch(new Msg(6, [arg_1]));
    })], ["children", Interop_reactApi.Children.toArray(Array.from(elems_9))])]))), createElement("div", createObj(ofArray([["className", join(" ", ["block"])], ["onKeyPress", (keyPress_2) => {
        if (keyPress_2.key === "Enter") {
            keyPress_2.preventDefault();
        }
    }], (elems_10 = [createElement("label", {
        className: join(" ", labelCss),
        for: "portOfDischarge",
        children: "Port Of Discharge",
    }), render_1(state.PortOfDischargeSearch, (arg_2) => {
        dispatch(new Msg(8, [arg_2]));
    })], ["children", Interop_reactApi.Children.toArray(Array.from(elems_10))])]))), createElement("div", createObj(ofArray([["className", join(" ", ["block"])], ["onKeyPress", (keyPress_3) => {
        if (keyPress_3.key === "Enter") {
            keyPress_3.preventDefault();
        }
    }], (elems_11 = [createElement("label", {
        className: join(" ", labelCss),
        for: "destination",
        children: "Final Destination",
    }), render_1(state.DestinationSearch, (arg_3) => {
        dispatch(new Msg(4, [arg_3]));
    })], ["children", Interop_reactApi.Children.toArray(Array.from(elems_11))])]))), createElement("div", createObj(ofArray([["className", join(" ", ["block"])], (elems_14 = [createElement("label", {
        className: join(" ", labelCss),
        for: "shipmentDate",
        children: "Shipment Date",
    }), createElement("div", createObj(ofArray([["className", join(" ", selectContainerCss)], (elems_13 = [createElement("select", createObj(ofArray([["className", join(" ", [])], ["id", "shipmentDate"], ["name", "shipmentDate"], (value_135 = state.ShipmentSearchQuery.SelectedShipmentDate, ["ref", (e_4) => {
        if (!(e_4 == null) && !equals(e_4.value, value_135)) {
            e_4.value = value_135;
        }
    }]), (elems_12 = toList(delay(() => append(singleton_1(createElement("option", {
        value: "",
        children: "Select...",
    })), delay(() => map_2((shipmentDate) => createElement("option", {
        value: shipmentDate.ShipmentDate,
        children: shipmentDate.ShipmentDate,
    }), state.UserDataSources.ShipmentDates))))), ["children", Interop_reactApi.Children.toArray(Array.from(elems_12))]), ["onChange", (e_5) => {
        const element_1 = e_5.target;
        dispatch(new Msg(0, [(query_4) => (isNullOrWhiteSpace(element_1.value) ? (new ShipmentSearchQuery(query_4.TrackingNumber, query_4.ModeOfTransport, query_4.ServiceLevel, query_4.PlaceOfReceiptCode, query_4.PlaceOfReceiptName, query_4.FinalDestinationCode, query_4.FinalDestinationName, query_4.PortOfLadingCode, query_4.PortOfLadingName, query_4.PortOfDischargeCode, query_4.PortOfDischargeName, query_4.AccountIds, query_4.HasException, query_4.HasPotentialException, element_1.value, query_4.ShipmentStatus, query_4.Shipper, query_4.Consignee, defaultOf_1(), defaultOf_1(), query_4.Page, query_4.PageSize)) : (new ShipmentSearchQuery(query_4.TrackingNumber, query_4.ModeOfTransport, query_4.ServiceLevel, query_4.PlaceOfReceiptCode, query_4.PlaceOfReceiptName, query_4.FinalDestinationCode, query_4.FinalDestinationName, query_4.PortOfLadingCode, query_4.PortOfLadingName, query_4.PortOfDischargeCode, query_4.PortOfDischargeName, query_4.AccountIds, query_4.HasException, query_4.HasPotentialException, element_1.value, query_4.ShipmentStatus, query_4.Shipper, query_4.Consignee, query_4.ShipmentFromDate, query_4.ShipmentToDate, query_4.Page, query_4.PageSize)))]));
    }]])))], ["children", Interop_reactApi.Children.toArray(Array.from(elems_13))])])))], ["children", Interop_reactApi.Children.toArray(Array.from(elems_14))])]))), createElement("div", createObj(ofArray([["className", join(" ", ["block"])], (elems_15 = [createElement("label", {
        className: join(" ", labelCss),
        for: "fromDate",
        children: "From",
    }), createElement("input", createObj(ofArray([["className", join(" ", inputCss)], ["id", "fromDate"], ["name", "fromDate"], ["disabled", isNullOrWhiteSpace(state.ShipmentSearchQuery.SelectedShipmentDate)], ["type", "date"], ["min", toString_1(minValue(), "yyyy-MM-dd")], ["max", toString_1(maxValue(), "yyyy-MM-dd")], (value_167 = toDateTimeOffsetInput(state.ShipmentSearchQuery.ShipmentFromDate), ["ref", (e_6) => {
        if (!(e_6 == null) && !equals(e_6.value, value_167)) {
            e_6.value = value_167;
        }
    }]), ["onChange", (e_7) => {
        const element_2 = e_7.target;
        dispatch(new Msg(0, [(query_5) => (new ShipmentSearchQuery(query_5.TrackingNumber, query_5.ModeOfTransport, query_5.ServiceLevel, query_5.PlaceOfReceiptCode, query_5.PlaceOfReceiptName, query_5.FinalDestinationCode, query_5.FinalDestinationName, query_5.PortOfLadingCode, query_5.PortOfLadingName, query_5.PortOfDischargeCode, query_5.PortOfDischargeName, query_5.AccountIds, query_5.HasException, query_5.HasPotentialException, query_5.SelectedShipmentDate, query_5.ShipmentStatus, query_5.Shipper, query_5.Consignee, isNullOrWhiteSpace(element_2.value) ? defaultOf_1() : toNullable(DateTimeOffset_fromString(element_2.value)), query_5.ShipmentToDate, query_5.Page, query_5.PageSize))]));
    }]])))], ["children", Interop_reactApi.Children.toArray(Array.from(elems_15))])]))), createElement("div", createObj(ofArray([["className", join(" ", ["block"])], (elems_16 = [createElement("label", {
        className: join(" ", labelCss),
        for: "toDate",
        children: "To",
    }), createElement("input", createObj(ofArray([["className", join(" ", inputCss)], ["id", "toDate"], ["name", "toDate"], ["disabled", isNullOrWhiteSpace(state.ShipmentSearchQuery.SelectedShipmentDate)], ["type", "date"], ["min", toString_1(minValue(), "yyyy-MM-dd")], ["max", toString_1(maxValue(), "yyyy-MM-dd")], (value_190 = toDateTimeOffsetInput(state.ShipmentSearchQuery.ShipmentToDate), ["ref", (e_8) => {
        if (!(e_8 == null) && !equals(e_8.value, value_190)) {
            e_8.value = value_190;
        }
    }]), ["onChange", (e_9) => {
        const element_3 = e_9.target;
        dispatch(new Msg(0, [(query_6) => (new ShipmentSearchQuery(query_6.TrackingNumber, query_6.ModeOfTransport, query_6.ServiceLevel, query_6.PlaceOfReceiptCode, query_6.PlaceOfReceiptName, query_6.FinalDestinationCode, query_6.FinalDestinationName, query_6.PortOfLadingCode, query_6.PortOfLadingName, query_6.PortOfDischargeCode, query_6.PortOfDischargeName, query_6.AccountIds, query_6.HasException, query_6.HasPotentialException, query_6.SelectedShipmentDate, query_6.ShipmentStatus, query_6.Shipper, query_6.Consignee, query_6.ShipmentFromDate, isNullOrWhiteSpace(element_3.value) ? defaultOf_1() : toNullable(DateTimeOffset_fromString(element_3.value)), query_6.Page, query_6.PageSize))]));
    }]])))], ["children", Interop_reactApi.Children.toArray(Array.from(elems_16))])]))), createElement("div", createObj(ofArray([["className", join(" ", ["block"])], ["onKeyPress", (keyPress_4) => {
        if (keyPress_4.key === "Enter") {
            keyPress_4.preventDefault();
        }
    }], (elems_17 = [createElement("label", {
        className: join(" ", labelCss),
        for: "shipper",
        children: "Shipper",
    }), render_1(state.ShipperSearch, (arg_4) => {
        dispatch(new Msg(12, [arg_4]));
    })], ["children", Interop_reactApi.Children.toArray(Array.from(elems_17))])]))), createElement("div", createObj(ofArray([["className", join(" ", ["block"])], ["onKeyPress", (keyPress_5) => {
        if (keyPress_5.key === "Enter") {
            keyPress_5.preventDefault();
        }
    }], (elems_18 = [createElement("label", {
        className: join(" ", labelCss),
        for: "consignee",
        children: "Consignee",
    }), render_1(state.ConsigneeSearch, (arg_5) => {
        dispatch(new Msg(10, [arg_5]));
    })], ["children", Interop_reactApi.Children.toArray(Array.from(elems_18))])]))), createElement("div", createObj(ofArray([["className", join(" ", ["block"])], (elems_21 = [createElement("label", {
        className: join(" ", labelCss),
        for: "mot",
        children: "Mode Of Transport",
    }), createElement("div", createObj(ofArray([["className", join(" ", selectContainerCss)], (elems_20 = [createElement("select", createObj(ofArray([["className", join(" ", inputCss)], ["id", "mot"], ["name", "mot"], (value_223 = state.ShipmentSearchQuery.ModeOfTransport, ["ref", (e_10) => {
        if (!(e_10 == null) && !equals(e_10.value, value_223)) {
            e_10.value = value_223;
        }
    }]), (elems_19 = toList(delay(() => append(singleton_1(createElement("option", {
        value: "Any",
        children: "Any",
    })), delay(() => map_2((mot) => createElement("option", {
        value: mot.ModeOfTransport,
        children: mot.ModeOfTransport,
    }), state.UserDataSources.ModesOfTransport))))), ["children", Interop_reactApi.Children.toArray(Array.from(elems_19))]), ["onChange", (e_11) => {
        const element_4 = e_11.target;
        dispatch(new Msg(0, [(query_7) => (new ShipmentSearchQuery(query_7.TrackingNumber, element_4.value, "", query_7.PlaceOfReceiptCode, query_7.PlaceOfReceiptName, query_7.FinalDestinationCode, query_7.FinalDestinationName, query_7.PortOfLadingCode, query_7.PortOfLadingName, query_7.PortOfDischargeCode, query_7.PortOfDischargeName, query_7.AccountIds, query_7.HasException, query_7.HasPotentialException, query_7.SelectedShipmentDate, query_7.ShipmentStatus, query_7.Shipper, query_7.Consignee, query_7.ShipmentFromDate, query_7.ShipmentToDate, query_7.Page, query_7.PageSize))]));
    }]])))], ["children", Interop_reactApi.Children.toArray(Array.from(elems_20))])])))], ["children", Interop_reactApi.Children.toArray(Array.from(elems_21))])]))), createElement("div", createObj(ofArray([["className", join(" ", ["block"])], (elems_24 = [createElement("label", {
        className: join(" ", labelCss),
        for: "serviceLevel",
        children: "Service Level",
    }), createElement("div", createObj(ofArray([["className", join(" ", selectContainerCss)], (elems_23 = [createElement("select", createObj(ofArray([["className", join(" ", inputCss)], ["id", "serviceLevel"], ["name", "serviceLevel"], ["disabled", isNullOrWhiteSpace(state.ShipmentSearchQuery.ModeOfTransport) ? true : (compare_1(state.ShipmentSearchQuery.ModeOfTransport, "Any", 5) === 0)], (value_251 = state.ShipmentSearchQuery.ServiceLevel, ["ref", (e_12) => {
        if (!(e_12 == null) && !equals(e_12.value, value_251)) {
            e_12.value = value_251;
        }
    }]), (elems_22 = toList(delay(() => append(singleton_1(createElement("option", {
        value: "",
        children: "Any",
    })), delay(() => map_2((serviceLevel) => createElement("option", {
        value: serviceLevel.Name,
        children: serviceLevel.Name,
    }), serviceLevelSearch(state)))))), ["children", Interop_reactApi.Children.toArray(Array.from(elems_22))]), ["onChange", (e_13) => {
        const element_5 = e_13.target;
        dispatch(new Msg(0, [(query_8) => (new ShipmentSearchQuery(query_8.TrackingNumber, query_8.ModeOfTransport, element_5.value, query_8.PlaceOfReceiptCode, query_8.PlaceOfReceiptName, query_8.FinalDestinationCode, query_8.FinalDestinationName, query_8.PortOfLadingCode, query_8.PortOfLadingName, query_8.PortOfDischargeCode, query_8.PortOfDischargeName, query_8.AccountIds, query_8.HasException, query_8.HasPotentialException, query_8.SelectedShipmentDate, query_8.ShipmentStatus, query_8.Shipper, query_8.Consignee, query_8.ShipmentFromDate, query_8.ShipmentToDate, query_8.Page, query_8.PageSize))]));
    }]])))], ["children", Interop_reactApi.Children.toArray(Array.from(elems_23))])])))], ["children", Interop_reactApi.Children.toArray(Array.from(elems_24))])]))), divider, createElement("button", {
        className: join(" ", toList(delay(() => append(singleton_1("block"), delay(() => append(singleton_1("button"), delay(() => append(singleton_1("is-small"), delay(() => append(singleton_1("is-primary"), delay(() => append(singleton_1("is-fullwidth"), delay(() => (equals(state.ShipmentsDeferred, new Deferred$1(1, [])) ? singleton_1("is-loading") : empty())))))))))))))),
        type: "submit",
        children: "Search",
        disabled: state.Accounts.length === 0,
    }), createElement("button", {
        className: join(" ", toList(delay(() => append(singleton_1("block"), delay(() => append(singleton_1("button"), delay(() => append(singleton_1("is-small"), delay(() => append(singleton_1("is-fullwidth"), delay(() => (equals(state.ShipmentsDeferred, new Deferred$1(1, [])) ? singleton_1("is-loading") : empty())))))))))))),
        type: "button",
        onClick: (_arg) => {
            dispatch(new Msg(14, []));
        },
        children: "Clear",
    })], ["children", Interop_reactApi.Children.toArray(Array.from(elems_25))])])));
    const header = createElement("div", createObj(ofArray([["className", join(" ", ["block"])], (elems_33 = [createElement("div", createObj(ofArray([["className", join(" ", ["box"])], (elems_32 = [createElement("div", createObj(ofArray([["className", join(" ", ["columns", "is-align-items-center"])], (elems_31 = [createElement("div", createObj(ofArray([["className", "column"], (elems_26 = [createElement("h1", {
        className: join(" ", ["has-text-weight-semibold", "has-text-link", "is-size-5"]),
        children: "Shipments",
    })], ["children", Interop_reactApi.Children.toArray(Array.from(elems_26))])]))), createElement("div", createObj(ofArray([["className", "column"], (elems_27 = [createElement("div", {
        className: join(" ", ["has-text-weight-semibold"]),
        children: (matchValue_8 = state.ShipmentsDeferred, (matchValue_8.tag === 1) ? "Searching..." : ((matchValue_8.tag === 2) ? ((matchValue_8.fields[0].tag === 0) ? ((compare(matchValue_8.fields[0].fields[0].TotalCount, toInt64(fromInt32(0))) > 0) ? (`${formatInt(~~toInt32(matchValue_8.fields[0].fields[0].TotalCount))} shipments found | showing page ${formatInt(matchValue_8.fields[0].fields[0].Page)} of ${formatInt(matchValue_8.fields[0].fields[0].NumberOfPages)}`) : "0 shipments found") : "") : "")),
    })], ["children", Interop_reactApi.Children.toArray(Array.from(elems_27))])]))), createElement("div", createObj(ofArray([["className", "column is-narrow"], (elems_30 = toList(delay(() => {
        let elems_28;
        return append(singleton_1(createElement("button", createObj(ofArray([["className", join(" ", ["button", "is-small", "mr-2"])], ["type", "button"], ["disabled", !hasPreviousPage(state)], ["onClick", (_arg_1) => {
            if (hasPreviousPage(state)) {
                dispatch(new Msg(16, [state.PreviousShipmentSearchQuery.Page - 1]));
            }
        }], (elems_28 = [createElement("span", {
            className: join(" ", ["material-icons", "is-small"]),
            children: "navigate_before",
        }), createElement("span", {
            className: "is-sr-only",
            children: "Previous",
        })], ["children", Interop_reactApi.Children.toArray(Array.from(elems_28))])])))), delay(() => {
            let elems_29;
            return append(singleton_1(createElement("button", createObj(ofArray([["className", join(" ", ["button", "is-small", "mr-2"])], ["type", "button"], ["disabled", !hasNextPage(state)], ["onClick", (_arg_2) => {
                if (hasNextPage(state)) {
                    dispatch(new Msg(16, [state.PreviousShipmentSearchQuery.Page + 1]));
                }
            }], (elems_29 = [createElement("span", {
                className: join(" ", ["material-icons", "is-small"]),
                children: "navigate_next",
            }), createElement("span", {
                className: join(" ", ["is-sr-only"]),
                children: "Next",
            })], ["children", Interop_reactApi.Children.toArray(Array.from(elems_29))])])))), delay(() => (state.User.CanExportDocuments ? singleton_1(createElement("button", {
                className: join(" ", ["button", "is-small"]),
                type: "button",
                children: (state.ExportShipments.tag === 1) ? "Exporting..." : "Export",
                disabled: equals(state.ExportShipments, new Deferred$1(1, [])),
                onClick: (_arg_3) => {
                    dispatch(new Msg(15, [new AsyncMsg$1(0, [])]));
                },
            })) : empty())));
        }));
    })), ["children", Interop_reactApi.Children.toArray(Array.from(elems_30))])])))], ["children", Interop_reactApi.Children.toArray(Array.from(elems_31))])])))], ["children", Interop_reactApi.Children.toArray(Array.from(elems_32))])])))], ["children", Interop_reactApi.Children.toArray(Array.from(elems_33))])])));
    let searchResult;
    const matchValue_10 = state.ShipmentsDeferred;
    searchResult = ((matchValue_10.tag === 1) ? render_2() : ((matchValue_10.tag === 2) ? ((matchValue_10.fields[0].tag === 0) ? render_3(false, state.User.CanViewCO2Emissions, matchValue_10.fields[0].fields[0].Data) : defaultOf_2()) : defaultOf_2()));
    if (state.Accounts.length === 0) {
        const children = singleton(createElement("h4", {
            className: join(" ", ["has-text-weight-semibold", "has-text-centered", "is-size-5"]),
            children: "Please select a client account",
        }));
        return createElement("div", {
            children: Interop_reactApi.Children.toArray(Array.from(children)),
        });
    }
    else {
        return createElement("div", createObj(singleton((elems_37 = [header, createElement("div", createObj(ofArray([["className", join(" ", ["columns"])], (elems_36 = [createElement("div", {
            className: join(" ", ["column", "is-narrow"]),
            children: Interop_reactApi.Children.toArray([searchControls]),
        }), createElement("div", {
            className: join(" ", ["column"]),
            children: Interop_reactApi.Children.toArray([searchResult]),
        })], ["children", Interop_reactApi.Children.toArray(Array.from(elems_36))])])))], ["children", Interop_reactApi.Children.toArray(Array.from(elems_37))]))));
    }
}

