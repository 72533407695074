import { Union, Record } from "../fable_modules/fable-library.4.10.0/Types.js";
import { record_type, option_type, lambda_type, class_type, union_type, bool_type, string_type } from "../fable_modules/fable-library.4.10.0/Reflection.js";
import { DataSource_$reflection } from "../Models.fs.js";
import { FSharpResult$2 } from "../fable_modules/fable-library.4.10.0/Choice.js";
import { AsyncMsg$1, Deferred$1, AsyncMsg$1_$reflection, Deferred$1_$reflection } from "../Extensions.fs.js";
import { join, isNullOrWhiteSpace } from "../fable_modules/fable-library.4.10.0/String.js";
import { ofArray, singleton } from "../fable_modules/fable-library.4.10.0/List.js";
import { Cmd_none } from "../fable_modules/Fable.Elmish.4.0.2/cmd.fs.js";
import { Toast_text, Toast_title, Toast_error } from "./SweetAlert.fs.js";
import { createObj, equals } from "../fable_modules/fable-library.4.10.0/Util.js";
import { Cmd_OfAsyncWith_perform } from "../fable_modules/Fable.Elmish.4.0.2/./cmd.fs.js";
import { startImmediate } from "../fable_modules/fable-library.4.10.0/Async.js";
import { createElement } from "react";
import { map, empty, singleton as singleton_1, append, delay, toList } from "../fable_modules/fable-library.4.10.0/Seq.js";
import { Interop_reactApi } from "../fable_modules/Feliz.2.6.0/./Interop.fs.js";
import { render as render_1 } from "./Loader.fs.js";
import { defaultOf } from "../fable_modules/Feliz.2.6.0/../fable-library.4.10.0/Util.js";
import { defaultArg } from "../fable_modules/fable-library.4.10.0/Option.js";

export class State extends Record {
    constructor(Name, Disabled, Search, SearchDeferred, IsModalActive, SearchCriteria, SelectedItem) {
        super();
        this.Name = Name;
        this.Disabled = Disabled;
        this.Search = Search;
        this.SearchDeferred = SearchDeferred;
        this.IsModalActive = IsModalActive;
        this.SearchCriteria = SearchCriteria;
        this.SelectedItem = SelectedItem;
    }
}

export function State_$reflection() {
    return record_type("CView.UI.SelectSearch.SelectSearch.State", [], State, () => [["Name", string_type], ["Disabled", bool_type], ["Search", lambda_type(string_type, class_type("Microsoft.FSharp.Control.FSharpAsync`1", [union_type("Microsoft.FSharp.Core.FSharpResult`2", [DataSource_$reflection(), string_type], FSharpResult$2, () => [[["ResultValue", DataSource_$reflection()]], [["ErrorValue", string_type]]])]))], ["SearchDeferred", Deferred$1_$reflection(union_type("Microsoft.FSharp.Core.FSharpResult`2", [DataSource_$reflection(), string_type], FSharpResult$2, () => [[["ResultValue", DataSource_$reflection()]], [["ErrorValue", string_type]]]))], ["IsModalActive", bool_type], ["SearchCriteria", string_type], ["SelectedItem", option_type(class_type("CView.UI.Models.IDataSourceItem"))]]);
}

export class Intent extends Union {
    constructor(tag, fields) {
        super();
        this.tag = tag;
        this.fields = fields;
    }
    cases() {
        return ["SelectedItemChanged", "NoOp"];
    }
}

export function Intent_$reflection() {
    return union_type("CView.UI.SelectSearch.SelectSearch.Intent", [], Intent, () => [[["Item", option_type(class_type("CView.UI.Models.IDataSourceItem"))]], []]);
}

export class Msg extends Union {
    constructor(tag, fields) {
        super();
        this.tag = tag;
        this.fields = fields;
    }
    cases() {
        return ["SetIsModalActive", "SetSearchCriteria", "SetSelectedItem", "SearchAsync"];
    }
}

export function Msg_$reflection() {
    return union_type("CView.UI.SelectSearch.SelectSearch.Msg", [], Msg, () => [[["isModalActive", bool_type]], [["searchCriteria", string_type]], [["Item", option_type(class_type("CView.UI.Models.IDataSourceItem"))]], [["Item", AsyncMsg$1_$reflection(union_type("Microsoft.FSharp.Core.FSharpResult`2", [DataSource_$reflection(), string_type], FSharpResult$2, () => [[["ResultValue", DataSource_$reflection()]], [["ErrorValue", string_type]]]))]]]);
}

export function init(name, selectedItemDisplayName, search) {
    return [new State(name, false, search, new Deferred$1(0, []), false, "", isNullOrWhiteSpace(selectedItemDisplayName) ? void 0 : {
        Id: "",
        DisplayValue: selectedItemDisplayName,
        Value: selectedItemDisplayName,
    }), singleton((dispatch) => {
        dispatch(new Msg(3, [new AsyncMsg$1(0, [])]));
    })];
}

export function update(msg, state) {
    let input_1;
    switch (msg.tag) {
        case 2:
            return [new State(state.Name, state.Disabled, state.Search, state.SearchDeferred, state.IsModalActive, state.SearchCriteria, msg.fields[0]), singleton((dispatch) => {
                dispatch(new Msg(0, [false]));
            }), new Intent(1, [])];
        case 0:
            return [new State(state.Name, state.Disabled, state.Search, state.SearchDeferred, msg.fields[0], msg.fields[0] ? "" : state.SearchCriteria, state.SelectedItem), msg.fields[0] ? singleton((dispatch_1) => {
                dispatch_1(new Msg(3, [new AsyncMsg$1(0, [])]));
            }) : Cmd_none(), msg.fields[0] ? (new Intent(1, [])) : (new Intent(0, [state.SelectedItem]))];
        case 3:
            if (msg.fields[0].tag === 1) {
                return [new State(state.Name, state.Disabled, state.Search, new Deferred$1(2, [msg.fields[0].fields[0]]), state.IsModalActive, state.SearchCriteria, state.SelectedItem), (input_1 = msg.fields[0].fields[0], (input_1.tag === 1) ? Toast_error(Toast_title(`Search ${state.Name}`, Toast_text(input_1.fields[0]))) : Cmd_none()), new Intent(1, [])];
            }
            else if (equals(state.SearchDeferred, new Deferred$1(1, []))) {
                return [state, Cmd_none(), new Intent(1, [])];
            }
            else if (!state.IsModalActive) {
                return [state, Cmd_none(), new Intent(1, [])];
            }
            else {
                return [new State(state.Name, state.Disabled, state.Search, new Deferred$1(1, []), state.IsModalActive, state.SearchCriteria, state.SelectedItem), Cmd_OfAsyncWith_perform((computation) => {
                    startImmediate(computation);
                }, state.Search, state.SearchCriteria, (arg) => (new Msg(3, [new AsyncMsg$1(1, [arg])]))), new Intent(1, [])];
            }
        default:
            return [new State(state.Name, state.Disabled, state.Search, state.SearchDeferred, state.IsModalActive, msg.fields[0], state.SelectedItem), Cmd_none(), new Intent(1, [])];
    }
}

export function render(state, dispatch) {
    let elems_15, elems_14, elems, elems_12, elems_6, elems_5, elems_4, elems_1, value_26, elems_3, elems_2, elems_7, matchValue_1, elems_11, elems_10, elems_9, elems_13, elems_19, elems_16, value_88, x_2, x, elems_18, elems_17;
    const modal = createElement("div", createObj(ofArray([["className", join(" ", toList(delay(() => append(singleton_1("modal"), delay(() => append(singleton_1("px-4"), delay(() => (state.IsModalActive ? singleton_1("is-active") : empty()))))))))], (elems_15 = [createElement("div", {
        className: "modal-background",
    }), createElement("div", createObj(ofArray([["className", join(" ", ["modal-card", "pb-6"])], (elems_14 = [createElement("header", createObj(ofArray([["className", "modal-card-head"], (elems = [createElement("p", {
        className: join(" ", ["modal-card-title", "is-size-6"]),
        children: `Select ${state.Name}`,
    }), createElement("span", {
        className: join(" ", ["material-icons"]),
        children: "check",
    })], ["children", Interop_reactApi.Children.toArray(Array.from(elems))])]))), createElement("section", createObj(ofArray([["className", "modal-card-body"], (elems_12 = [createElement("div", createObj(ofArray([["className", "columns"], (elems_6 = [createElement("div", createObj(ofArray([["className", join(" ", ["column"])], (elems_5 = [createElement("div", createObj(ofArray([["className", join(" ", ["field", "has-addons", "has-addons-right"])], (elems_4 = [createElement("p", createObj(ofArray([["className", join(" ", ["control", "is-expanded"])], (elems_1 = [createElement("input", createObj(ofArray([["className", join(" ", ["input", "is-small"])], ["type", "text"], ["maxLength", 256], ["placeholder", "Search..."], (value_26 = state.SearchCriteria, ["ref", (e) => {
        if (!(e == null) && !equals(e.value, value_26)) {
            e.value = value_26;
        }
    }]), ["onChange", (ev) => {
        dispatch(new Msg(1, [ev.target.value]));
    }], ["onKeyPress", (keyPress) => {
        if (keyPress.key === "Enter") {
            dispatch(new Msg(3, [new AsyncMsg$1(0, [])]));
        }
    }]])))], ["children", Interop_reactApi.Children.toArray(Array.from(elems_1))])]))), createElement("p", createObj(ofArray([["className", join(" ", ["control"])], (elems_3 = [createElement("button", createObj(ofArray([["className", join(" ", ["button", "is-small"])], ["type", "button"], ["onClick", (_arg) => {
        dispatch(new Msg(3, [new AsyncMsg$1(0, [])]));
    }], (elems_2 = [createElement("span", {
        className: join(" ", ["material-icons"]),
        children: "search",
    }), createElement("span", {
        className: "is-sr-only",
        children: "Search",
    })], ["children", Interop_reactApi.Children.toArray(Array.from(elems_2))])])))], ["children", Interop_reactApi.Children.toArray(Array.from(elems_3))])])))], ["children", Interop_reactApi.Children.toArray(Array.from(elems_4))])])))], ["children", Interop_reactApi.Children.toArray(Array.from(elems_5))])])))], ["children", Interop_reactApi.Children.toArray(Array.from(elems_6))])]))), createElement("div", createObj(ofArray([["className", join(" ", ["columns"])], (elems_7 = [createElement("span", {
        className: join(" ", ["column", "pt-0", "pb-0", "is-size-7"]),
        children: (matchValue_1 = state.SearchDeferred, (matchValue_1.tag === 1) ? "" : ((matchValue_1.tag === 2) ? ((matchValue_1.fields[0].tag === 0) ? (matchValue_1.fields[0].fields[0].IsPaginated ? (`Showing top ${matchValue_1.fields[0].fields[0].Data.length} results`) : "") : "") : "")),
    })], ["children", Interop_reactApi.Children.toArray(Array.from(elems_7))])]))), createElement("div", createObj(ofArray([["className", "table-container"], ["style", {
        maxHeight: 250,
        overflowY: "auto",
    }], (elems_11 = [createElement("table", createObj(ofArray([["className", join(" ", ["table", "is-bordered", "is-narrow", "is-hoverable", "is-fullwidth"])], (elems_10 = [createElement("tbody", createObj(singleton((elems_9 = toList(delay(() => {
        const matchValue_2 = state.SearchDeferred;
        return (matchValue_2.tag === 1) ? singleton_1(render_1()) : ((matchValue_2.tag === 2) ? ((matchValue_2.fields[0].tag === 0) ? map((item) => {
            let elems_8;
            return createElement("tr", createObj(ofArray([["className", join(" ", ["is-clickable"])], (elems_8 = [createElement("td", {
                className: join(" ", ["is-size-7", "p-2"]),
                children: item.DisplayValue,
                onClick: (_arg_1) => {
                    dispatch(new Msg(2, [item]));
                },
            })], ["children", Interop_reactApi.Children.toArray(Array.from(elems_8))])])));
        }, matchValue_2.fields[0].fields[0].Data) : singleton_1(defaultOf())) : singleton_1(defaultOf()));
    })), ["children", Interop_reactApi.Children.toArray(Array.from(elems_9))]))))], ["children", Interop_reactApi.Children.toArray(Array.from(elems_10))])])))], ["children", Interop_reactApi.Children.toArray(Array.from(elems_11))])])))], ["children", Interop_reactApi.Children.toArray(Array.from(elems_12))])]))), createElement("footer", createObj(ofArray([["className", join(" ", ["modal-card-foot", "is-justify-content-center"])], (elems_13 = [createElement("button", {
        className: join(" ", ["button", "is-small", "is-fullwidth"]),
        type: "button",
        children: "Cancel",
        onClick: (_arg_2) => {
            dispatch(new Msg(2, [void 0]));
        },
    })], ["children", Interop_reactApi.Children.toArray(Array.from(elems_13))])])))], ["children", Interop_reactApi.Children.toArray(Array.from(elems_14))])])))], ["children", Interop_reactApi.Children.toArray(Array.from(elems_15))])])));
    return createElement("div", createObj(ofArray([["className", join(" ", ["field", "has-addons", "has-addons-right"])], (elems_19 = [modal, createElement("p", createObj(ofArray([["className", join(" ", ["control", "is-expanded"])], (elems_16 = [createElement("input", createObj(ofArray([["className", join(" ", ["input", "is-small", "is-clickable"])], ["placeholder", `Select ${state.Name}...`], ["readOnly", true], ["disabled", state.Disabled], (value_88 = defaultArg((x_2 = state.SelectedItem, (x_2 == null) ? void 0 : ((x = x_2, x.DisplayValue))), ""), ["ref", (e_1) => {
        if (!(e_1 == null) && !equals(e_1.value, value_88)) {
            e_1.value = value_88;
        }
    }]), ["onClick", (_arg_3) => {
        dispatch(new Msg(0, [true]));
    }]])))], ["children", Interop_reactApi.Children.toArray(Array.from(elems_16))])]))), createElement("p", createObj(ofArray([["className", join(" ", ["control"])], (elems_18 = [createElement("button", createObj(ofArray([["className", join(" ", ["button", "is-small"])], ["type", "button"], ["disabled", state.Disabled], ["onClick", (_arg_4) => {
        dispatch(new Msg(0, [true]));
    }], (elems_17 = [createElement("span", {
        className: join(" ", ["material-icons", "is-small"]),
        children: "list",
    }), createElement("span", {
        className: "is-sr-only",
        children: `Select ${state.Name}`,
    })], ["children", Interop_reactApi.Children.toArray(Array.from(elems_17))])])))], ["children", Interop_reactApi.Children.toArray(Array.from(elems_18))])])))], ["children", Interop_reactApi.Children.toArray(Array.from(elems_19))])])));
}

