import { Union, Record } from "../fable_modules/fable-library.4.10.0/Types.js";
import { AnalyticsReportPage_$reflection, AnalyticsReportToken_$reflection, AnalyticsReport_$reflection, Account_$reflection } from "../../CView.DTOs/DTOs.fs.js";
import { record_type, option_type, union_type, string_type, array_type } from "../fable_modules/fable-library.4.10.0/Reflection.js";
import { FSharpResult$2 } from "../fable_modules/fable-library.4.10.0/Choice.js";
import { AsyncMsg$1, Deferred$1, AsyncMsg$1_$reflection, Deferred$1_$reflection } from "../Extensions.fs.js";
import { Cmd_none } from "../fable_modules/Fable.Elmish.4.0.2/cmd.fs.js";
import { ofArray, singleton } from "../fable_modules/fable-library.4.10.0/List.js";
import { Alert_text, Alert_title, Alert_error } from "../Components/SweetAlert.fs.js";
import { Cmd_OfAsyncWith_perform } from "../fable_modules/Fable.Elmish.4.0.2/./cmd.fs.js";
import { startImmediate } from "../fable_modules/fable-library.4.10.0/Async.js";
import { getEmbedToken } from "../Api/PowerBIApi.fs.js";
import { map } from "../fable_modules/fable-library.4.10.0/Array.js";
import { PowerBI_render } from "../Components/PowerBI.fs.js";
import { createElement } from "react";
import { join } from "../fable_modules/fable-library.4.10.0/String.js";
import { Interop_reactApi } from "../fable_modules/Feliz.2.6.0/./Interop.fs.js";
import { createObj } from "../fable_modules/fable-library.4.10.0/Util.js";
import { singleton as singleton_1, append, map as map_1, delay, toList } from "../fable_modules/fable-library.4.10.0/Seq.js";

export class State extends Record {
    constructor(Accounts, AvailableReports, AccessTokenDeferred, SelectedReport) {
        super();
        this.Accounts = Accounts;
        this.AvailableReports = AvailableReports;
        this.AccessTokenDeferred = AccessTokenDeferred;
        this.SelectedReport = SelectedReport;
    }
}

export function State_$reflection() {
    return record_type("CView.UI.Pages.AnalyticsDashboard.State", [], State, () => [["Accounts", array_type(Account_$reflection())], ["AvailableReports", array_type(AnalyticsReport_$reflection())], ["AccessTokenDeferred", Deferred$1_$reflection(union_type("Microsoft.FSharp.Core.FSharpResult`2", [AnalyticsReportToken_$reflection(), string_type], FSharpResult$2, () => [[["ResultValue", AnalyticsReportToken_$reflection()]], [["ErrorValue", string_type]]]))], ["SelectedReport", option_type(AnalyticsReportPage_$reflection())]]);
}

export class Msg extends Union {
    constructor(tag, fields) {
        super();
        this.tag = tag;
        this.fields = fields;
    }
    cases() {
        return ["GetEmbedReportTokenAsync", "SelectReport"];
    }
}

export function Msg_$reflection() {
    return union_type("CView.UI.Pages.AnalyticsDashboard.Msg", [], Msg, () => [[["Item", AsyncMsg$1_$reflection(union_type("Microsoft.FSharp.Core.FSharpResult`2", [AnalyticsReportToken_$reflection(), string_type], FSharpResult$2, () => [[["ResultValue", AnalyticsReportToken_$reflection()]], [["ErrorValue", string_type]]]))]], [["Item", AnalyticsReportPage_$reflection()]]]);
}

export function init(accounts, analyticsReports) {
    return [new State(accounts, analyticsReports, new Deferred$1(0, []), void 0), (accounts.length === 0) ? Cmd_none() : singleton((dispatch) => {
        dispatch(new Msg(0, [new AsyncMsg$1(0, [])]));
    })];
}

export function update(msg, state) {
    let matchValue_2, selectedReport, msg_1, matchValue, tokenResult;
    if (msg.tag === 0) {
        if (msg.fields[0].tag === 1) {
            return [new State(state.Accounts, state.AvailableReports, new Deferred$1(2, [msg.fields[0].fields[0]]), state.SelectedReport), (msg.fields[0].fields[0].tag === 1) ? Alert_error(Alert_title("Analytics Dashboard", Alert_text(msg.fields[0].fields[0].fields[0]))) : ((state.AvailableReports.length > 0) ? ((matchValue_2 = state.SelectedReport, (matchValue_2 != null) ? ((selectedReport = matchValue_2, singleton((dispatch_1) => {
                dispatch_1(new Msg(1, [selectedReport]));
            }))) : ((msg_1 = (new Msg(1, [state.AvailableReports[0].Pages[0]])), singleton((dispatch) => {
                dispatch(msg_1);
            }))))) : Cmd_none())];
        }
        else if (state.AccessTokenDeferred.tag === 0) {
            return [new State(state.Accounts, state.AvailableReports, new Deferred$1(1, []), state.SelectedReport), Cmd_OfAsyncWith_perform((computation) => {
                startImmediate(computation);
            }, getEmbedToken, map((x) => x.Id, state.Accounts), (arg) => (new Msg(0, [new AsyncMsg$1(1, [arg])])))];
        }
        else {
            return [state, Cmd_none()];
        }
    }
    else {
        return [new State(state.Accounts, state.AvailableReports, state.AccessTokenDeferred, msg.fields[0]), (matchValue = state.AccessTokenDeferred, (matchValue.tag === 2) ? ((tokenResult = matchValue.fields[0], (tokenResult.tag === 1) ? Alert_error(Alert_title("Analytics Dashboard", Alert_text(tokenResult.fields[0]))) : PowerBI_render(msg.fields[0].ReportId, msg.fields[0].ReportId, tokenResult.fields[0].AccessToken, map((account) => account.ExternalId, state.Accounts), msg.fields[0].Name))) : Cmd_none())];
    }
}

export function render(state, dispatch) {
    let elems_1, children_2, elems;
    if (state.Accounts.length === 0) {
        const children = singleton(createElement("h4", {
            className: join(" ", ["has-text-weight-semibold", "has-text-centered", "is-size-5"]),
            children: "Please select a client account",
        }));
        return createElement("div", {
            children: Interop_reactApi.Children.toArray(Array.from(children)),
        });
    }
    else {
        return createElement("div", createObj(singleton((elems_1 = [(children_2 = singleton(createElement("div", createObj(ofArray([["className", join(" ", ["column", "box", "mb-2"])], ["style", {
            height: 80 + "vh",
        }], (elems = toList(delay(() => map_1((report) => createElement("div", {
            id: report.Id,
            style: createObj(toList(delay(() => append(singleton_1(["height", 100 + "%"]), delay(() => {
                const matchValue = state.SelectedReport;
                let matchResult, selectedReport_1;
                if (matchValue != null) {
                    if (matchValue.ReportId === report.Id) {
                        matchResult = 0;
                        selectedReport_1 = matchValue;
                    }
                    else {
                        matchResult = 1;
                    }
                }
                else {
                    matchResult = 1;
                }
                switch (matchResult) {
                    case 0:
                        return singleton_1(["display", "block"]);
                    default:
                        return singleton_1(["display", "none"]);
                }
            }))))),
        }), state.AvailableReports))), ["children", Interop_reactApi.Children.toArray(Array.from(elems))])])))), createElement("div", {
            children: Interop_reactApi.Children.toArray(Array.from(children_2)),
        }))], ["children", Interop_reactApi.Children.toArray(Array.from(elems_1))]))));
    }
}

